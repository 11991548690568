/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from '@/redux/hooks';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface LineChartProps {
	chartData: { name: string; data: string[] }[];
	chartOptions: any;
}

const LineChart: React.FC<LineChartProps> = ({ chartData, chartOptions }) => {
	const [lineChartData, setChartData] = useState<
		{ name: string; data: string[] }[]
	>([]);
	const [lineChartOptions, setChartOptions] = useState<Partial<any>>({});

	useEffect(() => {
		setChartData(chartData);
		setChartOptions(chartOptions);
	}, [chartData, chartOptions]);

	return (
		<ReactApexChart
			options={lineChartOptions}
			//@ts-ignore
			series={lineChartData}
			type="line"
			width="100%"
			height="100%"
		/>
	);
};

export default LineChart;
