import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { DataTablePagination } from "@/components/ui/data-table/data-table-pagination";
import { ChargerTableToolbar } from "./charger-table-toolbar";
import { ChargerDetails } from "../charger-details/charger-details";
import { ChargerType } from "../../types";
import { ChargePointType } from "@/types/charger-network";
import { Skeleton } from "@/components/ui/skeleton";

interface ChargerDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[] | ChargePointType[];
  loading: boolean;
}

export function ChargerDataTable<TData, TValue>({
  columns,
  data,
  loading,
}: ChargerDataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selectedChargepoint, setSelectedChargepoint] = React.useState<any>();
  const [showModal, setShowModal] = React.useState(false);

  const table = useReactTable({
    data: data as ChargePointType[],
    columns: columns as ColumnDef<ChargePointType, any>[],
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div className="space-y-4">
      <ChargerTableToolbar table={table} />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton className=" h-4 w-4 rounded-sm " />
                    </TableCell>
                    {[...Array(5)].map((_, index) => (
                      <TableCell>
                        <Skeleton className="w-full h-4 rounded-sm" />
                      </TableCell>
                    ))}
                    <TableCell className="flex gap-1 w-full">
                      <Skeleton className=" h-1 w-1 rounded-sm " />
                      <Skeleton className=" h-1 w-1 rounded-sm " />
                      <Skeleton className=" h-1 w-1 rounded-sm " />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <TableRow
                    onClick={() => {
                      setSelectedChargepoint(row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {selectedChargepoint && (
        <ChargerDetails
          data={selectedChargepoint}
          setModalOpen={setSelectedChargepoint}
        />
      )}
    </div>
  );
}
