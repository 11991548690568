import request from '@/lib/utils/axios';
import {
	AssignRoleType,
	CreateNewOrganisationType,
	SendInvitesType,
} from '@/types/organisation';
import { AxiosError } from 'axios';

const orgId = localStorage.getItem('org_id');
export const createNewOrg = async (value: CreateNewOrganisationType) => {
	try {
		const res = await request.post('/core/organization/new-org/', value);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const getOrgProfile = async (value: { org_id: string }) => {
	try {
		if (orgId) {
			const res = await request.get(`/core/organization/org/${orgId}/`);
			return res.data;
		}
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};
export const acceptInvitaion = async (value: { invite_token: string }) => {
	try {
		const res = await request.post('/core/organization/acceptInvite/', value);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const assignRole = async (value: AssignRoleType) => {
	try {
		const res = await request.patch('/core/organization/assign-role/', value);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const generateInviteLink = async (value: { org_id: string }) => {
	try {
		if (orgId) {
			const res = await request.get(
				`/core/organization/generateInvite/${orgId}/`
			);
			return res.data;
		}
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const sendInvites = async (value: SendInvitesType) => {
	try {
		const res = await request.post(
			'/core/organization/generateInvites/',
			value
		);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const readInvites = async (value: { org_id: string }) => {
	try {
		if (orgId) {
			const res = await request.post(`/core/organization/invites/${orgId}`);
			return res.data;
		}
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};
