import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/lib/helpers/redux-base-query';

export const homeAction = createApi({
	reducerPath: 'homeAction',
	baseQuery,
	endpoints: (builder) => ({
		getTodayChargingSession: builder.query({
			query: (orgId) =>
				`core_backend/today-charging-session-analytics/?organization=${orgId}`,
		}),
		getTodayRevenue: builder.query({
			query: (orgId) =>
				`core_backend/today-total-revenue/?organization=${orgId}`,
		}),
		getKwhToday: builder.query({
			query: (orgId) =>
				`core_backend/today-energy-consumed-analytics/?organization=${orgId}`,
		}),
		getTotalUsageToday: builder.query({
			query: (orgId) => `core_backend/total-usage-today/${orgId}`,
		}),
		getTotalChargers: builder.query({
			query: (orgId) => `core_backend/charger-status/${orgId}`,
		}),
		getChargerLocations: builder.query({
			query: (orgId) => `core_backend/charger-map-visual/${orgId}`,
		}),
		getWeeklyConsumption: builder.query({
			query: (values) =>
				`core_backend/weekly-energy-consumed-analytics/?organization=${values.org_id}&start_date=${values.start_date}`,
		}),
		getTimeFrameUtilization: builder.query({
			query: (values) =>
				`core_backend/timeframe-utilization/${values.org_id}?timeframe=${values.timeframe}`,
		}),

		getWeeklySessions: builder.query({
			query: (values) =>
				`core_backend/weekly-charging-sessions/${values.org_id}?start_date=${values.start_date}`,
		}),
	}),
});

export const {
	useGetTodayChargingSessionQuery,
	useGetKwhTodayQuery,
	useGetTotalUsageTodayQuery,
	useGetTotalChargersQuery,
	useGetChargerLocationsQuery,
	useGetTodayRevenueQuery,
	useGetWeeklySessionsQuery,
	useGetWeeklyConsumptionQuery,
	useGetTimeFrameUtilizationQuery,
} = homeAction;
