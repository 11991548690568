interface DataProps {
	id: number;
	companyName: string;
	companyLogo: string;
	devices: number;
	modules: number;
	users: number;
}

interface Props {
	data: DataProps;
}
export const OrganisationsCard: React.FC<Props> = ({ data }) => {
	return (
		<div className="bg-blue-800 rounded-lg overflow-hidden cursor-pointer hover:scale-105 duration-200">
			<div
				className=" bg-slate-400 rounded-lg shadow-2xl p-4 mt-1"
				style={{ backdropFilter: 'blur(20px)' }}
			>
				<div className="flex gap-1 ">
					<img src={data.companyLogo} alt="" />
					<h3 className="text-white text-xl ">{data.companyName}</h3>
				</div>
				<div className="h-[.2px] w-full bg-[#ccc] my-4" />
				<div className="flex gap-2 justify-between">
					<div className="flex flex-col justify-center items-center text-white">
						<div className="text-lg font-semibold">Devices</div>
						<div className="">{data.devices}</div>
					</div>
					<div className="flex flex-col justify-center items-center text-white">
						<div className="text-lg font-semibold">Modules</div>
						<div className="">{data.modules}</div>
					</div>
					<div className="flex flex-col justify-center items-center text-white">
						<div className=" text-lg font-semibold">Users</div>
						<div className="">{data.users}</div>
					</div>
				</div>
			</div>
		</div>
	);
};
