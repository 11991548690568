import { useCallback, useReducer, useState } from 'react';
import { GlobalContext } from './context';
import { GlobalContextPropType } from './types/global';
import {
	GET_COUNTRIES_SUCCESS,
	GET_COUNTRIES_LOADING,
	GET_COUNTRIES_FAIL,
	GET_STATES_SUCCESS,
	GET_STATES_LOADING,
	GET_STATES_FAIL,
	GET_CITIES_SUCCESS,
	GET_CITIES_LOADING,
	GET_CITIES_FAIL,
	globalReducer,
} from './reducer';

import { Country, State } from 'country-state-city';

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
	const [countries, setCountries] = useReducer(globalReducer, {
		data: null,
		loading: false,
		error: null,
	});
	const [selectedCountry, setSelectedCountry] = useState('');

	const [states, setStates] = useReducer(globalReducer, {
		data: null,
		loading: false,
		error: null,
	});
	const [selectedState, setSelectedState] = useState('');

	const [cities, setCities] = useReducer(globalReducer, {
		data: null,
		loading: false,
		error: null,
	});
	const [selectedCity, setSelectedCity] = useState('');

	const handleGetCountries = useCallback(async () => {
		try {
			setCountries({ type: GET_COUNTRIES_LOADING });
			const response = await Country.getAllCountries();
			let allCountries = [];
			allCountries = response?.map(({ isoCode, name }) => ({
				isoCode,
				name,
			}));

			const [{ isoCode: firstCountry = '' } = {}] = allCountries;
			setSelectedCountry(firstCountry);
			setCountries({ type: GET_COUNTRIES_SUCCESS, payload: response });
		} catch (error) {
			setCountries({ type: GET_COUNTRIES_FAIL });
		}
	}, []);

	const handleGetStates = useCallback(async () => {
		try {
			setStates({ type: GET_STATES_LOADING });
			const response = await State.getStatesOfCountry(selectedCountry);
			let allStates = [];
			allStates = response?.map(({ isoCode, name }) => ({
				isoCode,
				name,
			}));

			const [{ isoCode: firstState = '' } = {}] = allStates;
			setCities({ type: GET_CITIES_FAIL });
			setSelectedCity('');
			setSelectedState(firstState);
			setStates({ type: GET_STATES_SUCCESS, payload: response });
		} catch (error) {
			setStates({ type: GET_STATES_FAIL });
			setCities({ type: GET_CITIES_FAIL });
			setSelectedCity('');
		}
	}, [selectedCountry]);

	const handleGetCities = useCallback(async () => {
		try {
			setCities({ type: GET_CITIES_LOADING });
			const response = await State.getStatesOfCountry(selectedState);
			let allCities = [];
			allCities = response?.map(({ isoCode, name }) => ({
				isoCode,
				name,
			}));

			const [{ isoCode: firstCity = '' } = {}] = allCities;
			setSelectedCity(firstCity);
			setCities({ type: GET_CITIES_SUCCESS, payload: response });
		} catch (error) {
			setCities({ type: GET_CITIES_FAIL });
		}
	}, [selectedState]);

	const values: GlobalContextPropType = {
		handleGetCountries,
		setSelectedCountry,
		countries,
		selectedCountry,

		handleGetStates,
		setSelectedState,
		states,

		handleGetCities,
		setSelectedCity,
		cities,
	};
	return (
		<GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
	);
};

export default GlobalProvider;
