import { useCallback, useEffect, useReducer, useState } from 'react';
import { OrganisationContext } from './context';
import { OrganisationContextPropType } from './types/organisation';
import {
	INVITE_LINK_LOADING,
	CREATE_NEW_ORG_SUCCESS,
	CREATE_NEW_ORG_LOADING,
	CREATE_NEW_ORG_FAIL,
	GET_ORG_PROFILE_SUCCESS,
	GET_ORG_PROFILE_LOADING,
	GET_ORG_PROFILE_FAIL,
	organisationReducer,
} from './reducer';
import {
	createNewOrg,
	generateInviteLink,
	getOrgProfile,
} from '@/services/organisation';
import { toast } from 'sonner';
import { CreateNewOrganisationType } from '@/types/organisation';
import { useAppNavigate } from '@/lib/hooks/use-app-navigate';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '@/lib/hooks/use-auth';

const OrganisationProvider = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate();
	const { authenticated } = useAuth();

	const [orgName, setOrgName] = useState('');
	const [inviteLink, setInviteLink] = useReducer(organisationReducer, {
		data: null,
		loading: false,
		error: null,
	});

	const [newOrg, setNewOrg] = useReducer(organisationReducer, {
		data: null,
		loading: false,
		error: null,
	});

	const [orgProfile, setOrgProfile] = useReducer(organisationReducer, {
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		if (authenticated)
			(async () => {
				const orgId = localStorage.getItem('org_id');
				const storedOrgname = localStorage.getItem('org_name');
				if (orgId && !storedOrgname) {
					const response: any = await getOrgProfile({ org_id: orgId });
					if (response.status == 'success' && response.data) {
						setOrgName(response.data.org_name);
						localStorage.setItem('org_name', response.data.org_name);
						localStorage.setItem('org_type', response.data.org_type);
					}
				}
				if (storedOrgname) {
					setOrgName(storedOrgname);
				}
				if (!orgId && !storedOrgname) {
					return <Navigate to="/" />;
				}
			})();
	}, []);

	const handleInviteLink = useCallback(async (values: { org_id: string }) => {
		try {
			setInviteLink({ type: INVITE_LINK_LOADING });

			const response = generateInviteLink(values);
		} catch (error) {}
	}, []);

	const handleCreateNewOrg = useCallback(
		async (values: CreateNewOrganisationType) => {
			try {
				setNewOrg({ type: CREATE_NEW_ORG_LOADING });
				const response = await createNewOrg(values);

				localStorage.setItem('org_id', response.data.id);

				if (response.status == 'success') {
					setNewOrg({
						type: CREATE_NEW_ORG_SUCCESS,
						payload: response.data,
					});
					toast.success(response.message);
				} else {
					setNewOrg({
						type: CREATE_NEW_ORG_FAIL,
					});
				}
			} catch (error) {
				setNewOrg({
					type: CREATE_NEW_ORG_FAIL,
				});
				toast.error('Something went wrong');
			}
		},
		[]
	);

	const handleGetOrgProfile = useCallback(async (value: { org_id: string }) => {
		try {
			setOrgProfile({ type: GET_ORG_PROFILE_LOADING });
			const response = await getOrgProfile(value);
			if (response.status == 'success' && response.data) {
				setOrgProfile({
					type: GET_ORG_PROFILE_SUCCESS,
					payload: response.data,
				});

				return response.data;
			} else {
				setOrgProfile({
					type: GET_ORG_PROFILE_FAIL,
				});
			}
		} catch (error) {
			setOrgProfile({
				type: GET_ORG_PROFILE_FAIL,
			});
		}
	}, []);

	const values: OrganisationContextPropType = {
		handleInviteLink,
		inviteLink,

		handleCreateNewOrg,
		newOrg,

		handleGetOrgProfile,
		orgProfile,

		orgName,
	};
	return (
		<OrganisationContext.Provider value={values}>
			{children}
		</OrganisationContext.Provider>
	);
};

export default OrganisationProvider;
