import {
  HomeIcon,
  ChartBarIcon,
  Squares2X2Icon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import { CreditCardIcon, Phone } from "lucide-react";
import { BsDiagram2Fill } from "react-icons/bs";
import { IoMdGitNetwork } from "react-icons/io";
import { MdRoomService } from "react-icons/md";
import { TbAccessPoint, TbAlertHexagonFilled } from "react-icons/tb";

//Deprecating
export const menuLink = [
  {
    name: "Menu",
    items: [
      {
        id: 1,
        name: "Home",
        icon: HomeIcon,
        href: "/",
      },
      {
        id: 2,
        name: "Revenue",
        icon: ChartBarIcon,
        href: "/revenue",
      },
      {
        id: 3,
        name: "Tariff Management",
        icon: BsDiagram2Fill,
        href: "/tariff-management",
      },
      // {
      // 	id: 4,
      // 	name: 'Maintenance & Alerts',
      // 	icon: TbAlertHexagonFilled,
      // 	href: '/maintenance',
      // },
      // {
      // 	id: 5,
      // 	name: 'Access Control',
      // 	icon: TbAccessPoint,
      // 	href: '/access-control',
      // },
      // {
      //   id: 5,
      //   name: "Transactions",
      //   icon: CreditCardIcon,
      //   href: "/transactions",
      // },
      {
        id: 6,
        name: "Charger Network",
        icon: IoMdGitNetwork,
        href: "/charger-network",
      },

      // {
      // 	id: 7,
      // 	name: 'Reservations',
      // 	icon: MdRoomService,
      // 	href: '/reservations',
      // },
    ],
  },
  {
    name: "Others",
    items: [
      {
        id: 1,
        name: "Settings",
        icon: Cog6ToothIcon,
        href: "/settings",
      },
      // {
      //   id: 3,
      //   name: "Help & Support",
      //   icon: Phone,
      //   href: "/support",
      // },
    ],
  },
];

export const menuOrgLinks = [
  {
    name: "Home",
    icon: HomeIcon,
    path: `/`,
  },
  {
    name: "Revenue",
    icon: ChartBarIcon,
    path: `/revenue`,
  },
  {
    name: "Tariff Management",
    icon: Squares2X2Icon,
    path: `/tariff-management`,
  },
  // {
  // 	name: 'Maintenance & Alerts',
  // 	icon: TbAlertHexagonFilled,
  // 	href: `/${orgId}/maintenance`,
  // },
  // {
  // 	name: 'Access Control',
  // 	icon: TbAccessPoint,
  // 	href: '/access-control',
  // },
  {
    name: "Charger Network",
    icon: IoMdGitNetwork,
    path: `/charger-network`,
  },
];
