import { OrganisationNavbar } from '@/components/organisation-navbar';

interface Props {
	children: React.ReactNode;
}
export const Organisation: React.FC<Props> = ({ children }) => {
	return (
		<div className="h-full w-full">
			<OrganisationNavbar />
			{children}
		</div>
	);
};
