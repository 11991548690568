import { Navbar } from '@/components/navbar';
import { Skeleton } from '@/components/ui/skeleton';
import {
	useGetPastPaymentCyclesQuery,
	useGetPaymentCyclesQuery,
} from '@/redux/features/revenue/revenue.api';
import {
	PaymentSettlmentCycleType,
	setPaymentSettlementCycle,
	startLoading,
} from '@/redux/features/revenue/revenue.slice';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import React, { useEffect, useState } from 'react';
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from '@/components/ui/pagination';
import { columns } from './components/payment-plan/column';
import { PaymentPlanDataTable } from './components/payment-plan/payment-plan-data-table';
import { Button } from '@/components/ui/button';

type Props = {};

const Settlements = (props: Props) => {
	const dispatch = useAppDispatch();
	const { orgId } = useAppSelector((state) => state.auth);
	const { paymentSettlementCycles, loading } = useAppSelector(
		(state) => state.revenue
	);

	const [viewPastPaymentCycle, setViewPastPaymentCycle] = useState(false);

	const [page, setPage] = useState(1);
	const [pastPage, setPastPage] = useState(1);

	const { data, isLoading } = useGetPaymentCyclesQuery(orgId, { skip: !orgId });
	const { data: pastData, isLoading: pastLoading } =
		useGetPastPaymentCyclesQuery(orgId, { skip: !orgId });

	useEffect(() => {
		if (isLoading) {
			dispatch(startLoading());
		} else if (data) {
			dispatch(setPaymentSettlementCycle(data));
		}
	}, [paymentSettlementCycles, dispatch, isLoading, data]);

	return (
		<div className=" flex gap-9 flex-col relative ">
			<div
				style={{
					backgroundImage: 'url(/images/--gf-dashboard-cover.png)',
					backgroundSize: '100% 270px',
					backgroundRepeat: 'no-repeat',
				}}
				className="bg-cover md:px-9 px-4  md:pt-9 pt-4 flex flex-col 2xl:gap-11 gap-5 mb-10"
			>
				<div className="container px-0">
					<Navbar title={'Payment Cycle'} />
				</div>

				<div className=" bg-black p-6 rounded-lg border border-slate-400 text-white container">
					<div className="mb-4">
						<h2 className="text-2xl font-bold ">Your Payment current cycle.</h2>
						<p className="text-lg">
							Here's a list of your current settlement cycle plans!
						</p>
					</div>

					<PaymentPlanDataTable
						data={paymentSettlementCycles && paymentSettlementCycles.results}
						columns={columns}
						loading={loading}
					/>

					{loading ? (
						<div className="flex  justify-end my-2">
							<div className="flex justify-end gap-2">
								<Skeleton className=" h-6 w-12 rounded-sm " />
								<Skeleton className=" h-6 w-12 rounded-sm " />
								<Skeleton className=" h-6 w-12 rounded-sm " />
							</div>
						</div>
					) : (
						paymentSettlementCycles && (
							<Pagination className="justify-end pr-6 pt-4">
								<PaginationContent>
									{paymentSettlementCycles.previous && (
										<PaginationItem onClick={() => setPage(page - 1)}>
											<PaginationPrevious href="#" />
										</PaginationItem>
									)}
									<PaginationItem>
										<PaginationLink href="#" isActive>
											{page}
										</PaginationLink>
									</PaginationItem>
									{paymentSettlementCycles.next && (
										<PaginationItem onClick={() => setPage(page + 1)}>
											<PaginationNext href="#" />
										</PaginationItem>
									)}
								</PaginationContent>
							</Pagination>
						)
					)}
				</div>

				{pastData && pastData.results.length > 0 && !viewPastPaymentCycle && (
					<Button
						onClick={() => {
							setViewPastPaymentCycle(true);
						}}
						variant="secondary"
						className="max-w-[40%] w-full mx-auto"
					>
						View Past Payment Cycles
					</Button>
				)}

				{viewPastPaymentCycle && (
					<div className=" bg-black p-6 rounded-lg border border-slate-400 text-white container">
						<div className="mb-4">
							<h2 className="text-2xl font-bold ">
								Your Past Payment current cycle.
							</h2>
							<p className="text-lg">
								Here's a list of your past settlement cycle plans!
							</p>
						</div>

						<PaymentPlanDataTable
							data={pastData.results}
							columns={columns}
							loading={pastLoading}
						/>

						{loading ? (
							<div className="flex  justify-end my-2">
								<div className="flex justify-end gap-2">
									<Skeleton className=" h-6 w-12 rounded-sm " />
									<Skeleton className=" h-6 w-12 rounded-sm " />
									<Skeleton className=" h-6 w-12 rounded-sm " />
								</div>
							</div>
						) : (
							pastData && (
								<Pagination className="justify-end pr-6 pt-4">
									<PaginationContent>
										{pastData.previous && (
											<PaginationItem onClick={() => setPastPage(page - 1)}>
												<PaginationPrevious href="#" />
											</PaginationItem>
										)}
										<PaginationItem>
											<PaginationLink href="#" isActive>
												{pastPage}
											</PaginationLink>
										</PaginationItem>
										{pastData.next && (
											<PaginationItem onClick={() => setPastPage(page + 1)}>
												<PaginationNext href="#" />
											</PaginationItem>
										)}
									</PaginationContent>
								</Pagination>
							)
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Settlements;
