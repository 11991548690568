import Cookies from 'js-cookie';
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import type { FetchArgs } from '@reduxjs/toolkit/query';

export const baseQuery = retry(
	async (args: string | FetchArgs, api, extraOptions) => {
		let result = await fetchBaseQuery({
			baseUrl: import.meta.env.VITE_CORE_API_URL,
			prepareHeaders: (headers) => {
				const accessToken = Cookies.get('access_token');
				if (accessToken) {
					headers.set('Authorization', `Bearer ${accessToken}`);
				}
				return headers;
			},
		})(args, api, extraOptions);

		if (result.error?.status === 401) {
			const refreshToken = Cookies.get('refresh_token');
			const refreshResult = await fetch(
				`${import.meta.env.VITE_CORE_API_URL}core/users/access-token/refresh/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						refresh: refreshToken,
					}),
				}
			);
			const response = await refreshResult.json();
			if (response.data) {
				const token = response.data.access;
				Cookies.set('access_token', token, { expires: 24 / 6 });
				result = await baseQuery(args, api, extraOptions);
			} else {
				Cookies.remove('access_token');
				Cookies.remove('refresh_token');
				localStorage.clear();
			}
			retry.fail(result.error);
		}

		return result;
	},
	{
		maxRetries: 0,
	}
);
