import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { BsFillClockFill, BsPlug, BsPerson, BsDownload } from 'react-icons/bs';
import { TransactionsType } from '../../../types';

interface Props {
	data: {
		cost: number;
		payment_method: string;
		payment_status: string;
		transaction_reference_number: string;
	};
}

export const CostDetails = ({ data }: Props) => {
	return (
		<div className="rounded-lg shadow-lg flex-1  bg-black/50 p-6 text-white space-y-4 transform transition duration-300 ease-in-out h-full">
			<div className="flex justify-between items-center">
				<h2 className="font-semibold text-lg flex items-center">
					<BsFillClockFill className="mr-4 text-yellow-500" />
					Cost Details
				</h2>
				{/* <Button className="bg-blue-500 hover:bg-blue-600">
					<BsDownload className="mr-2" />
					Download Receipt
				</Button> */}
			</div>
			<Separator className="bg-gray-500 h-[1px]" />
			<div className="">
				<ul className="space-y-3 flex-1">
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-bold text-sm">
								Cost breakdown of this session:
							</span>{' '}
							<span className="capitalize text-xs text-zinc-400 ">
								${data.cost}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPerson className="mr-4 text-green-500" />
						<div>
							<span className="font-semibold text-sm">Payment method:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.payment_method}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-semibold text-sm">Payment Status:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.payment_status}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-semibold text-sm">
								transaction reference number:
							</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.transaction_reference_number}
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

// Cost Details Section:
//  Display a cost breakdown of the session,
//  payment method,
//  payment status,
//  transaction reference number with an option to download the receipt.
