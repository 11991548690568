import { DeltaType } from '@/common/constants/enums';
import { todayRevenue } from './data/revenue';
import ChargerGroupContributionsCard from './components/charger-group-contributions-card';
import MonthlyRevenue from './components/monthly-revenue';
import { Navbar } from '@/components/navbar';
import { OverviewCard } from '@/components/overview-card';
import SettlementCycle from './components/settlment-cycle';
import TransactionsTable from './components/transaction-table/transactions-table';
import { isValidDeltaType } from '@/lib/helpers/delta-type';
import { SalesChart } from '../Home/components/sales-chart';
import { TotalRevenueCard } from '../Home/components/total-revenue-card';
import { useAppSelector } from '@/redux/hooks';
import { useEffect, useState } from 'react';
import { calculateRevenueChange } from '@/lib/helpers/calculate-revenue-change';

const Revenue = () => {
	const { totalRevenue, loading } = useAppSelector((state) => state.home);

	return (
		<div>
			<div
				style={{
					backgroundImage: 'url(/images/--gf-dashboard-cover.png)',
					backgroundSize: '100% 270px',
					backgroundRepeat: 'no-repeat',
				}}
				className="bg-cover md:px-9 px-4  md:pt-9 pt-4 flex flex-col 2xl:gap-11 gap-5 mb-10"
			>
				<div className="container">
					<Navbar title="Revenue Insight" />
				</div>
				<div className="container mx-auto flex flex-col gap-9 relative">
					<div className="flex  flex-col xl:flex-row gap-5">
						<div className="space-y-4 flex flex-col">
							{totalRevenue && (
								<div className="flex flex-col xl:flex-row gap-5 h-max">
									<TotalRevenueCard data={totalRevenue} loading={loading} />
								</div>
							)}
							<div className="flex flex-col xl:flex-row gap-5 h-full">
								<SettlementCycle />
							</div>
						</div>
						<div className="flex-1">
							<ChargerGroupContributionsCard />
						</div>
					</div>
					<div>
						<SalesChart />
					</div>
					<div>
						<TransactionsTable />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Revenue;
