import { TableRow } from '@/components/ui/table';
import React, { useState, useRef, useEffect } from 'react';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { X } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { toast } from 'sonner';
import { useOrg } from '@/lib/hooks/use-organisation';

type Props = { children: React.ReactNode };

export const InviteLink = ({ children }: Props) => {
	const { handleInviteLink } = useOrg();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleCopyLink = async () => {
		if (inputRef.current) {
			try {
				await navigator.clipboard.writeText(inputRef.current.value);
				inputRef.current.select();
				toast('Link copied to clipboard!');
			} catch (error) {
				toast('Failed to copy link to clipboard!');
			}
		}
	};

	useEffect(() => {
		handleInviteLink({ org_id: '4eb4d051a771428089b53903e334a700' });
	}, []);
	

	return (
		<>
			<div onClick={() => setIsModalOpen(true)} className="cursor-pointer">
				{children}
			</div>

			<Dialog
				modal
				open={isModalOpen}
				onOpenChange={() => setIsModalOpen(true)}
			>
				<DialogContent className="shadow-2xl p-4 text-white max-w-lg border bg-primary">
					<DialogHeader>
						<DialogTitle className="flex justify-between items-center">
							<h2>Invite Link</h2>
							<Button
								onClick={() => setIsModalOpen(false)}
								variant="secondary"
								type="button"
							>
								<X size={16} color="black" />
							</Button>
						</DialogTitle>
					</DialogHeader>

					<div className="relative">
						<Input
							ref={inputRef}
							className="py-6"
							value="https://core.dashboard.grdflo.com/invite/9J9sErd"
						/>
						<Button
							variant="secondary"
							className="absolute right-1 bottom-[4.5px]"
							onClick={handleCopyLink}
						>
							Copy link
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};
