import { ChargerGroupType } from '@/types/charger-network';
import { columns } from './column';
import { GroupDataTable } from './group-data-table';
import { generateGroupsRandomData } from '@/pages/(dashboard)/ChargerNetwork/data/chargers';
import { useEffect, useState } from 'react';
import { getChargerGroups } from '@/services/chargers';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useGetChargeGroupsQuery } from '@/redux/features/charger-network/charger-network.api';
import {
	setChargeGroups,
	startLoading,
} from '@/redux/features/charger-network/charger-network.slice';
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from '@/components/ui/pagination';
import { Skeleton } from '@/components/ui/skeleton';

export const GroupsTable: React.FC = () => {
	const { orgId } = useAppSelector((state) => state.auth);
	const dispatch = useAppDispatch();
	const {
		data: { chargeGroups: groups },
		loading,
	} = useAppSelector((state) => state.chargerNetwork);

	const [page, setPage] = useState(1);

	const { data, isFetching, isError } = useGetChargeGroupsQuery({
		orgId,
		page,
	});

	useEffect(() => {
		if (isFetching) {
			dispatch(startLoading());
		} else if (data) {
			dispatch(setChargeGroups(data));
		}
	}, [groups, dispatch, isFetching, data]);

	return (
		<div className=" bg-black p-6 rounded-lg border border-slate-400 text-white container">
			<div className="mb-4">
				<h2 className="text-2xl font-bold ">Your Groups.</h2>
				<p className="text-lg">Your current charger groups</p>
			</div>

			<GroupDataTable
				loading={loading}
				data={groups ? groups.results : []}
				columns={columns}
			/>

			{loading ? (
				<div className="flex  justify-end my-2">
					<div className="flex justify-end gap-2">
						<Skeleton className=" h-6 w-12 rounded-sm " />
						<Skeleton className=" h-6 w-12 rounded-sm " />
						<Skeleton className=" h-6 w-12 rounded-sm " />
					</div>
				</div>
			) : (
				data && (
					<Pagination className="justify-end pr-6 pt-4">
						<PaginationContent>
							{groups.previous && (
								<PaginationItem onClick={() => setPage(page - 1)}>
									<PaginationPrevious href="#" />
								</PaginationItem>
							)}
							<PaginationItem>
								<PaginationLink href="#" isActive>
									{page}
								</PaginationLink>
							</PaginationItem>
							{groups.next && (
								<PaginationItem onClick={() => setPage(page + 1)}>
									<PaginationNext href="#" />
								</PaginationItem>
							)}
						</PaginationContent>
					</Pagination>
				)
			)}
		</div>
	);
};
