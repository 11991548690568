import { useState } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { MembersCard } from './member-card';
import { useAppSelector } from '@/redux/hooks';
import { useGetTeamMembersQuery } from '@/redux/features/org/org.api';

const TeamTable = () => {
	const [page, setPage] = useState(1);
	const { orgId } = useAppSelector((state) => state.auth);

	const teamMembersQuery = useGetTeamMembersQuery(
		{
			orgId: orgId!,
			page: page,
		},
		{ refetchOnMountOrArgChange: true }
	);

	return (
    <div className="text-white  rounded-lg border-[1px] border-gray-600 overflow-hidden">
      <div className="flex justify-between items-center p-4  border-b-[1px] border-gray-600">
        <div className="flex gap-3 items-center">
          <Checkbox className="border-white" />
          <p className="text-sm font-light text-gray-300">Select all</p>
        </div>
        <div>
          <DotsHorizontalIcon />
        </div>
      </div>

      {teamMembersQuery.isLoading ? (
        <div>Loading </div>
      ) : (
        teamMembersQuery.isSuccess &&
        teamMembersQuery.data.data.results.map((item: any) => (
          <MembersCard
            key={item.core_user.id}
            userId={item.core_user.id}
            memberId={item.id}
            firstName={item.core_user.first_name}
            lastName={item.core_user.last_name}
            email={item.core_user.email}
            role={item.organization_role}
          />
        ))
      )}
    </div>
  );
};

export default TeamTable;
