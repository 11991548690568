import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import React, { useState, useEffect } from "react";
import { Zap, BatteryCharging, AlertCircle, Power } from "react-feather";
import { faker } from "@faker-js/faker";

import { ChargerGroupType } from "@/types/charger-network";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  useAddChargerGroupToTariffMutation,
  useGetChargeGroupsQuery,
  useGetChargersStateQuery,
} from "@/redux/features/charger-network/charger-network.api";
import {
  incrementGlobalChargerGroupsPage,
  setChargeGroups,
  setChargerStatus,
  startLoading,
} from "@/redux/features/charger-network/charger-network.slice";
import { CircularProgress } from "@mui/material";
import { useGetTafiffPlansQuery } from "@/redux/features/tariff/tariff.api";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";

export const ApplyTo = ({ id, tariffId }: { id: string; tariffId: string }) => {
  const { orgId } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  const [tariff, setTariff] = useState<
    { value: string; name: string }[] | null
  >(null);
  const [selectedTariff, setSelectedTariff] = useState<string | null>(null);
  const { data: tariffPlansData, isLoading: tariffPlansLoading } =
    useGetTafiffPlansQuery({
      orgId,
      page,
    });
  const [
    addChargerGroupToTariff,
    { isLoading: addChargerGroupToTariffLoading },
  ] = useAddChargerGroupToTariffMutation();

  const handleGroupChange = (value: string) => {
    setSelectedTariff(value);
    addChargerGroupToTariff({
      charger_group_id: id,
      tariff_plan_id: value,
    }).then((res) => {
      toast.success(res.data.message);
    });
  };

  useEffect(() => {
    if (tariffPlansData) {
      setTariff([
        ...tariffPlansData.results.map((result: any) => ({
          name: result.name,
          value: result.id,
        })),
      ]);
    }
  }, [tariffPlansData, tariff]);

  return (
    <Select
      disabled={tariffPlansLoading || addChargerGroupToTariffLoading}
      defaultValue={tariffId ?? undefined}
      onValueChange={handleGroupChange}
    >
      <SelectTrigger className="border-none bg-transparent">
        <SelectValue
          placeholder={
            tariffPlansLoading || addChargerGroupToTariffLoading
              ? "Loading"
              : "Apply Tariff Plan"
          }
        />
      </SelectTrigger>

      <SelectContent>
        {addChargerGroupToTariffLoading ? (
          <div className="flex justify-center py-2">
            <CircularProgress size={20} color="inherit" />
          </div>
        ) : tariff ? (
          <div>
            {tariffId && (
              <SelectItem value={null as any} className="text-red-500">
                Remove tariff plan
              </SelectItem>
            )}
            {tariff.map(({ value, name }, index) => (
              <SelectItem value={value} key={index}>
                {name}
              </SelectItem>
            ))}
            {tariffPlansData && tariffPlansData.next && (
              <Button
                variant="ghost"
                className="text-center w-full py-2 text-xs text-gray-500 hover:text-gray-300"
                onClick={() => setPage(page + 1)}
              >
                Load more
              </Button>
            )}
          </div>
        ) : (
          <div>No Charger groups found</div>
        )}
      </SelectContent>
    </Select>
  );
};
