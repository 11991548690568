import { Checkbox } from '@/components/ui/checkbox';
import { PendingMemberCardAction } from './pending-member-card-action';
import { MembersDetailsType } from '../../types';

type Prop = {
	data: MembersDetailsType;
};
export const PendingMemberCard = ({ data }: Prop) => {
	return (
    <div
      className={`p-4 border-b-[1px] border-gray-600 flex justify-between items-center `}
    >
      <div className="flex items-center gap-3">
        <Checkbox className="border-white" />
        <div className="flex items-center gap-1">
          <img src="/images/user-image.png" />
          <div>
            <h3 className="text-sm">{data.invited_user}</h3>
            <p className="text-xs text-gray-500">{data.invited_user}</p>
          </div>
        </div>
      </div>
      <div className="flex gap-4 items-center">
        <span className="text-sm">{data.role}</span>

        {data.expired ? (
          <span className=" opacity-70 italic bg-red-700 text-red-200 flex rounded-full py-1 px-2 duration-100 text-xs">
            Expired
          </span>
        ) : (
          <span className=" opacity-70 italic bg-opacity-20 bg-[#999871] text-[#ccce97] flex rounded-full py-1 px-2 duration-100 text-xs">
            Pending
          </span>
        )}

        {/* {!data.accepted && (
          <span className=" opacity-70 italic bg-opacity-20 bg-[#999871] text-[#ccce97] flex rounded-full py-1 px-2 duration-100 text-xs">
            Pending
          </span>
        )} */}
        <PendingMemberCardAction data={data} />
      </div>
    </div>
  );
};
