import * as z from 'zod';
import { EyeIcon, EyeOff } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { useState, ChangeEvent } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '@/lib/hooks/use-auth';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { signupFormSchema } from './schema';
import { steps } from './steps';
import { AddressAutoComplete } from '@/components/address-auto-complete';
import { useSignupMutation } from '@/redux/features/auth/auth.api';
import { toast } from 'sonner';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const Signup = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	// search params that are passed from the /acceptInvitation route
	// search params should be passed to the login route on successful signup

	const inviteToken = searchParams.get('inviteToken') || null;
	const invitedUserEmail = searchParams.get('userEmail') || null;
	const orgName = searchParams.get('orgName') || null;

	const [currentStep, setCurrentStep] = useState(0);
	const totalSteps = steps.length;
	const [phone, setPhone] = useState('');
	const [passwordType, setPasswordType] = useState(true);
	// Do not attempt to change/"fix" the name of this variable anywhere you see it.
	const EyeComponent = passwordType ? EyeIcon : EyeOff;

	const signupDetailsForm = useForm<z.infer<typeof signupFormSchema>>({
    defaultValues: {
      email: invitedUserEmail || "",
    },
    resolver: zodResolver(signupFormSchema),
  });

	const [signup, { isLoading, error, data }] = useSignupMutation();

	const stepValidationFields: Array<
		Array<keyof z.infer<typeof signupFormSchema>>
	> = [
		[
			'first_name',
			'last_name',
			'phone_number',
			'email',
			'address',
			'lat',
			'lng',
		],
		['password', 'confirm_password', 'terms'],
	];

	const handleSignup = async (values: z.infer<typeof signupFormSchema>) => {
		const result = {
			email: values.email,
			phone_number: values.phone_number,
			first_name: values.first_name,
			last_name: values.last_name,
			password: values.password,
			address: values.address,
			latitude: values.lat,
			longitude: values.lng,
			invite_token: inviteToken ? inviteToken : undefined,
		};

		try {
			await signup(result)
				.unwrap()
				.then((data) => {
					if (data.response_status === 'success' && data.data) {
						toast.success(data.message);
						if (!inviteToken) {
							setTimeout(() => {
								navigate(`/signup/verify-email?email=${data.data.email}`);
							}, 2000);
						} else {
							toast.success(
								'You have successfully joined the organization, and can view the organization details upon login.'
							);
							setTimeout(() => {
								navigate(`/login`);
							}, 2000);
						}
					}
				});
		} catch (error: any) {
			if (error) {
				toast.error(error?.data.error_message);
			}
		}
	};

	const nextStep = async () => {
		const fieldsToValidate = stepValidationFields[currentStep];
		const isValid = await signupDetailsForm.trigger(fieldsToValidate);
		if (!isValid) return;

		if (currentStep < totalSteps - 1)
			setCurrentStep(currentStep < totalSteps ? currentStep + 1 : currentStep);
	};

	const prevStep = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
		}
	};

	return (
    <div className="h-full flex  items-center justify-center ">
      <div className="  p-5   w-full md:w-[80%] m-4 max-w-[600px] mx-auto">
        <div>
          <div className="flex items-center flex-col justify-center gap-3 my-3">
            <img src="/images/logo-text.png" height={50} width={150} />
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
            <span className="text-white text-sm font-light w-max mx-2 whitespace-nowrap text-center ">
              Register your account
            </span>
            <div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
          </div>
        </div>

        {invitedUserEmail && orgName && inviteToken && (
          <div className="bg-muted-foreground bg-[#eab308] px-3 py-4 my-4 rounded-md">
            <p className="text-sm">
              By signing up, you will be automatically added to{" "}
              <span className="underline underline-offset-2 font-bold">
                {orgName}
              </span>
            </p>
          </div>
        )}
        <Form {...signupDetailsForm}>
          <form
            className="my-8 flex flex-col gap-6"
            onSubmit={signupDetailsForm.handleSubmit(handleSignup)}
          >
            <div className="flex flex-col gap-6">
              {currentStep === 0 && (
                <>
                  <div className="flex gap-4">
                    <FormField
                      control={signupDetailsForm.control}
                      name="first_name"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="First name"
                              className=""
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className=" text-[10px]" />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={signupDetailsForm.control}
                      name="last_name"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="Last name"
                              className=""
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className=" text-[10px]" />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={signupDetailsForm.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <Input
                            disabled={Boolean(invitedUserEmail)}
                            type="email"
                            placeholder="Email Address"
                            className=""
                            {...field}
                          />
                        </FormControl>
                        <FormMessage className=" text-[10px]" />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={signupDetailsForm.control}
                    name="phone_number"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <PhoneInput
                            defaultCountry="hk"
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                            inputClassName="p-10 w-full !bg-background border border-input !border-l-0 !rounded-r-md !text-white !font-medium !py-2 !text-sm"
                            countrySelectorStyleProps={{
                              buttonClassName:
                                "!py-2 !px-3 border-input rounded-l-md border-r-0",
                            }}
                          />
                        </FormControl>
                        <FormMessage className=" text-[10px]" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={signupDetailsForm.control}
                    name="address"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <AddressAutoComplete
                            onSelectAddress={(address, latitude, longitude) => {
                              signupDetailsForm.setValue("address", address);
                              signupDetailsForm.setValue(
                                "lat",
                                latitude as number
                              );
                              signupDetailsForm.setValue(
                                "lng",
                                longitude as number
                              );
                            }}
                            defaultValue={field.value}
                          />
                        </FormControl>
                        <FormMessage className=" text-[10px]" />
                      </FormItem>
                    )}
                  />

                  <Button
                    type="button"
                    className="bg-[#eab308] text-black hover:bg-[#eab308]/60 w-full md:w-[60%] mx-auto "
                    onClick={nextStep}
                  >
                    Continue
                  </Button>
                </>
              )}

              {currentStep === 1 && (
                <>
                  <FormField
                    control={signupDetailsForm.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <div className="relative transition-all duration-150 group">
                            <Input
                              type={passwordType ? "password" : "text"}
                              placeholder="Password"
                              className=""
                              {...field}
                            />

                            <EyeComponent
                              className="absolute z-10 right-2 bottom-3 cursor-pointer hidden group-hover:block"
                              size={16}
                              onClick={() => {
                                setPasswordType((prev) => !prev);
                              }}
                            />
                          </div>
                        </FormControl>
                        <FormMessage className=" text-[10px]" />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={signupDetailsForm.control}
                    name="confirm_password"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <div className="relative transition-all duration-150 group">
                            <Input
                              type={passwordType ? "password" : "text"}
                              placeholder="Confirm Password"
                              className=""
                              {...field}
                            />

                            <EyeComponent
                              className="absolute z-10 right-2 bottom-3 cursor-pointer hidden group-hover:block"
                              size={16}
                              onClick={() => {
                                setPasswordType((prev) => !prev);
                              }}
                            />
                          </div>
                        </FormControl>
                        <FormMessage className=" text-[10px]" />
                      </FormItem>
                    )}
                  />
                </>
              )}
            </div>
            {currentStep === 1 && (
              <>
                <div className="flex items-center space-x-2 my-2 px2-">
                  <FormField
                    control={signupDetailsForm.control}
                    name="terms"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <div className="flex items-center space-x-2 my-2 px2-">
                            <Checkbox
                              id="terms"
                              className="bg-transparent text-white text-sm font-light"
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                            <Label
                              htmlFor="terms"
                              className="text-white text-xs font-light peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              By registering, you agree to our{" "}
                              <span className="font-semibold">
                                Terms & Condition{" "}
                              </span>
                              and
                              <span className="font-semibold">
                                {" "}
                                Privacy Policy
                              </span>
                              .
                            </Label>
                          </div>
                        </FormControl>
                        <FormMessage className=" text-[10px]" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-2 ">
                  <Button
                    type="submit"
                    disabled={isLoading}
                    className="bg-[#eab308] text-black hover:bg-[#eab308]/60  w-full md:w-[60%] mx-auto"
                  >
                    Signup
                  </Button>

                  <Button
                    type="button"
                    variant="outline"
                    className=" border-[#eab308]  w-full md:w-[60%] mx-auto "
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                </div>
              </>
            )}
          </form>
        </Form>
        <div>
          <h2 className="text-white text-xs font-light ">
            Already have an account?{" "}
            <Link to="/login" className="underline font-bold">
              Login here
            </Link>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Signup;
