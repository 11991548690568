// import { Search } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import SidebarMobile from "./sidebar-mobile";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { orgType, setOrgId } from "@/redux/features/auth/auth.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useGetOrganizationsQuery } from "@/redux/features/org/org.api";
import {
  setOrganisations,
  startLoading,
  setCurrentOrg,
} from "@/redux/features/org/org.slice";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { ScrollArea } from "./ui/scroll-area";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils/cn";
import { User, X } from "lucide-react";
import { CreateOrganisation } from "@/pages/(dashboard)/Settings/components/create-organisation-modal/create-organisation";

interface Props {
  title: string;
}
export const Navbar: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { orgId, isLoading } = useAppSelector((state) => state.auth);

  const { loading, currentOrg, organisations } = useAppSelector(
    (state) => state.org
  );

  const { data: organizationsData, isLoading: organiszationsLoading } =
    useGetOrganizationsQuery(undefined);

  const [logoutModal, setLogoutModal] = useState(false);
  const [orgModal, setOrgModal] = useState(false);

  const [privateOrg, setPrivateOrg] = useState<orgType | null | undefined>(
    null
  );
  const [publicOrg, setPublicOrg] = useState<orgType[] | null | undefined>(
    null
  );

  const handleUserLogout = useCallback(() => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    localStorage.clear();

    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }, []);

  useEffect(() => {
    if (organiszationsLoading) {
      dispatch(startLoading());
    } else if (organizationsData) {
      dispatch(setOrganisations(organizationsData.data));
    }
  }, [organiszationsLoading, dispatch, organizationsData]);

  const handleOrgChange = (value: string) => {
    dispatch(setOrgId(value));
    navigate("/");
  };

  useEffect(() => {
    if (organisations) {
      dispatch(setCurrentOrg(organisations.find((org) => org.id === orgId)));
      setPrivateOrg(organisations.find((data) => data.org_type == "Private"));
      setPublicOrg(
        organisations.filter((data) => data.org_type == "Multi-user")
      );
    }
  }, [organisations, orgId]);

  return (
    <div className="flex md:items-center justify-between gap-4 mt-9">
      <div className="backdrop-blur-xl  bg-gradient-radial p-4 rounded-xl md:hidden block">
        <SidebarMobile />
      </div>
      <div className="rounded-xl filter backdrop-blur-xl  bg-gradient-radial relative md:block flex items-center justify-center">
        <h3 className="text-white md:text-[28px] text-lg  py-1 px-4">
          {title}
        </h3>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex gap-4 rounded-full p-2 filter backdrop-blur-xl  bg-gradient-radial "
          >
            <User />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-background text-white border-[#323232] ">
          {currentOrg && (
            <DropdownMenuLabel className="flex gap-2 items-center bg-transparent">
              <Avatar className="relative flex h-6 w-6 shrink-0 overflow-hidden rounded-full">
                <AvatarImage src={currentOrg.org_profile_picture} />
                <AvatarFallback>
                  {currentOrg?.org_name?.charAt(0)}
                </AvatarFallback>
              </Avatar>
              <span>{currentOrg.org_name}</span>
              <Button
                variant="ghost"
                onClick={() => setOrgModal(true)}
                className=" justify-center text-muted-foreground max-w-[40px] w-full overflow-auto h-auto px-2 py-1 text-xs"
              >
                <CaretSortIcon />
              </Button>
            </DropdownMenuLabel>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem disabled>Support</DropdownMenuItem>
          <DropdownMenuItem>
            <Link to={`/settings`}>Settings</Link>
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleUserLogout} className="text-red-400">
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog modal open={orgModal}>
        <DialogContent className="max-w-sm bg-transparent border-[#323232] gap-0">
          <Command className="border-[#323232] ">
            <div className="relative">
              <CommandInput className="h-9" placeholder="search..." />

              <Button
                variant="secondary"
                onClick={() => setOrgModal(false)}
                className="absolute top-1 right-1 p-1 h-auto"
              >
                <X size={20} />
              </Button>
            </div>
            <CommandList>
              <CommandEmpty>No organisation found.</CommandEmpty>{" "}
              <ScrollArea className="max-h-64 ">
                <CommandGroup
                  className=" overflow-auto divide-y-2"
                  heading="Private"
                >
                  {privateOrg && (
                    <CommandItem
                      value={privateOrg.org_name}
                      onSelect={() => {
                        handleOrgChange(privateOrg.id);
                        setOrgModal(false);
                      }}
                      className="gap-2"
                      disabled={loading}
                    >
                      <Avatar className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                        <AvatarImage src={privateOrg.org_profile_picture} />
                        <AvatarFallback>
                          {privateOrg?.org_name?.charAt(0)}
                        </AvatarFallback>
                      </Avatar>

                      <span className="text-sm font-medium">
                        {privateOrg.org_name}
                      </span>
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          privateOrg.id === orgId ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  )}
                </CommandGroup>
                <CommandGroup
                  className="h-56 overflow-auto divide-y-2"
                  heading="Public"
                >
                  {publicOrg &&
                    publicOrg.map((org, index) => (
                      <CommandItem
                        key={index}
                        value={org.org_name}
                        onSelect={() => {
                          handleOrgChange(org.id);
                          setOrgModal(false);
                        }}
                        className="gap-2"
                        disabled={loading}
                      >
                        <Avatar className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                          <AvatarImage src={org.org_profile_picture} />
                          <AvatarFallback>
                            {org?.org_name?.charAt(0)}
                          </AvatarFallback>
                        </Avatar>

                        <span className="text-sm font-medium">
                          {org.org_name}
                        </span>
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            org.id === orgId ? "opacity-100" : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    ))}
                </CommandGroup>
              </ScrollArea>
            </CommandList>
          </Command>
          <CreateOrganisation />
        </DialogContent>
      </Dialog>
    </div>
  );
};
