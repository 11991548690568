import { ColumnDef } from '@tanstack/react-table';
import { Checkbox } from '@/components/ui/checkbox';
import { Revenue } from '../../data/schema';
import { DataTableColumnHeader } from '@/components/ui/data-table/data-table-column-header';
import { Actions } from './actions';
import { formatDate, formatDateShort } from '@/lib/helpers/dates';
import { statuses } from '../../data/data';
import { extractFirstId } from '../../../../../lib/helpers/extract-first-id';
import { AmountRow } from '../../../../../components/amount-row';

export const columns: ColumnDef<Revenue>[] = [
	{
		id: 'select',
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllPageRowsSelected()}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
				className="translate-y-[2px]"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={(value) => row.toggleSelected(!!value)}
				aria-label="Select row"
				className="translate-y-[2px]"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'session_id',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Session ID" />
		),
		cell: ({ row }) => (
			<div className=" uppercase">{row.getValue('session_id')}</div>
		),

		enableSorting: false,
		enableHiding: false,
	},

	{
		accessorKey: 'charger_id',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Charger ID" />
		),
		cell: ({ row }) => (
			<div className="uppercase whitespace-nowrap">
				{row.getValue('charger_id')}
			</div>
		),

		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'amount',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Amount" />
		),
		cell: ({ row }) => <AmountRow amount={row.getValue('amount')} />,

		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'charger_group_id',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Charger Group ID" />
		),
		cell: ({ row }) => (
			<div className="uppercase">
				{extractFirstId(row.getValue('charger_group_id'))}
			</div>
		),

		enableSorting: false,
		enableHiding: false,
	},

	{
		accessorKey: 'charger_location',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Charger Location" />
		),
		cell: ({ row }) => (
			<div className="capitalize truncate">
				{row.getValue('charger_location')}
			</div>
		),

		enableSorting: false,
		enableHiding: false,
	},

	{
		accessorKey: 'connector_id',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Connector ID" />
		),
		cell: ({ row }) => (
			<div className="capitalize">
				{extractFirstId(row.getValue('connector_id'))}
			</div>
		),

		enableSorting: false,
		enableHiding: false,
	},

	{
		accessorKey: 'payment_method',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Payment Method" />
		),
		cell: ({ row }) => (
			<div className="capitalize">{row.getValue('payment_method')}</div>
		),

		enableSorting: false,
		enableHiding: false,
	},

	{
		accessorKey: 'status',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Status" />
		),
		cell: ({ row }) => {
			const status = statuses.find(
				(status) => status.value === row.getValue('status')
			);

			if (!status) {
				return null;
			}

			return (
				<div className="flex w-[100px] items-center">
					{status.icon && (
						<status.icon
							size={20}
							className={`mr-2 h-4 w-4 ${
								status.value === 'Failed'
									? 'text-red-500/80 animate-pulse'
									: status.value === 'Completed'
										? 'text-green-500/80'
										: 'text-yellow-500/80'
							}`}
						/>
					)}
					<span>{status.label}</span>
				</div>
			);
		},
		filterFn: (row, id, value) => {
			return value.includes(row.getValue(id));
		},
	},
	{
		accessorKey: 'transaction_start_time',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Start Time" />
		),
		cell: ({ row }) => (
			<div className="truncate">
				{formatDateShort(row.getValue('transaction_start_time'))}
			</div>
		),

		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'transaction_end_time',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="End Time" />
		),
		cell: ({ row }) => (
			<div className="truncate">
				{formatDateShort(row.getValue('transaction_end_time'))}
			</div>
		),

		enableSorting: false,
		enableHiding: false,
	},

	{
		id: 'actions',
		cell: ({ row }) => <Actions row={row} />,
	},
];
