import * as z from 'zod';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { useAuth } from '@/lib/hooks/use-auth';
import { useForgotPasswordMutation } from '@/redux/features/auth/auth.api';
import { toast } from 'sonner';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';

type Props = {};

const getCreateNewPasswordPath = (email: string) => {
	return '/create-new-password/';
};

const ForgotPasswordFormSchema = z.object({
	email: z
		.string()
		.email({ message: 'Invalid email address' })
		.max(100, { message: 'Email address is too long' }),
	frontend_url: z.string().default(window.location.origin),
});

const ForgotPassword = (props: Props) => {
	const forgotPasswordDetailsForm = useForm<
		z.infer<typeof ForgotPasswordFormSchema>
	>({
		resolver: zodResolver(ForgotPasswordFormSchema),
	});

	const [forgotPassword, { isLoading, error, data }] =
		useForgotPasswordMutation();

	const [isOpen, setIsOpen] = useState(false);

	const handleSubmit = async (
		values: z.infer<typeof ForgotPasswordFormSchema>
	) => {
		const frontend_url =
			window.location.origin + getCreateNewPasswordPath(values.email);
		try {
			const response = await forgotPassword({ ...values, frontend_url })
				.unwrap()
				.then((data) => {
					if (data.response_status == 'success') {
						forgotPasswordDetailsForm.reset();
						setIsOpen(true);
						toast.success(data.message);
					}
				});
		} catch (error: any) {
			if (error) {
				toast.error(error?.data.error_message);
			}
		}
	};

	return (
		<div className="flex items-center justify-center h-full ">
			<div className="flex relative items-center justify-center h-full w-full">
				<div className="flex flex-col gap-4  max-w-[600px] w-full space-y-6">
					<div className="space-y-2">
						<div className="flex items-center flex-col justify-center gap-3 my-3">
							<img src="/images/logo-text.png" height={50} width={150} />
						</div>
						<div className="flex gap-2 items-center">
							<div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
							<span className="text-white text-sm font-light w-max mx-2 whitespace-nowrap text-center ">
								Forgot password
							</span>
							<div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
						</div>
						<p className="text-slate-200 font-light text-xs max-w-[350px] text-center mx-auto">
							Enter your registered email address below & we will send an email
							with instructions to reset your password.
						</p>
					</div>

					<Form {...forgotPasswordDetailsForm}>
						<form
							onSubmit={forgotPasswordDetailsForm.handleSubmit(handleSubmit)}
							className="flex flex-col items-center gap-4"
						>
							<FormField
								control={forgotPasswordDetailsForm.control}
								name="email"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormControl>
											<Input
												type="email"
												placeholder="Email Address"
												className=""
												{...field}
											/>
										</FormControl>
										<FormMessage className=" text-[10px]" />
									</FormItem>
								)}
							/>

							<Button
								disabled={isLoading}
								className=" bg-[#eab308] text-black hover:bg-[#eab308]/60 w-full md:w-[60%]"
							>
								Continue
							</Button>
						</form>
					</Form>
				</div>
			</div>

			<Dialog modal open={isOpen} onOpenChange={() => setIsOpen(true)}>
				<DialogContent className="sm:max-w-lg shadow-sm border-0 bg-black  bg-opacity-40 ">
					<X
						className="h-4 w-4 right-4 top-4 z-10 absolute rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-gray-100 data-[state=open]:text-gray-500 dark:ring-offset-gray-950 dark:focus:ring-gray-300 dark:data-[state=open]:bg-gray-800 dark:data-[state=open]:text-gray-400"
						onClick={() => setIsOpen(false)}
					/>
					<div className=" backdrop-blur-xl flex- flex flex-col gap-4 bg-black bg-opacity-20 p-4 rounded-xl items-center  justify-center w-full">
						<img src="/icons/check-email-2.png" className="h-32 w-32" />

						<p className="text-slate-200 font-light text-xs text-center w-full">
							We have sent a password recovery instruction to your email.
						</p>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ForgotPassword;
