import React, { useState, useEffect } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import CircularProgress from '@mui/material/CircularProgress';
import {
	getChargerGroups,
	getChargersUtilizationRate,
} from '@/services/chargers';
import { InfiniteMovingCards } from '@/components/ui/infinite-moving-cards';
import { ChargerGroupType } from '@/types/charger-network';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
	useGetChargeGroupsQuery,
	useGetChargeGroupGlobalQuery,
	useGetChargersUtilizationRateQuery,
} from '@/redux/features/charger-network/charger-network.api';
import {
	incrementGlobalChargerGroupsPage,
	setChargeGroups,
	setChargerStatus,
	setChargerUtilizationRate,
	startLoading,
} from '@/redux/features/charger-network/charger-network.slice';
import { useSelector } from 'react-redux';

interface ChargerUtilizationData {
	chargerId: string;
	utilization: number;
}

export const ChargerUtilization: React.FC = () => {
	const { orgId } = useAppSelector((state) => state.auth);
	const globalChargeGroupsPage = useAppSelector(
		(state) => state.chargerNetwork.globalChargerGroups.page
	);

	const dispatch = useAppDispatch();
	const {
		data: { chargeGroups: groups, chargerUtilizationRate },
		loading,
	} = useAppSelector((state) => state.chargerNetwork);

	const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
	const [chargerData, setChargerData] = useState<any>();

	const {
		data: groupsData,
		isFetching,
		isError,
		isLoading,
	} = useGetChargeGroupsQuery({ orgId: orgId, page: globalChargeGroupsPage });

	const globalChargeGroups = useGetChargeGroupGlobalQuery({
		orgId: orgId,
		page: globalChargeGroupsPage,
	});

	const {
		data: utilizationData,
		isFetching: utilizationFetching,
		isError: utilizationError,
		isLoading: utilizationIsLoading,
	} = useGetChargersUtilizationRateQuery({
		org_id: orgId,
		group_id: selectedGroup,
	});

	useEffect(() => {
		if (isFetching) {
			dispatch(startLoading());
		} else if (groupsData) {
			dispatch(setChargeGroups(groupsData));
		}
		if (utilizationFetching) {
			dispatch(startLoading());
		} else if (utilizationData) {
			dispatch(setChargerUtilizationRate(utilizationData));
		}
	}, [dispatch, isFetching, utilizationFetching, utilizationData, groupsData]);

	const handleGroupChange = (value: string) => {
		setSelectedGroup(value);
	};

	const loadMoreData = (data: any) => {
		if (data.next) {
			dispatch(incrementGlobalChargerGroupsPage());
		}
	};

	return (
		<div className="backdrop-blur-xl h-full bg-gradient-linear relative p-4 rounded-lg shadow-2xl text-white flex flex-col justify-between flex-1 mb-6">
			<div className="flex items-center gap-4 justify-between">
				<h2 className="text-lg">Chargers Utilization</h2>
				<div className="flex-[.5]">
					<Select onValueChange={handleGroupChange}>
						<SelectTrigger className="flex-[.2]">
							<SelectValue placeholder="All groups" />
						</SelectTrigger>
						<SelectContent>
							{globalChargeGroups.isLoading ? (
								<div className="flex justify-center py-2">
									<CircularProgress size={20} color="inherit" />
								</div>
							) : globalChargeGroups.data &&
							  Object.keys(globalChargeGroups.data).length !== 0 ? (
								<div>
									{globalChargeGroups.data.results.map((group: any) => (
										<SelectItem value={group.id} key={group.name}>
											{group.name}
										</SelectItem>
									))}
									{globalChargeGroups.data.results.length > 0 &&
										!globalChargeGroups.isFetching &&
										globalChargeGroups.data.next && (
											<button
												className="text-center w-full py-2 text-xs text-gray-500 hover:text-gray-300"
												onClick={() => loadMoreData(globalChargeGroups.data)}
											>
												Load more
											</button>
										)}

									{globalChargeGroups.isFetching && (
										<div className="flex justify-center py-2">
											<CircularProgress size={20} color="inherit" />
										</div>
									)}
								</div>
							) : (
								<div>No Charge groups found</div>
							)}
						</SelectContent>
					</Select>
				</div>
			</div>

			<div className="rounded-md flex flex-col antialiased  dark:bg-grid-white/[0.05] items-center justify-center relative overflow-hidden">
				{loading ? (
					<p>loading</p>
				) : chargerData ? (
					<InfiniteMovingCards
						speed="slow"
						items={chargerData}
						direction="right"
					/>
				) : (
					<p>No Data to display</p>
				)}
			</div>
		</div>
	);
};
