import { AuthProvider } from '@/store/auth/providers';
import { Toaster } from '@/components/ui/sonner';
import { ToastProvider, Toast as UiToaster } from '@/components/ui/toast.tsx';
import { BrowserRouter as Router } from 'react-router-dom';
import OrganisationProvider from '@/store/organisation/providers';
import GlobalProvider from '@/store/global/providers';
import Client from './client';
import RevenueProvider from '@/store/revenue/providers';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import { TooltipProvider } from '@/components/ui/tooltip';

const Providers = ({ children }: { children: React.ReactNode }) => {
	return (
		<Client>
			<Provider store={store}>
				<Router>
					<TooltipProvider>
						<Toaster className="text-white" />
						<ToastProvider>
							<GlobalProvider>
								<AuthProvider>
									<OrganisationProvider>
										<RevenueProvider>{children}</RevenueProvider>
									</OrganisationProvider>
								</AuthProvider>
							</GlobalProvider>
						</ToastProvider>
					</TooltipProvider>
				</Router>
			</Provider>
		</Client>
	);
};

export default Providers;
