import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MobileWarning, NotFound, ReRoute } from '../pages';
import { appRoutes } from '@/common/routes/route';

function App() {
	const [showMobileWarning, setShowMobileWarning] = useState(false);

	useEffect(() => {
		if (window.innerWidth <= 800) setShowMobileWarning(true);
	}, []);

	if (showMobileWarning) return <MobileWarning />;

	return (
		<DndProvider backend={HTML5Backend}>
			<Routes>
				{appRoutes.map(({ path, element }, index) => (
					<Route key={index} path={path} element={element} />
				))}
				<Route path="/*" element={<NotFound />} />
			</Routes>
		</DndProvider>
	);
}

export default App;
