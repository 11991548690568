import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useGoogleMapsScript, Libraries } from "use-google-maps-script";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { toast } from "sonner";

type Props = {};

interface ISearchBoxProps {
  onSelectAddress: (
    address: string,
    latitude: number | null,
    longitude: number | null
  ) => void;
  defaultValue: string;
}

const libraries: Libraries = ["places"];

export const AddressAutoComplete = ({
  onSelectAddress,
  defaultValue,
}: ISearchBoxProps) => {
  const { isLoaded, loadError } = useGoogleMapsScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY ?? "",
    libraries,
  });

  if (!isLoaded) return null;
  if (loadError) return <div>Error loading</div>;

  return (
    <SearchBox onSelectAddress={onSelectAddress} defaultValue={defaultValue} />
  );
};

const SearchBox = ({ onSelectAddress, defaultValue }: ISearchBoxProps) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 300, defaultValue });

  const handleChange = (e: string) => {
    setValue(e);

    if (e === "") {
      onSelectAddress("", null, null);
    }
  };

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      onSelectAddress(address, lat, lng);
    } catch (error) {
      toast.error(`😱 Error: ${error}`);
    }
  };

  return (
    <Command>
      <CommandInput
        id="search"
        placeholder="Address"
        value={value}
        disabled={!ready}
        className="w-full p-2 "
        // Do not attemt to change this to something meaningful,
        autoComplete="grdfff"
        onValueChange={handleChange}
        onFocus={(event) => {
          event.target.setAttribute("autocomplete", "grdfff");
        }}
      />
      <CommandList className="max-h-[100px]">
        <CommandGroup>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <CommandItem
                key={place_id}
                value={description}
                onSelect={handleSelect}
              >
                <span>{description}</span>
              </CommandItem>
            ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
