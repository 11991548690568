import { columns } from './column';
import { ChargerDataTable } from './charger-data-table';
import { ChargerGroupType, ChargePointType } from '@/types/charger-network';
import { useEffect, useState } from 'react';
import { getChargePoints } from '@/services/chargers';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useGetChargePointsQuery } from '@/redux/features/charger-network/charger-network.api';
import {
	setChargePoints,
	setShowInstructions,
	startLoading,
} from '@/redux/features/charger-network/charger-network.slice';
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from '@/components/ui/pagination';
import { Skeleton } from '@/components/ui/skeleton';
import { Button } from '@/components/ui/button';

export const ChargerTable: React.FC = () => {
	const [page, setPage] = useState(1);
	const { orgId } = useAppSelector((state) => state.auth);

	const dispatch = useAppDispatch();
	const {
		data: { chargePoints: chargers },
		loading,
	} = useAppSelector((state) => state.chargerNetwork);

	const { data, isFetching, isError, isLoading } = useGetChargePointsQuery({
		orgId,
		page,
	});

	useEffect(() => {
		if (isFetching) {
			dispatch(startLoading());
		} else if (data) {
			dispatch(setChargePoints(data));
		}
	}, [chargers, data, dispatch, isFetching]);

	return (
		<div className=" bg-black p-6 rounded-lg border border-slate-400 text-white container">
			<div className="mb-4 flex justify-between">
				<h2 className="text-2xl font-bold ">Your charge points.</h2>
				<Button
					onClick={() => dispatch(setShowInstructions(true))}
					variant="link"
					className="text-sm text-[#666] px-0"
				>
					How to connect my charge points?
				</Button>
			</div>

			<ChargerDataTable
				loading={loading}
				data={chargers ? chargers.results : []}
				columns={columns}
			/>

			{loading ? (
				<div className="flex  justify-end my-2">
					<div className="flex justify-end gap-2">
						<Skeleton className=" h-6 w-12 rounded-sm " />
						<Skeleton className=" h-6 w-12 rounded-sm " />
						<Skeleton className=" h-6 w-12 rounded-sm " />
					</div>
				</div>
			) : (
				chargers && (
					<Pagination className="justify-end pr-6 pt-4">
						<PaginationContent>
							{chargers.previous && (
								<PaginationItem onClick={() => setPage(page - 1)}>
									<PaginationPrevious href="#" />
								</PaginationItem>
							)}
							<PaginationItem>
								<PaginationLink href="#" isActive>
									{page}
								</PaginationLink>
							</PaginationItem>
							{chargers.next && (
								<PaginationItem onClick={() => setPage(page + 1)}>
									<PaginationNext href="#" />
								</PaginationItem>
							)}
						</PaginationContent>
					</Pagination>
				)
			)}
		</div>
	);
};
