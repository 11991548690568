import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Trash } from "lucide-react";
import React, { useState } from "react";
import { IoIosWarning } from "react-icons/io";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { useDeleteAccountMutation } from "@/redux/features/auth/auth.api";
import { persistor } from "@/redux/store";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

interface DeleteAccountProps {}

const FormSchema = z.object({
  deleteAccount: z.boolean(),
});

const DeleteAccount: React.FC<DeleteAccountProps> = ({}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const [deleteAccount, { isLoading }] = useDeleteAccountMutation();

  function onSubmit(data: z.infer<typeof FormSchema>) {
    deleteAccount(data)
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        persistor.purge();
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        localStorage.clear();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      });
  }

  return (
    <div className="">
      <h2 className="text-2xl font-bold">Delete Account</h2>
      <hr className="my-2 border-[.2px] border-slate-600 mt-6" />
      <div className="flex justify-between items-center">
        <p>
          If you no longer wish to use GridFlow, you can{" "}
          <span>permanently</span> delete you account
        </p>

        <Dialog modal open={isOpen}>
          <DialogTrigger>
            <Button
              type="button"
              variant="destructive"
              className="font-medium gap-2 px-8 "
              onClick={() => setIsOpen(true)}
            >
              <IoIosWarning size={18} />
              Delete
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px] border-none px-4 py-8">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <div className="flex flex-col items-center gap-3">
                  <div className="bg-red-100/80 p-2 rounded-full">
                    <Trash color="red" />
                  </div>

                  <h2 className="text-2xl font-bold">Delete Account</h2>

                  <p className="text-sm ">
                    Are you sure you'll like to delete your account with us?
                  </p>
                </div>
                <FormField
                  control={form.control}
                  name="deleteAccount"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-2 shadow">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel className="text-xs">
                          I understand that I won't be able to ever recover my
                          account.
                        </FormLabel>
                        <FormDescription className="text-[10px]">
                          All your existing data will be deleted!
                        </FormDescription>
                      </div>
                    </FormItem>
                  )}
                />
                <DialogFooter>
                  <Button
                    variant="outline"
                    className="border-red-500"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isLoading}
                    variant="destructive"
                    type="submit"
                  >
                    Submit
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default DeleteAccount;
