import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils/cn';
import React from 'react';

interface Props {
	onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
	children: React.ReactNode;
	className?: string;
}

export const ZoomButton: React.FC<Props> = ({
	onClick,
	children,
	className,
}) => (
	<Button
		onClick={onClick}
		// variant="secondary"
		className={cn('z-10 relative', className)}
	>
		{children}
	</Button>
);
