import { useContext } from 'react';

import { GlobalContext } from '@/store/global/context';

export const useGlobal = () => {
	const context = useContext(GlobalContext);
	if (!context) {
		throw new Error('useGlobal must be used within an GlobalProvider');
	}
	return context;
};
