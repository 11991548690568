import { Button } from '@/components/ui/button';
import { Cross } from 'lucide-react';
import { EditTariffPlansModal } from '../tariff-plans/edit-tariff-plans-modal';
import { AddPricePlanModal } from '../tariff-plans/add-price-plan-modal';

export function ViewOptions() {
	return (
		<AddPricePlanModal>
			<Button
				variant="outline"
				size="sm"
				className="ml-auto hidden h-8 lg:flex"
			>
				<Cross className="mr-2 h-4 w-4" />
				Add tariff plans
			</Button>
		</AddPricePlanModal>
	);
}
