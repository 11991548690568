import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from '@/components/ui/sheet';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Cross, X } from 'lucide-react';
import { driverRoles } from '@/pages/(dashboard)/AccessControl/data/drivers-table-data';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { useGlobal } from '@/lib/hooks/use-global';
import { useEffect, useState } from 'react';
import { AddChargerGroupType } from '@/types/charger-network';
import { addChargerGroup } from '@/services/chargers';
import { toast } from 'sonner';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useAddChargeGroupMutation } from '@/redux/features/charger-network/charger-network.api';
import {
	setChargeGroups,
	startLoading,
} from '@/redux/features/charger-network/charger-network.slice';
import { AddressAutoComplete } from '@/components/address-auto-complete';

const FormSchema = z.object({
	name: z
		.string({ required_error: 'Group name cannot be empty' })
		.min(3, { message: 'Group name is too short' })
		.max(50, { message: 'Group name is too long' }),
	description: z
		.string()
		.min(3, { message: 'Description is too short' })
		.max(50, { message: 'Description is too long' })
		.optional(),
	latitude: z.number(),
	longitude: z.number(),
	address: z
		.string()
		.max(250, { message: 'Address length is too long.' })
		.min(2, { message: 'Please enter address.' }),
});

export function ChargerTableViewOptions() {
	const { orgId } = useAppSelector((state) => state.auth);
	const dispatch = useAppDispatch();

	const [addchargeGroupMutation, { isLoading }] = useAddChargeGroupMutation();

	const {
		handleGetCountries,
		setSelectedCountry,
		selectedCountry,
		countries: { data: countriesData, loading: countriesLoading },

		handleGetStates,
		states: { data: statesData, loading: statesLoading },
	} = useGlobal();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
	});

	const handleSubmit = async (values: z.infer<typeof FormSchema>) => {
		const result: any = { ...values, organization: orgId };

		const response = await addchargeGroupMutation(result)
			.unwrap()
			.then((response) => {
				toast.success(response.message);
				setIsOpen(false);
			})
			.catch((error) => {
				toast.error(error.data.error_message);
			});
	};

	useEffect(() => {
		handleGetCountries();
	}, []);

	useEffect(() => {
		handleGetStates();
	}, [selectedCountry]);

	return (
		<Sheet modal open={isOpen}>
			<SheetTrigger asChild>
				<Button
					variant="outline"
					onClick={() => {
						form.reset();
						setIsOpen(!isOpen);
					}}
				>
					<Cross className="mr-2 h-4 w-4" />
					Add Charger Groups
				</Button>
			</SheetTrigger>
			<SheetContent className="overflow-scroll max-h[50vh]">
				<Form {...form}>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<div className="flex items-start w-full justify-between">
							<SheetHeader>
								<SheetTitle> Add Charger groups</SheetTitle>
								<SheetDescription>
									Add charger groups for your charge points here.
								</SheetDescription>
							</SheetHeader>
							<Button
								type="button"
								onClick={() => {
									form.reset();
									setIsOpen(false);
								}}
								variant={'secondary'}
								className=" p-2  h-auto"
							>
								<X className="h-4 w-4" />
							</Button>
						</div>
						<div className="grid gap-4 py-4">
							<FormField
								control={form.control}
								name="name"
								render={({ field }) => (
									<>
										<FormItem className="grid grid-cols-4 items-center gap-4">
											<FormLabel className="text-right whitespace-nowrap">
												Name
											</FormLabel>
											<FormControl>
												<Input
													type="text"
													placeholder="Name"
													className="col-span-3"
													{...field}
												/>
											</FormControl>
										</FormItem>{' '}
										<FormMessage className=" text-[10px] text-right" />
									</>
								)}
							/>

							<FormField
								control={form.control}
								name="address"
								render={({ field }) => (
									<FormItem className="grid grid-cols-4 items-center gap-4">
										<FormLabel className="text-right whitespace-nowrap">
											Address
										</FormLabel>
										<FormControl>
											<div className="col-span-3">
												<AddressAutoComplete
													onSelectAddress={(address, latitude, longitude) => {
														form.setValue('address', address);
														form.setValue('latitude', latitude as number);
														form.setValue('longitude', longitude as number);
													}}
													defaultValue=""
												/>
											</div>
										</FormControl>
										<FormMessage className=" text-[10px]" />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="description"
								render={({ field }) => (
									<>
										<FormItem className="grid grid-cols-4 items-start gap-4">
											<FormLabel className="text-right whitespace-nowrap pt-2">
												Description
											</FormLabel>
											<FormControl>
												<Textarea
													placeholder="Description"
													className="col-span-3 "
													{...field}
												/>
											</FormControl>
										</FormItem>{' '}
										<FormMessage className=" text-[10px] text-right" />
									</>
								)}
							/>
						</div>
						<SheetFooter>
							<Button disabled={isLoading} variant="secondary" type="submit">
								Create
							</Button>
						</SheetFooter>
					</form>
				</Form>
			</SheetContent>
		</Sheet>
	);
}
