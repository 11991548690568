import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type orgType = {
  id: string;
  org_id: string;
  org_name: string;
  org_type: "Multi-user" | "Private";
  org_profile_picture: string | undefined;
  org_latitude: number;
  org_longitude: number;
  org_phone_number: string;
};

interface IState {
  loading: boolean;
  error: string | null;
  data: any;
  organisations: orgType[] | null;
  currentOrg: orgType | null;
}

const initialState: IState = {
  data: null,
  loading: false,
  error: null,
  organisations: null,
  currentOrg: null,
};

const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setOrganisations: (state, action) => {
      state.organisations = action.payload;
      state.loading = false;
    },
    setCurrentOrg: (state, action) => {
      state.currentOrg = action.payload;
      state.loading = false;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { startLoading, setError, setOrganisations, setCurrentOrg } =
  orgSlice.actions;

export default orgSlice.reducer;
