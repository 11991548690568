import { cn } from '@/lib/utils/cn';
import React from 'react';
import {
	BsArrowUpRight,
	// BsArrowUp,
	BsArrowDownRight,
	// BsArrowDown,
} from 'react-icons/bs';
import styles from './DeltaBadge.module.css';
import { DeltaType } from '@/common/constants/enums';

interface DeltaProps {
	type: DeltaType | 'default';
	value: number;
}

const DeltaBadge: React.FC<DeltaProps> = ({ type, value }) => {
	let Icon;
	let iconColor;
	let bgClass;
	let textClass;

	switch (type) {
		case DeltaType.ModerateIncrease:
			Icon = BsArrowUpRight;
			iconColor = '#FED127';
			bgClass = styles['bg-icon-color-1'];
			textClass = styles['text-icon-color-1'];
			break;
		case DeltaType.Increase:
			Icon = BsArrowUpRight;
			iconColor = '#6BEBA4';
			bgClass = styles['bg-icon-color-2'];
			textClass = styles['text-icon-color-2'];
			break;
		case DeltaType.ModerateDecrease:
			Icon = BsArrowDownRight;
			iconColor = '#ff6e2b';
			bgClass = styles['bg-icon-color-3'];
			textClass = styles['text-icon-color-3'];
			break;
		case DeltaType.Decrease:
			Icon = BsArrowDownRight;
			iconColor = '#F52525';
			bgClass = styles['bg-icon-color-4'];
			textClass = styles['text-icon-color-4'];
			break;
		default:
			Icon = BsArrowDownRight;
			iconColor = '#F52525';
			bgClass = styles['bg-icon-color-4'];
			textClass = styles['text-icon-color-4'];
	}

	return (
		<div
			className={cn(
				bgClass,
				'bg-opacity-20 py-1 px-3 flex items-center gap-1 text-base',
				textClass,
				'w-max rounded-full'
			)}
		>
			{Icon && <Icon size={13} color={iconColor} />}
			<span className="font-thin">{value}%</span>
		</div>
	);
};

export default DeltaBadge;
