import { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { ExportDate } from './export-date';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { AiOutlineExport } from 'react-icons/ai';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	SelectGroup,
} from '@/components/ui/select';
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Checkbox } from '@/components/ui/checkbox';
import { toast } from '@/components/ui/use-toast';
import { ChargerGroupType } from '@/types/charger-network';
import { getChargerGroups } from '@/services/chargers';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { DateRangePicker } from '@/components/ui/date-range';
import { extractDayDates, formatDateToYYYYMMDD } from '@/lib/helpers/dates';
import { DateRange } from 'react-day-picker';
import { values } from 'lodash';
import { subDays } from 'date-fns';
import {
	exportTransactionRecordsCSV,
	exportTransactionRecordsPDF,
} from '@/services/revenue';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useGetChargeGroupsQuery } from '@/redux/features/charger-network/charger-network.api';
import {
	setChargeGroups,
	startLoading,
} from '@/redux/features/charger-network/charger-network.slice';

interface ExportTransactionsProps<TData> {
	table: Table<TData>;
}

const FormSchema = z.object({
	charger_groups: z.string(),
	pdf_export: z.boolean().default(true).optional(),
	csv_export: z.boolean().default(false).optional(),
});

const items = [
	{
		id: 'csv',
		label: 'CSV',
	},
	{
		id: 'pdf',
		label: 'PDF',
	},
] as const;
export function ExportTransactions<TData>({
	table,
}: ExportTransactionsProps<TData>) {
	const { orgId } = useAppSelector((state) => state.auth);
	const dispatch = useAppDispatch();

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
	});
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [selectedExportFormat, setSelectedExportFormat] = useState('');
	const [isDownloading, setIsDownloading] = useState(false);

	const {
		data: { chargeGroups: groups },
	} = useAppSelector((state) => state.chargerNetwork);
	const {
		data: groupsData,
		isFetching,
		isError,
		isLoading,
	} = useGetChargeGroupsQuery(
		{ orgId },
		{
			skip: !!groups,
		}
	);

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [selectedInterval, setSelectedInterval] = useState<string>('custom');
	const [date, setDate] = useState<DateRange | any>();

	useEffect(() => {
		hanleDateChange(date);
	}, [date]);

	const hanleDateChange = (dateResult?: DateRange | any, value?: any) => {
		if (dateResult) {
			const initialFromDate = new Date(dateResult.from);
			const fromDate = formatDateToYYYYMMDD(initialFromDate);
			const initialToDate = new Date(dateResult.to);
			const toDate = formatDateToYYYYMMDD(initialToDate);

			return { fromDate, toDate };
		}
	};

	const handleExport = async (values: {
		charger_groups: string;
		csv_export?: boolean;
		pdf_export?: boolean;
	}) => {
		const dateData = hanleDateChange(date);
		if (values.csv_export && dateData) {
			setIsDownloading(true);
			try {
				const response: any = await exportTransactionRecordsCSV({
					charger_groups_id: values.charger_groups,
					fromDate: dateData.fromDate,
					toDate: dateData.toDate,
				});

				const blob = new Blob([response], { type: 'text/csv' });

				// Create a URL for the Blob
				const url = window.URL.createObjectURL(blob);

				// Create a link element and click it to trigger the download
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'transactions.csv');
				document.body.appendChild(link);
				link.click();

				// Cleanup
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			} catch (error: any) {
				throw new Error(error);
			} finally {
				setIsDownloading(false);
			}
		}

		if (values.pdf_export && dateData) {
			setIsDownloading(true);
			try {
				const response: any = await exportTransactionRecordsPDF({
					charger_groups_id: values.charger_groups,
					fromDate: dateData.fromDate,
					toDate: dateData.toDate,
				});

				const blob = new Blob([response], { type: 'application/pdf' });

				// Create a URL for the Blob
				const url = window.URL.createObjectURL(blob);

				// Create a link element and click it to trigger the download
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'transactions.pdf');
				document.body.appendChild(link);
				link.click();

				// Cleanup
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			} catch (error: any) {
				throw new Error(error);
			} finally {
				setIsDownloading(false);
			}
		}
	};

	const handleDateFilter = (interval: string) => {
		const today = new Date();
		setSelectedInterval(interval);
		switch (interval) {
			case 'lastMonth':
				setDate({
					from: subDays(today, 30),
					to: today,
				});
				break;
			case 'last2Months':
				setDate({
					from: subDays(today, 60),
					to: today,
				});
				break;
			case 'custom':
				// Set custom logic for the 'Custom' interval
				// For example, you can open a date picker modal
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (isFetching) {
			dispatch(startLoading());
		} else if (groupsData) {
			dispatch(setChargeGroups(groupsData));
		}
	}, [dispatch, isFetching, groupsData]);

	return (
		<div className="flex justify-evenly gap-2">
			<Button variant="secondary" onClick={() => setIsModalOpen(true)}>
				<AiOutlineExport className="h-4 w-4 mr-2" />
				Export
			</Button>
			<Dialog
				modal
				open={isModalOpen}
				onOpenChange={() => setIsModalOpen(true)}
			>
				<DialogContent className="border-0 shadow-2xl bg-background text-white max-w-md max-h-xl">
					<DialogHeader className="border-b border-b-gray-500 p-4">
						<DialogTitle className="flex justify-between items-center">
							<h2>Export Transactions.</h2>
							<Button
								onClick={() => setIsModalOpen(false)}
								variant="secondary"
								type="button"
							>
								<X size={16} color="black" />
							</Button>
						</DialogTitle>
					</DialogHeader>
					<Form {...form}>
						<form className="p-4" onSubmit={form.handleSubmit(handleExport)}>
							<div className="space-y-3">
								<div>Time frame</div>
								<div className="flex gap-4 justify-between">
									<Button
										type="button"
										variant={
											selectedInterval === 'lastMonth' ? 'secondary' : 'default'
										}
										onClick={() => handleDateFilter('lastMonth')}
									>
										Last month
									</Button>
									<Button
										type="button"
										variant={
											selectedInterval === 'last2Months'
												? 'secondary'
												: 'default'
										}
										onClick={() => handleDateFilter('last2Months')}
									>
										Last 2 months
									</Button>
									<Button
										type="button"
										variant={
											selectedInterval === 'custom' ? 'secondary' : 'default'
										}
										onClick={() => handleDateFilter('custom')}
									>
										Custom
									</Button>
								</div>
								{selectedInterval === 'custom' && (
									<ExportDate onDateChange={setDate} />
								)}

								<div className="space-y-3">
									<div>Charger groups</div>

									<FormField
										control={form.control}
										name="charger_groups"
										render={({ field }) => (
											<FormItem className="w-full">
												<div className=" flex items-center gap-3">
													<Select
														onValueChange={(value) => {
															field.onChange(value);
														}}
														defaultValue={'All'}
													>
														<FormControl>
															<SelectTrigger className="w-full">
																<SelectValue />
															</SelectTrigger>
														</FormControl>
														<SelectContent>
															<SelectGroup>
																{Object.keys(groups).length !== 0 &&
																	groups.results.map(
																		(group: ChargerGroupType) => (
																			<SelectItem
																				key={group.id}
																				value={group.id}
																			>
																				{group.name}
																			</SelectItem>
																		)
																	)}
															</SelectGroup>
														</SelectContent>
													</Select>
												</div>
												<FormMessage className=" text-[10px]" />
											</FormItem>
										)}
									/>
								</div>
								<div className="space-y-2">
									<div>
										<h2 className=" ">Export Format</h2>
										<p className=" text-gray-500 text-xs">
											Select the format you wish to export your transactions.
										</p>
									</div>
									<FormField
										control={form.control}
										name="pdf_export"
										render={({ field }) => {
											return (
												<FormItem className="flex flex-row items-start space-x-3 space-y-0">
													<FormControl>
														<Checkbox
															checked={field.value}
															onCheckedChange={field.onChange}
														/>
													</FormControl>
													<FormLabel className="font-normal">PDF</FormLabel>
												</FormItem>
											);
										}}
									/>
									<FormField
										control={form.control}
										name="csv_export"
										render={({ field }) => {
											return (
												<FormItem className="flex flex-row items-start space-x-3 space-y-0">
													<FormControl>
														<Checkbox
															checked={field.value}
															onCheckedChange={field.onChange}
														/>
													</FormControl>
													<FormLabel className="font-normal">CSV</FormLabel>
												</FormItem>
											);
										}}
									/>{' '}
									{/* <FormLabel className="text-base">Export Format</FormLabel>
									<FormDescription>
										Select the format you wish to export your transactions.
									</FormDescription> */}
								</div>
							</div>

							<div className="flex gap-3 pt-8 justify-end">
								<Button
									type="button"
									variant="outline"
									onClick={() => setIsModalOpen(false)}
								>
									Cancel
								</Button>
								<Button>
									{isDownloading ? 'Exporting...' : 'Export Record'}
								</Button>
							</div>
						</form>
					</Form>
				</DialogContent>
			</Dialog>
		</div>
	);
}
