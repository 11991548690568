import { Checkbox } from '@/components/ui/checkbox';
import { OthersMemberCardAction } from './others-member-card-action';
import { MeMemberCardAction } from './me-member-card-action';
import { ManageRole } from './manage-role';
import { useAppSelector } from '@/redux/hooks';

export const MembersCard = (props: {
  userId: string;
  memberId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: any;
}) => {
  const user = useAppSelector((state) => state.auth.user);
  return (
    <div className="p-4 border-b-[1px] border-gray-600 flex justify-between items-center">
      <div className="flex items-center gap-3">
        <Checkbox className="border-white" />
        <div className="flex items-center gap-1">
          <img src="/images/user-image.png" />
          <div>
            <h3 className="text-sm">{`${props.firstName} ${props.lastName}`}</h3>
            <p className="text-xs text-gray-500">{props.email}</p>
          </div>
        </div>
      </div>
      <div className="flex gap-4 items-center">
        <span className="text-sm">
          {props.role ? <p>{props.role.org_role_name}</p> : <p>Member</p>}
        </span>
        <ManageRole>Manage Role</ManageRole>

        {user?.id === props.userId ? (
          <MeMemberCardAction />
        ) : (
          <OthersMemberCardAction
            memberId={props.memberId}
            userId={props.userId}
          />
        )}
      </div>
    </div>
  );
};
