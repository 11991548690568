import { Button } from '@/components/ui/button';

type Props = {};

const CheckEmail = (props: Props) => {
	return (
		<div className="flex items-center justify-center h-full">
			<div className="  rounded-3xl backdrop-blur-xl  bg-gradient-login p-6 h-3/4 w-1/2 ">
				<div
					style={{
						backgroundImage: 'url(/animations/check-email.svg)',
						backgroundSize: 'contain',
						backgroundRepeat: 'no-repeat',
					}}
					className="flex relative items-center justify-center h-full "
				>
					<div></div>
					<div className=" backdrop-blur-xl flex- flex flex-col gap-4 bg-black bg-opacity-20 p-4 rounded-xl items-center  justify-center">
						<img src="/icons/check-email-2.png" className="h-32 w-32" />

						<h1 className=" text-6xl font-bold">Check your email</h1>
						<p className="text-slate-200 font-light text-xs">
							We have sent a password recovery instruction to you email.
							password.
						</p>
						{/* <div className="flex flex-col items-center gap-4 w-full">
							<Button className="bg-[#4e4e4e] rounded-[16px] w-full md:w-[60%] ">
								Open Email
							</Button>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckEmail;
