import { Account } from '../components/account/account';
import { Activity } from '../components/activity/activity';
import { Billing } from '../components/billing/billing';
import { GeneralSettings } from '../components/general-settings/general-settings';
import { Members } from '../components/members/members';
import { Notifications } from '../components/notification/notification';
import { Bell, Group, PersonStanding, User } from 'lucide-react';
import { MdGroupWork, MdOutlinePayment } from 'react-icons/md';
import { Settings } from '../types';

const AccountSettings = () => <Account />;
const NotificationSettings = () => <Notifications />;
const ActivitySettings = () => <Activity />;
const GeneralOrganizationSettings = () => <GeneralSettings />;
const BillingOrganizationSettings = () => <Billing />;
const MembersOrganizationSettings = () => <Members />;

export const personalSettings: Settings[] = [
	{
		name: 'Account',
		type: 'personal',
		description: 'Update your account settings.',
		icon: User,
		component: AccountSettings,
	},
	// {
	// 	name: 'Notifications',
	// 	type: 'personal',
	// 	description: 'Update notification settings.',
	// 	icon: Bell,
	// 	component: NotificationSettings,
	// },
	// {
	// 	name: 'Activity',
	// 	type: 'personal',
	// 	description: 'Update notification settings.',
	// 	icon: PersonStanding,
	// 	component: ActivitySettings,
	// },
];

export const organizationSettings: Settings[] = [
	{
		name: 'General',
		type: 'organisation',
		description: 'Manage general organization details.',
		icon: Group,
		component: GeneralOrganizationSettings,
	},
	{
		name: 'Billing',
		type: 'organisation',
		description: 'Manage billing details.',
		icon: MdOutlinePayment,
		component: BillingOrganizationSettings,
	},
	{
		name: 'Members',
		type: 'organisation',
		description: 'Manage organization members.',
		icon: MdGroupWork,
		component: MembersOrganizationSettings,
	},
];
