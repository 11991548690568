import { organisations } from './data/organisations';
import { OrganisationsCard } from './components/organisations-card';
import { getGreeting } from '@/lib/helpers/greeting';

const Organisations = () => {
	const greeting = getGreeting();

	return (
		<div className="container md:my-6">
			<div>
				<h1 className="text-white text-4xl font-semibold">
					👋 {greeting}, Kehlani
				</h1>
			</div>
			<div className="my-9">
				<p className="text-2xl ">
					Your <span className="text-[#FD9A27] ">Organisations</span>
				</p>

				<div className="grid xlg:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-2 gap-4 my-3 ">
					{organisations.map((organisation) => (
						<OrganisationsCard key={organisation.id} data={organisation} />
					))}
				</div>
			</div>
		</div>
	);
};

export default Organisations;
