interface RevenueChange {
  deltaType: string;
  percentageChange: number;
}

export const calculateRevenueChange = (
  today: number,
  yesterday: number
): RevenueChange => {
  if (yesterday === 0 && today === 0) {
    return { deltaType: "Decrease", percentageChange: 0 };
  }

  const percentageChange =
    yesterday == 0
      ? ((today - yesterday) / today) * 100
      : today === 0
        ? ((yesterday - today) / yesterday) * 100
        : ((today - yesterday) / yesterday) * 100;

  const percentageChangeNumber = parseFloat(percentageChange.toFixed(0));

  if (percentageChangeNumber > 75) {
    return { deltaType: "Increase", percentageChange: percentageChangeNumber };
  } else if (percentageChangeNumber > 50) {
    return {
      deltaType: "ModerateIncrease",
      percentageChange: percentageChangeNumber,
    };
  } else if (percentageChangeNumber < 0) {
    return {
      deltaType: "ModerateDecrease",
      percentageChange: percentageChangeNumber,
    };
  } else if (percentageChangeNumber < -50) {
    return { deltaType: "Decrease", percentageChange: percentageChangeNumber };
  } else {
    return { deltaType: "Decrease", percentageChange: 0 };
  }
};

export const calculatePercentageChange = (percentage: number) => {
  if (percentage === 0) {
    return { deltaType: "Decrease", percentageChange: 0 };
  }
  const percentageChange = parseFloat((percentage * 100).toFixed(0));


  if (percentageChange > 75) {
    return { deltaType: "Increase", percentageChange: percentageChange };
  } else if (percentageChange > 50) {
    return {
      deltaType: "ModerateIncrease",
      percentageChange: percentageChange,
    };
  } else if (percentageChange < 0) {
    return {
      deltaType: "ModerateDecrease",
      percentageChange: percentageChange,
    };
  } else if (percentageChange < -50) {
    return { deltaType: "Decrease", percentageChange: percentageChange };
  } else {
    return { deltaType: "Decrease", percentageChange: 0 };
  }
};
