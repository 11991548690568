import Sparkline from './ui/sparkline';
import DeltaBadge from './ui/delta-badge';
import { DeltaType } from '@/common/constants/enums';
import { cn } from '@/lib/utils/cn';
import { Skeleton } from '@/components/ui/skeleton';

interface OveviewCardProps {
	title: string;
	metric: string | number;
	deltaValue: number;
	deltaType: DeltaType | 'default';
	dayTime?: string;
	data: number[];
	onClick?: () => void;
	className?: string;
}

export const OverviewCard: React.FC<OveviewCardProps> = ({
	title,
	data,
	metric,
	deltaValue,
	deltaType,
	dayTime,
	onClick,
	className,
}) => {
	const width = 200;
	const height = 50;
	const smoothness = 0.1;
	const lineThickness = 4;
	return (
		<div
			onClick={onClick}
			className={cn(
				'backdrop-blur-xl  bg-gradient-linear relative  2xl:w-max gap-7 w-full overflow-hidden rounded-xl ',
				className
			)}
		>
			<div className="p-6 flex xl:flex-row flex-col items-center  h-full">
				<div className="flex flex-col gap-3 justify-between py-8 h-full">
					<div>
						<p className="text-[#D1D1D1] font-extralight ">{title}</p>
					</div>
					<div className={cn('relative xl:invisible xl:h-0 visible')}>
						<div className="w-32 ">
							<Sparkline
								data={data}
								width={width}
								height={height}
								smoothness={smoothness}
								lineThickness={lineThickness}
							/>
						</div>
					</div>
					<div className="font-bold text-white text-2xl">{metric}</div>
					<div className="flex items-center gap-2">
						<DeltaBadge type={deltaType} value={deltaValue} />
						<span className="text-[##D1D1D1] whitespace-nowrap capitalize">
							from {dayTime}
						</span>
					</div>
				</div>
				<div className="relative xl:visible invisible xl:h-auto h-0">
					<div className="w-32 ">
						<Sparkline
							data={data}
							width={width}
							height={height}
							smoothness={smoothness}
							lineThickness={lineThickness}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
