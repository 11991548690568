import {
	MdLocationOn,
	MdPower,
	MdPowerOff,
	MdError,
	MdBarChart,
} from 'react-icons/md';
import { DeviceCharts } from '../charger-details/connector-table/device-stats';
import { GroupType } from '../../types';
import { useGlobal } from '@/lib/hooks/use-global';
import { useEffect, useState } from 'react';
import { Country, State } from 'country-state-city';

interface GroupCardProps {
	groupData: GroupType;
}

export const DetailsCard: React.FC<GroupCardProps> = ({ groupData }) => {
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');

	useEffect(() => {
		(async () => {
			const countryResponse = await Country.getCountryByCode(groupData.country);
			const stateResponse = await State.getStateByCode(groupData.state);

			setCountry(countryResponse?.name ?? 'Unknown Country');
			setState(stateResponse?.name ?? 'Unknown State');
		})();
	}, []);
	// const dataStatsSeries = [
	// 	{
	// 		name: 'Energy Utilization',
	// 		data: groupData.utilization,
	// 	},
	// 	{
	// 		name: 'uptime',
	// 		data: groupData.uptime,
	// 	},
	// 	{
	// 		name: 'Charging session',
	// 		data: groupData.sessions,
	// 	},
	// ];

	return (
		<div className="mx-auto bg-gray-800 text-white rounded-md  shadow-md p-4 flex gap-4 items-center flex-1 ">
			<div className="">
				<div className="mb-2">
					<p className="text-gray-400 font-bold">
						Group Name{' '}
						<span className=" font-normal text-white text-sm">
							{groupData.name}
						</span>{' '}
					</p>
				</div>

				<div className="mb-2">
					<p className="text-gray-400 font-bold">
						Address -{' '}
						<span className=" font-normal text-white text-sm">
							{groupData.address}
						</span>{' '}
					</p>
				</div>

				<div className="mb-2">
					<p className="text-gray-400 font-bold">
						City -{' '}
						<span className=" font-normal text-white text-sm">
							{groupData.city}
						</span>
					</p>
				</div>

				<div className="mb-2">
					<p className="text-gray-400 font-bold">
						Zip Code -{' '}
						<span className=" font-normal text-white text-sm">
							{groupData.zip_code}
						</span>
					</p>
				</div>
				<div className="mb-2">
					<p className="text-gray-400 font-bold">
						State -{' '}
						<span className=" font-normal text-white text-sm">{state}</span>
					</p>
				</div>

				<div className="mb-2">
					<p className="text-gray-400 font-bold">
						Country -{' '}
						<span className=" font-normal text-white text-sm">{country}</span>
					</p>
				</div>
				{/* <div className="mb-2">
					<p className="text-gray-400 font-bold">Charger Status</p>
					<p className="text-sm">
						<MdPower className="inline-block text-green-400 mr-1" />
						Online: {groupData.chargerStatus.online} |
						<MdPowerOff className="inline-block text-red-400 mx-1" />
						Offline: {groupData.chargerStatus.offline} |
						<MdError className="inline-block text-yellow-400 ml-1" />
						Faulty: {groupData.chargerStatus.faulty}
					</p>
				</div>

				<div className="mb-2">
					<p className="text-gray-400 font-bold">Applied Tariff Plan</p>
					<p className="text-lg">{groupData.tariffPlan}</p>
				</div> */}
			</div>

			{/* <div className="mb-2">
				<div className="flex">
					<DeviceCharts
						series={dataStatsSeries}
						title="Graphs Uptime and session details"
					/>
				</div>
			</div> */}
		</div>
	);
};
