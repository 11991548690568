import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Delete, Edit, X } from 'lucide-react';
import { MembersDetailsType } from '../../types';
import { DeletePrompt } from '@/components/delete-prompt';
import {
	useDeleteInvitationMutation,
	useResendInvitationMutation,
} from '@/redux/features/org/org.api';
import { toast } from 'sonner';
import { Dispatch, SetStateAction } from 'react';

type Prop = {
	data: MembersDetailsType;
};
export function PendingMemberCardAction({ data }: Prop) {
	const [deleteInvitation, { isLoading: deleteInvitationLoading }] =
		useDeleteInvitationMutation();
	const [resendInvitation, { isLoading: resendInvitationLoading }] =
		useResendInvitationMutation();

	const onDelete = async (setModal: Dispatch<SetStateAction<boolean>>) => {
		try {
			await deleteInvitation(data.id).unwrap();
			toast.success('Invitation deleted successfully');
			setModal(false);
		} catch (error) {
			console.log('error', error);
		}
	};

	const onResend = async () => {
		try {
			await resendInvitation({
				id: data.id,
				frontend_url: window.location.origin + '/acceptInvitation?referrer=',
			}).unwrap();
			toast.success('Invitation resent successfully');
		} catch (error) {
			console.log('error', error);
			toast.error('Failed to resend invitation');
		}
	};
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="ghost"
					className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
				>
					<DotsHorizontalIcon />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[200px]">
				<Button
					disabled={resendInvitationLoading}
					onClick={onResend}
					className="flex gap-2 justify-between hover:text-white text-primary/90 w-full bg-transparent"
				>
					Resend invitation
				</Button>

				<DeletePrompt
					buttonDisabled={deleteInvitationLoading}
					id={data.id}
					onDelete={onDelete}
					title="Are you sure you want to delete this invitation?"
					warningText="This action cannot be undone."
					buttonText="Delete"
				>
					<Button className="flex gap-2 justify-between text-red-500 w-full bg-transparent">
						Delete invitation
					</Button>
				</DeletePrompt>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
