import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { formatDate } from '@/lib/helpers/dates';
import {
	BsDownload,
	BsFillClockFill,
	BsLightning,
	BsPlug,
	BsPerson,
	BsGeoAlt,
	BsCardChecklist,
	BsGraphUp,
} from 'react-icons/bs';
import { TransactionsType } from '../../../types';

interface Props {
	data: {
		charger_id: string;
		connector_id: number;
		duration: string;
		electricity_usage: number;
		initiated_by: string;
		session_id: string;
		transaction_status: string;
		transaction_timeline: string;
	};
}

export const SessionDetails = ({ data }: Props) => {
	return (
		<div className="rounded-lg shadow-lg bg-black/50 p-6 text-white space-y-4 transform transition duration-300 ease-in-out h-full">
			<div className="flex justify-between items-center">
				<h2 className="font-bold text-xl flex items-center">
					<BsFillClockFill className="mr-4 text-yellow-500" />
					Charging Session Details
				</h2>
				{/* <Button className="bg-blue-500 hover:bg-blue-600">
					<BsDownload className="mr-2" />
					Download Session Report
				</Button> */}
			</div>
			<Separator className="bg-gray-500 h-[1px]" />
			<div className="flex">
				<ul className="space-y-3 flex-1">
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-bold text-sm">Session ID:</span>{' '}
							<span className="uppercase text-xs text-zinc-400 ">
								{data.session_id}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPerson className="mr-4 text-green-500" />
						<div>
							<span className="font-semibold text-sm">Initiated by:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.initiated_by}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-semibold text-sm">Charger ID:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.charger_id}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-semibold text-sm">Connector Number:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.connector_id}
							</span>
						</div>
					</li>
				</ul>
				<ul className="flex-1 space-y-3">
					<li className="flex items-start">
						<BsLightning className="mr-4 text-yellow-500" />
						<div>
							<span className="font-semibold text-sm">Electricity Usage:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.electricity_usage} kW/h
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsCardChecklist className="mr-4 text-purple-500" />
						<div>
							<span className="font-semibold text-sm">Transaction Status:</span>{' '}
							<span
								className={`py-[1px] px-3 rounded-full text-xs  capitalize ${
									data.transaction_status === 'failed'
										? 'bg-red-500/30 text-red-500/80'
										: data.transaction_status === 'completed'
											? 'bg-green-500/30 text-green-500/80'
											: 'bg-yellow-500/30 text-yellow-500/80'
								}`}
							>
								{data.transaction_status}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsGeoAlt className="mr-4 text-indigo-500" />
						<div>
							<span className="font-semibold text-sm">
								Transaction Timeline:
							</span>{' '}
							<span className="capitalize text-xs text-zinc-400">
								{data.transaction_timeline}
							</span>{' '}
						</div>
					</li>
					<li className="flex items-start">
						<BsGraphUp className="mr-4 text-pink-500" />
						<div>
							<span className="font-semibold text-sm">Duration:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.duration}
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

// Charger Details Section:
//  Include Charger ID,
//  Group Name, Charger Name,
//  Protocol, Vendor/Model,
//  Firmware Version, and
//  Address and button (Show additional details - redirects to charger page)
