import { DEFAULT_CENTER_LAT, DEFAULT_CENTER_LNG } from '../config';
import { ChargerLocationType } from '../types';
import { IChargerPin } from '../types/charger';

// Calculate the center based on charger locations
export const calculateCenter = (
	chargers: IChargerPin[]
): { lat: number; lng: number } => {
	if (chargers.length === 0) {
		return { lat: DEFAULT_CENTER_LAT, lng: DEFAULT_CENTER_LNG }; // Default center if no chargers
	}

	const totalLat = chargers.reduce((sum, charger) => sum + charger.lat, 0);
	const totalLng = chargers.reduce((sum, charger) => sum + charger.lng, 0);

	const averageLat = totalLat / chargers.length;
	const averageLng = totalLng / chargers.length;

	return { lat: averageLat, lng: averageLng };
};

// Calculate the zoom level to fit all charger locations
export const calculateZoomLevel = (chargers: IChargerPin[]): number => {
	if (chargers.length === 0) {
		return 8; // Default zoom level
	}

	const latitudes = chargers.map((charger) => charger.lat);
	const longitudes = chargers.map((charger) => charger.lng);

	const maxLat = Math.max(...latitudes);
	const minLat = Math.min(...latitudes);
	const maxLng = Math.max(...longitudes);
	const minLng = Math.min(...longitudes);

	const latSpan = maxLat - minLat;
	const lngSpan = maxLng - minLng;

	const maxSpan = Math.max(latSpan, lngSpan);
	const zoomLevel = 9 - Math.log2(maxSpan);

	return zoomLevel;
};
