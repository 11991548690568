import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ArrowUpRight, Link, PlusCircle } from 'lucide-react';
import { useSendInvitationsMutation } from '@/redux/features/org/org.api';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { CrossCircledIcon } from '@radix-ui/react-icons';
import { TeamMembers } from './team-members';
import { InviteLink } from './modals/invite-link.modal';
import { InviteMemberDetails } from './invite-member-details';
import { toast } from 'sonner';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';
import { MembersDetailsType } from '../../types';
import { useAppSelector } from '@/redux/hooks';

export const Members = () => {
	const [memberDetails, setMemberDetails] = useState([{}]);
	const [pendingInvitations, setPendingInvitations] = useState<
		MembersDetailsType[]
	>([]);

	const { orgId } = useAppSelector((state) => state.auth);

	const [
		sendInvitationsMutation,
		{
			isLoading: sendInviteLoading,
			error: sendInviteError,
			isSuccess: sendInviteSuccess,
		},
	] = useSendInvitationsMutation();

	const MembersSchema = z.object({
		user_email: z.string().email({ message: 'Invalid email address.' }),
		role: z.string().min(2, { message: 'Please select a role.' }),
	});

	const membersDetailsForm = useForm<z.infer<typeof MembersSchema>>({
		resolver: zodResolver(MembersSchema),
	});

	const handleAddMore = () => {
		setMemberDetails((prevDetails) => [...prevDetails, {}]);
	};

	const handleRemove = (indexToRemove: number) => {
		setMemberDetails((prevDetails) =>
			prevDetails.filter((_, index) => index !== indexToRemove)
		);
	};

	const handleUpdate = (
		index: number,
		data: { user_email: string; role: string }
	) => {
		setMemberDetails((prevDetails) =>
			prevDetails.map((item, i) => (i === index ? data : item))
		);
	};

	const handleSubmit = async () => {
		try {
			const response = await sendInvitationsMutation({
				frontend_url: window.location.origin + '/acceptInvitation?referrer=',
				orgId: orgId,
				invites: memberDetails,
			}).unwrap();

			response.messages.map((item: any) => {
				toast.info(item.message);
			});
			setMemberDetails([{}]);
		} catch (error) {
			toast.error('Error inviting members');
			console.error('catch error: ', error);
		}
	};

	return (
		<div className="text-white">
			<div>
				<h1 className="text-lg my-1 font-semibold">Members</h1>
				<p className="text-xs text-gray-400 font-light">
					Manage and invite your organization Members.
				</p>
			</div>
			<Form {...membersDetailsForm}>
				<form
					onSubmit={membersDetailsForm.handleSubmit(handleSubmit)}
					className="border-[.3px] border-gray-500 rounded-lg bg-primary my-8"
				>
					<div className="p-4">
						<div className="flex justify-between py-3 items-center">
							<p className="text-sm font-normal">
								Invite new members by email address
							</p>
							<InviteLink>
								<Button type="button" variant="outline" className="flex gap-2">
									<Link size={16} /> Invite Link
								</Button>
							</InviteLink>
						</div>
						<hr className="border-t-[1px] border-r-0 border-b-0 border-l-0 border-gray-500" />
						<div>
							{memberDetails.map((_, index) => (
								<InviteMemberDetails
									key={index}
									index={index}
									onRemove={handleRemove}
									onUpdate={handleUpdate}
									form={membersDetailsForm}
								/>
							))}
						</div>
						<div
							onClick={() => {
								if (memberDetails.length < 3) return;
								else
									toast('Sorry, you cannot invite more than 3 members at once');
							}}
						>
							<Button
								variant="outline"
								type="button"
								className="flex gap-2"
								onClick={handleAddMore}
								disabled={memberDetails.length > 2}
							>
								<PlusCircle />
								Add more
							</Button>
						</div>
					</div>
					<div className="flex justify-between border-t-[.3px] items-center border-gray-500 p-4">
						<span className="text-blue-400 flex items-center gap-1">
							Learn more <ArrowUpRight size={16} />{' '}
						</span>
						<Button disabled={sendInviteLoading} variant="secondary">
							{' '}
							Invite
						</Button>
					</div>
				</form>
			</Form>

			<TeamMembers />
		</div>
	);
};
