import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { DeletePrompt } from '@/components/delete-prompt';

type Prop = {};
export function MeMemberCardAction({}: Prop) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="ghost"
					className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
				>
					<DotsHorizontalIcon />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[200px]">
				<DeletePrompt
					id=""
					onDelete={() => {}}
					title="Are you absolutely sure you want to remove this invitation?"
					warningText="This action cannot be undone."
					buttonText="Continue"
				>
					<Button className="flex gap-2 justify-between text-red-500 w-full bg-transparent">
						Leave team
					</Button>
				</DeletePrompt>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
