import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { useAppSelector } from '@/redux/hooks';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { DeletePrompt } from '@/components/delete-prompt';
import { useRemoveUserFromOrgMutation } from '@/redux/features/org/org.api';
import { toast } from 'sonner';
import { Dispatch, SetStateAction } from 'react';

type Prop = {
  userId: string;
  memberId: string;
};
export function OthersMemberCardAction({ userId, memberId }: Prop) {
  const orgId = useAppSelector((state) => state.auth.orgId);
  const [removeUserFromOrgMutation, { isLoading }] =
    useRemoveUserFromOrgMutation();

  const onDelete = async (setModal: Dispatch<SetStateAction<boolean>>) => {
    try {
      await removeUserFromOrgMutation({
        orgId: orgId,
        userId: memberId,
      }).unwrap();
      toast.success("User removed from team");
      setModal(false);
    } catch (error) {
      toast.error(error.data.error_message);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <DeletePrompt
          buttonDisabled={isLoading}
          id=""
          onDelete={onDelete}
          title="Are you absolutely sure you want to remove this invitation?"
          warningText="This action cannot be undone."
          buttonText="Continue"
        >
          <Button className="flex gap-2 justify-between text-red-500 w-full bg-transparent">
            Remove from team
          </Button>
        </DeletePrompt>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
