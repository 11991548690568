import React from 'react';
import { Button } from '@/components/ui/button';
import { BsArrowClockwise } from 'react-icons/bs';
import { useSpring, animated } from 'react-spring';
import '@/styles/mobile-warnings.css';

const MobileWarning = () => {
	const fadeIn = useSpring({
		opacity: 1,
		from: { opacity: 0 },
		config: { duration: 800 },
	});

	const rotateArrow = useSpring({
		transform: 'rotate(360deg)',
		from: { transform: 'rotate(0deg)' },
		reset: true,
		reverse: true,
		onRest: () => rotateArrow.start(),
	});

	const refreshPage = () => {
		window.location.reload();
	};

	return (
		<animated.div style={fadeIn} className="space-y-12 main">
			<div className="text-black">
				<h1 className="text-3xl text-center font-bold my-6">Oops!</h1>
			</div>
			<div className="flex items-center justify-center">
				<img
					src="/images/--mobile-warning.png"
					className="w-[70%]"
					alt="Mobile Warning"
				/>
			</div>
			<p className="text-center font-semibold text-black">
				Looks like our app isn't available for mobile users, please switch to a
				desktop.
			</p>
			<Button
				className="bg-transparent border-b rounded-none"
				onClick={refreshPage}
			>
				<BsArrowClockwise style={{ ...rotateArrow }} className="mr-2" />
				Refresh page
			</Button>
		</animated.div>
	);
};

export default MobileWarning;
