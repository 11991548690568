import React, { useEffect, useState } from 'react';
import { redirect, useNavigate, useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { toast } from 'sonner';
import { useAppSelector } from '@/redux/hooks';
import { acceptOrgInvite } from '@/services/auth';

type Props = {};

const AcceptInvite = (props: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

	const inviteToken = searchParams.get('invite_token');
	const userEmail = searchParams.get('referrer');

	const [userData, setUserData] = useState({});
	const [inviteDetails, setInviteDetails] = useState(null);
	const [tokenInvalid, setTokenInvalid] = useState(false);
	const [tokenVerificationLoading, setTokenVerificationLoading] =
		useState(true);
	const navigate = useNavigate();

	const processUserInvite = async (orgName: string) => {
		try {
			const response = await axios.get(
				`https://core.grdflo.com/api/v1/core/users/profile/email/${userEmail}`
			);
			setUserData(response.data);

			if (!isAuthenticated) {
				navigate(
					`/login?redirect=acceptInvite&inviteToken=${inviteToken}&userEmail=${userEmail}&orgName=${orgName}`
				);
				return;
			} else {
				if (inviteToken) {
					try {
						await acceptOrgInvite({ invite_token: inviteToken });
						toast.success('You have successfully joined the organization');
						navigate('/');
					} catch (error) {
						console.error('error');
						toast.error(error as string);
					}
				}
			}
		} catch (error) {
			setUserData({ ...userData, error: error });
			console.error('Error fetching user data:', error);

			if (error.response.status === 404) {
				navigate(
					`/signup?inviteToken=${inviteToken}&userEmail=${userEmail}&orgName=${orgName}`
				);
			}
		}
	};

	const verifyInviteToken = async () => {
		setTokenVerificationLoading(true);
		try {
			const response = await axios.get(
				`https://core.grdflo.com/api/v1/core/organization/invites/verify/?user_email=${userEmail}&invite_token=${inviteToken}`
			);
			const orgName = response.data.data.organization.org_name;
			processUserInvite(orgName);
		} catch (error) {
			setTokenInvalid(true);
			console.error('Error fetching user data:', error);
		} finally {
			setTokenVerificationLoading(false);
		}
	};

	useEffect(() => {
		verifyInviteToken();
	}, []);

	// first verify that the invite is valid
	//  if invalid, then show ui that its invalid
	//  if valid then proceed with below steps

	// check if user has an account first of all

	// if user has an account then:
	//      if user is authenticated (logged in), then let the access the screen
	//      if user is not authenticated (logged in), then redirect them to the login screen,
	//      with some search params that directs the login to send them back here after they login

	// if no account:
	//      send them to the signup page, with something in the search params that indicates they came from here
	//      from signup -> verify email -> login -> back to here, make sure to pass something in query params so they can redirect here upon login

	return (
		<div>
			{tokenVerificationLoading ? (
				<div>Loading</div>
			) : (
				tokenInvalid && <div>Token is invalid</div>
			)}
		</div>
	);
};

export default AcceptInvite;
