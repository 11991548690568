export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_LOADING = 'LOGOUT_LOADING';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_LOADING = 'SIGNUP_LOADING';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_LOADING = 'GET_USER_PROFILE_LOADING';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_LOADING = 'VERIFY_OTP_LOADING';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_LOADING = 'RESEND_OTP_LOADING';
export const RESEND_OTP_FAIL = 'RESEND_OTP_FAIL';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

interface AuthState {
	loading: boolean;
	signedIn: boolean;
	data: any;
	error: string | null;
}

interface AuthAction {
	type: string;
	payload?: any;
}

export const authReducer = (
	state: AuthState,
	action: AuthAction
): AuthState => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				signedIn: true,
				data: action.payload,
				error: null,
			};
		case LOGIN_LOADING:
			return {
				...state,
				loading: true,
				signedIn: false,
				data: [],
				error: null,
			};
		case LOGIN_FAIL:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: null,
				error: action.payload,
			};

		case LOGOUT_SUCCESS:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: null,
				error: null,
			};
		case LOGOUT_LOADING:
			return {
				...state,
				loading: true,
				signedIn: false,
				data: [],
				error: null,
			};
		case LOGOUT_FAIL:
			return {
				...state,
				loading: false,
				signedIn: false,
				error: action.payload,
			};

		case SIGNUP_SUCCESS:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: action.payload,
				error: null,
			};
		case SIGNUP_LOADING:
			return {
				...state,
				loading: true,
				signedIn: false,
				data: [],
				error: null,
			};
		case SIGNUP_FAIL:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: null,
				error: action.payload,
			};

		case GET_USER_PROFILE_SUCCESS:
			return {
				...state,
				loading: false,
				signedIn: true,
				data: action.payload,
				error: null,
			};
		case GET_USER_PROFILE_LOADING:
			return {
				...state,
				loading: true,
				signedIn: true,
				data: [],
				error: null,
			};
		case GET_USER_PROFILE_FAIL:
			return {
				...state,
				loading: false,
				signedIn: true,
				data: null,
				error: action.payload,
			};

		case VERIFY_OTP_SUCCESS:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: action.payload,
				error: null,
			};
		case VERIFY_OTP_LOADING:
			return {
				...state,
				loading: true,
				signedIn: false,
				data: [],
				error: null,
			};
		case VERIFY_OTP_FAIL:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: null,
				error: action.payload,
			};

		case RESEND_OTP_SUCCESS:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: action.payload,
				error: null,
			};
		case RESEND_OTP_LOADING:
			return {
				...state,
				loading: true,
				signedIn: false,
				data: [],
				error: null,
			};
		case RESEND_OTP_FAIL:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: null,
				error: action.payload,
			};

		case CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				signedIn: false,
				data: action.payload,
				error: null,
			};
		case CHANGE_PASSWORD_LOADING:
			return {
				...state,
				loading: true,
				signedIn: true,
				data: [],
				error: null,
			};
		case CHANGE_PASSWORD_FAIL:
			return {
				...state,
				loading: false,
				signedIn: true,
				data: null,
				error: action.payload,
			};
		default:
			return state;
	}
};
