import { Separator } from '@/components/ui/separator';
import { BsFillClockFill, BsPlug, BsPerson } from 'react-icons/bs';
import { TransactionsType } from '../../../types';

interface Props {
	data: {
		contact_information: string;
		driver_id: string;
		driver_name: string;
		vehicle_description: string;
	};
}

export const DriverDetails = ({ data }: Props) => {
	return (
		<div className="rounded-lg shadow-lg bg-black/50 p-6 text-white space-y-4 transform transition duration-300 ease-in-out h-full">
			<div className="flex justify-between items-center">
				<h2 className="font-semibold text-lg flex items-center">
					<BsFillClockFill className="mr-4 text-yellow-500" />
					Vehicle Driver Details
				</h2>
			</div>
			<Separator className="bg-gray-500 h-[1px]" />
			<div className="">
				<ul className="space-y-3 flex-1">
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-bold text-sm">Driver Name:</span>{' '}
							<span className="capitalize text-xs text-zinc-400 ">
								{data.driver_name}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPerson className="mr-4 text-green-500" />
						<div>
							<span className="font-semibold text-sm">Driver ID:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.driver_id}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-semibold text-sm">
								Contact Information:
							</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.contact_information}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-semibold text-sm">
								Vehicle Description:
							</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.vehicle_description}
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

// Driver Details Section:
// Display information based on driver type.
// For public users, display anonymised information such as Driver ID.
// For fleet members, provide more detailed information like Driver ID,
// Name, Contact Information, and Vehicle Details.
