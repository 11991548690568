import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '@/components/ui/dialog';
import { SessionDetails } from './session-details-card';
import { ChargerDetails } from './charger-details';
import { DriverDetails } from './driver-details';
import { CostDetails } from './cost-details';
import { EnergyUtilisation } from './energy-utilisation';
import { TransactionsType } from '../../../types';
import { useEffect, useState } from 'react';
import { TableRow } from '@/components/ui/table';
import { X } from 'lucide-react';
import { getTransactionRecordsById } from '@/services/revenue';
import { statuses } from '../../../../Reservations/data/data';
import { useGetTransactionRecordsByIdQuery } from '@/redux/features/revenue/revenue.api';

interface TransactionsDataType {
	charging_session_detail: {
		charger_id: string;
		connector_id: number;
		duration: string;
		electricity_usage: number;
		initiated_by: string;
		session_id: string;
		transaction_status: string;
		transaction_timeline: string;
	};
	charger_usage_details: {
		charger_group_name: string;
		charger_id: string;
		charger_name: string;
		firmware_version: string;
		protocol: string;
		vendor_model: string;
	};
	cost_detials: {
		cost: number;
		payment_method: string;
		payment_status: string;
		transaction_reference_number: string;
	};
	vehicle_driver_details: {
		contact_information: string;
		driver_id: string;
		driver_name: string;
		vehicle_description: string;
	};
}

const TransactionDetails = ({
	id,
	setTransactionId,
}: {
	id: string;
	setTransactionId: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
	const [isModalOpen, setIsModalOpen] = useState(true);
	const [details, setDetails] = useState<TransactionsDataType>();

	const { data, isFetching, isError } = useGetTransactionRecordsByIdQuery({
		transaction_id: id,
	});

	useEffect(() => {
		if (data) {
			if (data.status == 'success' && data.data) {
				setDetails(data.data);
			}
		}
	}, [data]);

	return (
		<>
			<Dialog
				modal
				open={isModalOpen}
				onOpenChange={() => setIsModalOpen(true)}
			>
				<DialogContent className="border-0 shadow-2xl bg-primary p-4 text-white max-w-5xl max-h-[70%] overflow-scroll ">
					<DialogHeader>
						<DialogTitle className="flex justify-between items-center mb-6">
							<h2>Transaction Details</h2>

							<div className="flex justify-between items-center gap-4">
								<Button variant="secondary" className=" py-1">
									Print
								</Button>
								<Button
									onClick={() => {
										setTransactionId(null);
										setIsModalOpen(false);
									}}
									variant="secondary"
									type="button"
								>
									<X size={16} color="black" />
								</Button>
							</div>
						</DialogTitle>
						{details && (
							<DialogDescription className="flex gap-4 ">
								<div className="gap-3 grid grid-cols-2 items-center">
									<SessionDetails data={details.charging_session_detail} />
									<ChargerDetails data={details.charger_usage_details} />
									<CostDetails data={details.cost_detials} />
									<DriverDetails data={details.vehicle_driver_details} />
								</div>
								{/* <div className="flex-1">
								{data && <EnergyUtilisation data={data.data} />}
							</div> */}
							</DialogDescription>
						)}
					</DialogHeader>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default TransactionDetails;
