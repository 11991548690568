import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChargerNetworkState {
	loading: boolean;
	data: any;
	globalChargerGroups: {
		page: number;
	};
	error: null | string;
	chargerGroupStatus: {
		charger_count: number;
		category_counts: {
			online: number;
			charging: number;
			failure: number;
			offline: number;
		};
	} | null;
	showInstructions: boolean;
}

const initialState: ChargerNetworkState = {
	globalChargerGroups: {
		page: 1,
	},
	loading: false,
	data: {
		chargePoints: null,
		chargeGroups: null,
		chargerStatus: {},
		chargerUtilizationRate: {},
	},
	error: null,
	chargerGroupStatus: null,
	showInstructions: false,
};

const chargerNetworkSlice = createSlice({
	name: 'chargerNetwork',
	initialState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
			state.error = null;
		},
		setChargePoints: (state, action) => {
			state.data.chargePoints = action.payload.data;
			state.loading = false;
		},
		setChargeGroups: (state, action) => {
			state.data.chargeGroups = action.payload;
			state.loading = false;
		},
		setChargerStatus: (state, action) => {
			state.chargerGroupStatus = action.payload;
			state.loading = false;
		},
		setChargerUtilizationRate: (state, action) => {
			state.data.chargerUtilizationRate = action.payload.data;
			state.loading = false;
		},
		incrementGlobalChargerGroupsPage: (state) => {
			state.globalChargerGroups.page += 1;
		},
		resetGlobalChargerGroupsPage: (state) => {
			state.globalChargerGroups.page = 1;
		},
		setShowInstructions: (state, action) => {
			state.showInstructions = action.payload;
		},
	},
});

export const {
	setChargePoints,
	startLoading,
	setChargeGroups,
	setChargerStatus,
	setChargerUtilizationRate,
	incrementGlobalChargerGroupsPage,
	resetGlobalChargerGroupsPage,
	setShowInstructions,
} = chargerNetworkSlice.actions;

export default chargerNetworkSlice.reducer;
