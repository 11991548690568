import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ConnectorTable } from "./connector-table/connector-table";
import { DeviceCharts } from "./connector-table/device-stats";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { TableRow } from "@/components/ui/table";
import { RecentFeedsContainer } from "./connector-table/recent-feeds/recent-feeds-container";
import { ChargerType } from "../../types";
import { X } from "lucide-react";
import { ChargePointType } from "@/types/charger-network";
import { useGetChargepointConnectorsQuery } from "@/redux/features/charger-network/charger-network.api";
import { useAppSelector } from "@/redux/hooks";
import { Zap } from "react-feather";

type Props = {
  children?: React.ReactNode;
  data?: ChargePointType;
  setModalOpen: any;
};

export const ChargerDetails = ({ data, children, setModalOpen }: Props) => {
  const { orgId } = useAppSelector((state) => state.auth);
  const {
    isLoading,
    data: connectorsData,
    isError,
    error,
  } = useGetChargepointConnectorsQuery(
    {
      orgId: orgId,
      page: 1,
      chargepointId: data?.id,
    },
    { skip: !orgId }
  );

  return (
    <Dialog modal open>
      <DialogContent className="border-0 shadow-2xl bg-background p-4 max-w-2xl text-white   overflow-none  scrollbar-track-gray-600 scrollbar-thin ">
        <DialogTitle className="flex justify-between items-center">
          <h2 className="my-5">
            Chargepoint Details for {data?.charge_point_id}
          </h2>
          <Button
            onClick={() => setModalOpen(null)}
            variant="secondary"
            type="button"
          >
            <X />
          </Button>
        </DialogTitle>

        <p className="bg-gray-800 text-green-400 p-4 rounded-lg mb-6 text-xs">
          wss://core.grdflo.com/ocpp/1.6/central_system/{data?.charge_point_id}
        </p>

        <div className="w-full flex items-center justify-center">
          {isLoading && <p>Loading connectors</p>}

          {!isLoading &&
            connectorsData &&
            connectorsData.data.results.length && (
              <div className="w-full flex flex-col space-y-3">
                {connectorsData.data.results.map((item: any) => (
                  <div
                    key={item.connector_id}
                    className="flex w-full items-center p-2 bg-opacity-20 border-white border text-white rounded-md"
                  >
                    <Zap className="text-base mr-3" />
                    <div>
                      <p className="text-xs font-semibold">
                        {item.connector_status}
                      </p>
                      <span className="font-bold">
                        Connector {item.connector_id}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}

          {!isLoading &&
            (!connectorsData || connectorsData.data.results.length === 0) && (
              <div>No connectors</div>
            )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
