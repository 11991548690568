import { formatDate } from '@/lib/helpers/dates';
import { faker } from '@faker-js/faker';
import { generateFakeRecentActivity } from '../../ChargerNetwork/data/chargers';
import {
	DriverType,
	DriversType,
	MetricRolesType,
	MetricType,
	RoleType,
	RolesType,
} from '../types';
import { driverRoles } from './drivers-table-data';

// Function to generate an array of fake numeric data
const generateFakeNumericData = (count: number, maxValue: number): number[] => {
	const fakeData = Array.from({ length: count }, () =>
		faker.number.int({ max: maxValue })
	);
	return fakeData;
};

// Function to generate fake metrics data
const generateFakeMetricsData = (
	title: string,
	count: number,
	maxValue: number
): MetricType => {
	return {
		title,
		data: generateFakeNumericData(count, maxValue),
		metric: faker.number.int({ max: 100 }).toString(),
		deltaValue: faker.number.float({ min: 0, max: 10, precision: 1 }),
		deltaType: faker.helpers.arrayElement([
			'ModerateIncrease',
			'ModerateDecrease',
		]),
		dayTime: new Date().toLocaleDateString('en-US', { month: 'long' }),
	};
};

const generateFakeMetricsRolesData = (
	count: number,
	maxValue: number
): MetricRolesType => {
	return {
		title: 'Role Expirations',
		data: generateFakeNumericData(count, maxValue),
		roles: generateFakeRoles(5),
		metric: faker.number.int({ max: 100 }).toString(),
		deltaValue: faker.number.float({ min: 0, max: 10, precision: 1 }),
		deltaType: faker.helpers.arrayElement([
			'ModerateIncrease',
			'ModerateDecrease',
		]),
		dayTime: new Date().toLocaleDateString('en-US', { month: 'long' }),
	};
};

export const generateFakeRoles = (count: number): RoleType[] => {
	const fakeRoles = [];

	for (let i = 0; i < count; i++) {
		fakeRoles.push({
			id: `RLE-${faker.number.int({ min: 10, max: 100 })}`,
			roleName: faker.helpers.arrayElement([
				'Sales Manager',
				'Developer',
				'Driver',
				'Guest',
			]),
			accessLevel: faker.helpers.arrayElement([
				'Administrator',
				'Manager',
				'Analyst',
				'Developer',
				'Guest',
			]),
			expirationDate: formatDate(faker.date.recent().toString()),
		});
	}

	return fakeRoles;
};

// Generate fake metrics data
export const metricsData = [
	generateFakeMetricsData('Total Drivers', 10, 30),
	generateFakeMetricsData('Active Roles', 10, 30),
	generateFakeMetricsRolesData(10, 40),
	generateFakeMetricsData('New Drivers', 10, 30),
	generateFakeMetricsData('Drivers Without Active Roles', 10, 30),
];

export const generateFakeDrivers = (count: number): DriversType[] => {
	const drivers = [];

	for (let i = 0; i < count; i++) {
		const driver = {
			id: `DRVR-${faker.number.int({ min: 10, max: 100 })}`,
			name: faker.person.fullName(),
			role: faker.helpers.arrayElement(driverRoles).value,
			electricityUsage: faker.number.int({ min: 50, max: 100 }),
			chargingSessions: faker.number.int({ min: 50, max: 100 }),
			expirationDate: formatDate(faker.date.recent().toString()),
			recentActivities: generateFakeRecentActivity(10),
			email: faker.internet.email(),
			phoneNumber: faker.phone.number(),
		};
		drivers.push(driver);
	}
	return drivers;
};

export const generateFakeUserRoles = (count: number) => {
	const roles: RolesType[] = [];
	for (let i = 0; i < count; i++) {
		const role = {
			id: `RLE-${faker.number.int({ min: 10, max: 100 })}`,
			roleName: faker.helpers.arrayElement([
				'sales manager',
				'developer',
				'driver',
				'guest',
			]),
			accessLevel: faker.helpers.arrayElement([
				'administrator',
				'manager',
				'analyst',
				'developer',
				'guest',
			]),
			expirationDate: formatDate(faker.date.recent().toString()),
			totalDrivers: faker.number.int({ min: 50, max: 100 }),
		};
		roles.push(role);
	}

	return roles;
};

export const generateFakeDriversNoRoles = (count: number) => {
	const drivers: DriverType[] = [];
	for (let i = 0; i < count; i++) {
		const driver = {
			id: `DRV-${faker.number.int({ min: 10, max: 100 })}`,
			driverName: faker.person.fullName(),
		};
		drivers.push(driver);
	}

	return drivers;
};
