import { getChargerPointsById } from '@/services/chargers';
import React, { useEffect, useState } from 'react';
import { GroupDetails } from './group-details';
import { GroupType } from '../../types';

type Props = {};

export const GroupDetailsContainer = ({
	groupId,
	setSelectedGroupId,
}: {
	groupId: string;
	setSelectedGroupId: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
}) => {
	const [loading, setLoading] = useState(false);
	const [groupDetails, setGroupDetails] = useState<GroupType>();
	useEffect(() => {
		const fetchDetails = async () => {
			setLoading(true);
			try {
				const response = await getChargerPointsById({ charger_id: groupId });
				setGroupDetails(response.data);
			} catch (error) {
				console.error('Error fetching details:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchDetails();
	}, [groupId]);

	return (
		<GroupDetails
			data={groupDetails}
			loading={loading}
			setSelectedGroupId={setSelectedGroupId}
		/>
	);
};

// address: 'Test';
// city: 'Test';
// country: 'AL';
// created_by: 'e4795399-08d0-4e30-87c4-7201115bd0eb';
// description: null;
// id: '9244be1f-3fad-471e-9561-d85297b116e2';
// latitude: '0.99992';
// longitude: '1.223455';
// name: 'Test2';
// organization: 'fa009987-e6a5-4253-a494-6c03beeee2f0';
// state: '09';
// tarrif_plan: null;
// zip_code: '123456';
