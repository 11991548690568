import { useContext } from 'react';
import { OrganisationContext } from '@/store/organisation/context';

export const useOrg = () => {
	const context = useContext(OrganisationContext);
	if (!context) {
		throw new Error(
			'useOrganisation must be used within an OrganisationProvider'
		);
	}
	return context;
};
