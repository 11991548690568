import { DeltaType } from "@/common/constants/enums";
import { cardData } from "./data/dashboard";
import { ChargerLocations } from "./components/charger-locations";
import { KwhGraph } from "./components/kwh-graph";
import { Navbar } from "@/components/navbar";
import { OverviewCard } from "@/components/overview-card";
import { SalesChart } from "./components/sales-chart";
import { TotalChargers } from "./components/total-chargers";
import { Traffic } from "./components/traffic";
import { UtilizationRatio } from "./components/utilization-ratio";
import { generateChargerData } from "./data/charger";
import { isValidDeltaType } from "@/lib/helpers/delta-type";
import { useEffect, useState } from "react";
import {
  calculateRevenueChange,
  calculatePercentageChange,
} from "@/lib/helpers/calculate-revenue-change";
import { number } from "zod";
import { ChargerLocationType } from "./types";
import { TotalRevenueCard } from "./components/total-revenue-card";
import {
  useGetChargerLocationsQuery,
  useGetKwhTodayQuery,
  useGetTodayChargingSessionQuery,
  useGetTodayRevenueQuery,
  useGetTotalChargersQuery,
  useGetTotalUsageTodayQuery,
} from "@/redux/features/home/home.api";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  setChargerLocations,
  setChargingSession,
  setKwhToday,
  setTotalRevenue,
  setTotalChargers,
  setTotalUsageToday,
  startLoading,
} from "@/redux/features/home/home.slice";
const Home = () => {
  const {
    chargingSession,
    kwhToday,
    totalUsageToday,
    totalChargers,
    chargerLocations,
    totalRevenue,
    loading,
  } = useAppSelector((state) => state.home);
  const { orgId } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const { data: chargingSessionData, isLoading: chargingSessionLoading } =
    useGetTodayChargingSessionQuery(orgId, { skip: !orgId });
  const { data: kwhTodayData, isLoading: kwhTodayLoading } =
    useGetKwhTodayQuery(orgId, { skip: !orgId });
  const { data: totalUsageTodayData, isLoading: totalUsageTodayLoading } =
    useGetTotalUsageTodayQuery(orgId, { skip: !orgId });
  const { data: totalChargersData, isLoading: totalChargersLoading } =
    useGetTotalChargersQuery(orgId, { skip: !orgId });
  const { data: chargerLocationsData, isLoading: chargerLocationsLoading } =
    useGetChargerLocationsQuery(orgId, { skip: !orgId });
  const { data: totalRevenueData, isLoading: totalRevenueLoading } =
    useGetTodayRevenueQuery(orgId, { skip: !orgId });

  useEffect(() => {
    if (
      chargingSessionLoading &&
      kwhTodayLoading &&
      totalUsageTodayLoading &&
      totalChargersLoading &&
      chargerLocationsLoading &&
      totalRevenueLoading
    ) {
      dispatch(startLoading());
    } else if (
      chargingSessionData &&
      kwhTodayData &&
      totalUsageTodayData &&
      totalChargersData &&
      chargerLocationsData &&
      totalRevenueData
    ) {
      dispatch(setChargingSession(chargingSessionData.data));
      dispatch(setKwhToday(kwhTodayData.data));
      dispatch(setTotalUsageToday(totalUsageTodayData.data));
      dispatch(setTotalChargers(totalChargersData.data));
      dispatch(setTotalRevenue(totalRevenueData.data));
      dispatch(setChargerLocations(chargerLocationsData.data));
    }
  }, [
    dispatch,
    chargingSessionData,
    kwhTodayData,
    totalUsageTodayData,
    totalChargersData,
    chargerLocationsData,
    totalRevenueData,
    chargingSessionLoading,
    kwhTodayLoading,
    totalUsageTodayLoading,
    totalChargersLoading,
    chargerLocationsLoading,
    totalRevenueLoading,
  ]);

  return (
    <div>
      {loading ? (
        <div className="inset-0 absolute h-screen w-full bg-black bg-opacity-30 backdrop-blur-sm z-50 ">
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
            aria-label="Pocket Lawyers"
          >
            <img
              className=" animate-bounce "
              src="/images/logo.png"
              alt="logo"
              height={128}
              width={122}
            />
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundImage: "url(/images/--gf-dashboard-cover.png)",
              backgroundSize: "100% 270px",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="bg-cover md:px-9 px-4  md:pt-9 pt-4 flex flex-col 2xl:gap-11 gap-5 mb-10 container">
              <Navbar title="Dashboard Overview" />
              {chargingSession &&
                kwhToday &&
                totalUsageToday &&
                totalChargers &&
                totalRevenue && (
                  <div className="flex flex-col xl:flex-row -mb-10 gap-5">
                    <div className=" flex-1 grid grid-cols-2 justify-between md:gap-5 gap-2 md:flex-row flex-col  ">
                      <TotalRevenueCard data={totalRevenue} />

                      <OverviewCard
                        data={[1, 0]}
                        title="Charging Sessions"
                        metric={chargingSession.total_charging_sessions_today}
                        deltaValue={chargingSession.percentageChange}
                        deltaType={
                          isValidDeltaType(chargingSession.deltaType)
                            ? DeltaType[chargingSession.deltaType]
                            : "default"
                        }
                        dayTime="yesterday"
                        className="!w-full"
                      />

                      <OverviewCard
                        data={[1, 0]}
                        title="kWh Charged Today"
                        metric={kwhToday.today_total_energy_consumed_day}
                        deltaValue={kwhToday.percentageChange}
                        deltaType={
                          isValidDeltaType(kwhToday.deltaType)
                            ? DeltaType[kwhToday.deltaType]
                            : "default"
                        }
                        dayTime="yesterday"
                        className="!w-full"
                      />
                      <OverviewCard
                        data={[1, 0]}
                        title="Total Usage Today"
                        metric={
                          (totalUsageToday.today_usage_rate * 100).toFixed(2) +
                          "%"
                        }
                        deltaValue={totalUsageToday.percentageChange}
                        deltaType={
                          isValidDeltaType(totalUsageToday.deltaType)
                            ? DeltaType[totalUsageToday.deltaType]
                            : "default"
                        }
                        dayTime="yesterday"
                        className="!w-full"
                      />
                    </div>
                    <div className="flex-[0.5] hidden 2xl:block">
                      <TotalChargers data={totalChargers} />
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="2xl:hidden flex md:px-9 px-4  md:pt-9 pt-4 2xl:gap-11 gap-5  xl:flex-row flex-col container">
            {totalChargers && (
              <div className="flex-[0.5] w-full">
                <TotalChargers data={totalChargers} />
              </div>
            )}
            <div className="flex-[0.5] h-full">
              <Traffic />
            </div>
          </div>
          <div className="flex md:px-9 px-4   md:pt-9 pt-4 2xl:gap-11 gap-5  xl:flex-row flex-col container">
            <div className="flex-1">
              <SalesChart />
            </div>
            <div className="flex-[0.5] h-full hidden 2xl:block">
              <Traffic />
            </div>
          </div>
          <div className="flex  md:px-9 px-4 md:pt-9 pt-4 2xl:gap-11 gap-5 md:flex-row flex-col container">
            <div className="flex-1">
              <KwhGraph />{" "}
            </div>
            <div className="flex-[0.5]">
              <UtilizationRatio />
            </div>
          </div>
          <div className=" md:px-9 px-4  md:pt-9 py-4 pb-8 container">
            {chargerLocations && <ChargerLocations data={chargerLocations} />}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
