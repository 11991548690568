import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import onlineIcon from "@/assets/icons/charger-online-icon.png";
import chargerGroup from "@/assets/icons/charger-groups.png";
import offlineIcon from "@/assets/icons/charger-offline-icon.png";
import idleIcon from "@/assets/icons/charger-idle-icon.png";
import useSupercluster from "use-supercluster";
import { useCallback, useMemo, useRef, useState } from "react";
import { calculateCenter, calculateZoomLevel } from "../helpers/map";
import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_ID } from "../config";
import { ChargerLocationType } from "../types";
import { ZoomButton } from "./custom-map-ui/zoom-button";

import { toast } from "sonner";
import { Minus, Plus } from "lucide-react";
import { BsFullscreen } from "react-icons/bs";
import { ExitFullScreenIcon } from "@radix-ui/react-icons";
import { useAppSelector } from "@/redux/hooks";

interface ChargerMapProps {
  data: ChargerLocationType[];
}

interface IChargerPin {
  status: string;
  lat: number;
  lng: number;
}
export const ChargerLocations: React.FC<ChargerMapProps> = ({ data }) => {
  const chargers = data
    .filter(({ lat, lng }) => lat !== null && lng !== null)
    // do not remove the multiplication as it's for converting the coordinates to number type, which is the fastest and simplest way.
    .map(({ lat, lng, status }) => ({ lat: lat * 1, lng: lng * 1, status }));

  const { currentOrg } = useAppSelector((state) => state.org);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    mapIds: [GOOGLE_MAP_ID],
  });

  const hongKongBounds = {
    north: 22.46824,
    south: 22.134099,
    east: 114.305253,
    west: 113.82563,
  };

  const mapRef = useRef<google.maps.Map>();

  const [center, setCenter] = useState(
    currentOrg
      ? { lat: currentOrg.org_latitude, lng: currentOrg.org_longitude }
      : calculateCenter(chargers)
  );
  const [zoom, setZoom] = useState(calculateZoomLevel(chargers));
  const [bounds, setBounds] = useState<number[]>();
  const [isSatelliteView, setIsSatelliteView] = useState(false);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    setCenter(
      currentOrg
        ? { lat: currentOrg.org_latitude, lng: currentOrg.org_longitude }
        : calculateCenter(chargers)
    );
  }, []);

  const onZoomChanged = useCallback(() => {
    if (mapRef && mapRef.current) {
      const z = mapRef.current.getZoom();
      setZoom(z || zoom);
    }
  }, [zoom]);

  const onBoundsChanged = useCallback(() => {
    if (mapRef && mapRef.current) {
      const b = mapRef.current.getBounds();
      if (b) {
        const west = b.getSouthWest().lng();
        const south = b.getSouthWest().lat();
        const east = b.getNorthEast().lng();
        const north = b.getNorthEast().lat();

        // ? For setting the bounds, we need to follow the sequence
        setBounds([west, south, east, north]);
      }
    }
  }, []);

  const points = useMemo(() => {
    const formattedPoints = chargers.map((charger) => ({
      type: "Feature",
      properties: { cluster: false, ...charger },
      geometry: {
        type: "Point",
        coordinates: [charger.lng, charger.lat],
      },
    }));
    return formattedPoints;
  }, [chargers]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 25 },
  });
  if (loadError) return <p>An error came up</p>;
  if (!isLoaded) return <p>Loading...</p>;

  const handleZoomIn = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(zoom + 1);
    }
  };

  const handleZoomOut = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(zoom - 1);
      if (zoom === 11) {
        toast("Max zoom level reached!");
      }
    }
  };

  const handleFullScreen = () => {
    if (mapRef.current) {
      const mapContainer = mapRef.current.getDiv();
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        mapContainer.requestFullscreen();
      }
    }
  };

  const handleToggleMap = () => {
    setIsSatelliteView((prev) => !prev);
  };

  const handleMarkerClick = (
    event: google.maps.MapMouseEvent,
    cluster: any
  ) => {
    const pointerEvent = event.domEvent as PointerEvent;

    if (pointerEvent.shiftKey) {
      if (mapRef.current) {
        mapRef.current.setZoom(zoom - 1);
      }
    } else {
      handleZoomIn();
      setCenter({
        lat: cluster.properties.lat,
        lng: cluster.properties.lng,
      });
    }
  };

  return (
    <>
      <div className="rounded-2xl overflow-hidden relative">
        <div className="p-6 absolute w-full h-[136px] z-10 bg-gradient-to-t from-[rgba(0,0,0,0.0)] via-[rgba(0,0,0,0.7)] to-[rgba(0,0,0,0.8)]">
          <h3 className="text-xl font-light text-white h-[136px]">
            Charger Location and Status
          </h3>
        </div>
        <div>
          <GoogleMap
            mapContainerStyle={{ height: "calc(100vh - 101px)" }}
            zoom={10} //note: theres a function that calculates average zool level of all available chargers - calculateZoomLevel
            center={center}
            onLoad={onMapLoad}
            onZoomChanged={onZoomChanged}
            onBoundsChanged={onBoundsChanged}
            options={{
              mapId: GOOGLE_MAP_ID,

              disableDefaultUI: true,
              mapTypeId: isSatelliteView
                ? window.google.maps.MapTypeId.SATELLITE
                : window.google.maps.MapTypeId.ROADMAP,
              //   restriction: {
              //     latLngBounds: hongKongBounds,
              //     strictBounds: false,
              //   },
            }}
          >
            <div className="absolute bottom-2 right-2 flex flex-col gap-2">
              <ZoomButton className="w-max p-4" onClick={handleZoomIn}>
                <Plus />
              </ZoomButton>
              <ZoomButton onClick={handleZoomOut}>
                <Minus />
              </ZoomButton>
            </div>
            <div className="flex absolute right-2 top-2 gap-2">
              <ZoomButton onClick={handleToggleMap}>
                {isSatelliteView
                  ? "Switch to Normal Map"
                  : "Switch to Satellite"}
              </ZoomButton>
              <ZoomButton className="" onClick={handleFullScreen}>
                {document.fullscreenElement ? (
                  <ExitFullScreenIcon />
                ) : (
                  <BsFullscreen />
                )}
              </ZoomButton>
            </div>
            {clusters.map((cluster) => {
              const [lng, lat] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                cluster.properties;

              if (isCluster) {
                return (
                  <MarkerF
                    key={`cluster-${cluster.id}`}
                    position={{ lat, lng }}
                    title={pointCount.toString()}
                    visible={true}
                    label={pointCount.toString()}
                    zIndex={1000}
                    icon={{
                      url: chargerGroup,
                      origin: new window.google.maps.Point(0, 0),
                      scaledSize: new window.google.maps.Size(30, 30),
                      // labelOrigin: new window.google.maps.Point(25, -15),
                    }}
                    onClick={() => {
                      if (mapRef && mapRef.current) {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          20
                        );
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({ lat, lng });
                      }
                    }}
                  />
                );
              } else {
                return (
                  <MarkerF
                    key={cluster.properties.id}
                    position={{ lat, lng }}
                    icon={{
                      url:
                        cluster.properties.status === "Operative"
                          ? onlineIcon
                          : cluster.properties.status === "Inoperative"
                            ? offlineIcon
                            : cluster.properties.status ===
                                "Partially Operative"
                              ? idleIcon
                              : onlineIcon,
                      origin: new window.google.maps.Point(0, 0),
                      scaledSize: new window.google.maps.Size(30, 40),
                    }}
                    onClick={(event) => handleMarkerClick(event, cluster)}
                  />
                );
              }
            })}
          </GoogleMap>
        </div>
      </div>
    </>
  );
};
