import * as z from 'zod';
import { EyeIcon, EyeOff } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { useEffect, useState } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '@/lib/hooks/use-auth';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLoginMutation } from '@/redux/features/auth/auth.api';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import Cookies from 'js-cookie';
import {
	authenticationSuccess,
	setUserPrivateOrgId,
} from '@/redux/features/auth/auth.slice';
import { toast } from 'sonner';

const loginFormSchema = z.object({
	email: z.string().email({ message: 'Invalid email address.' }),
	password: z.string(),
});

const Login = () => {
	const [loginMutation, { isLoading }] = useLoginMutation();
	const [searchParams, setSearchParams] = useSearchParams();

	const redirect = searchParams.get('redirect') || null;
	const inviteToken = searchParams.get('inviteToken') || null;
	const invitedUserEmail = searchParams.get('userEmail') || null;
	const orgName = searchParams.get('orgName') || null;

	const [passwordType, setPasswordType] = useState(true);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const loginDetailsForm = useForm<z.infer<typeof loginFormSchema>>({
		defaultValues: {
			email: invitedUserEmail || '',
		},
		resolver: zodResolver(loginFormSchema),
	});

	const EyeComponent = passwordType ? EyeIcon : EyeOff;

	const handleLogin = async (values: { email: string; password: string }) => {
		try {
			const loginResult = await loginMutation(values)
				.unwrap()
				.then((data) => {
					if (data?.response_status == 'success' && data?.user_data) {
						Cookies.set('access_token', data.access_token, { expires: 24 / 6 });
						Cookies.set('refresh_token', data.refresh_token, {
							expires: 24 / 6,
						});

						dispatch(authenticationSuccess(data.user_data));

						toast.success(data.message);

						if (redirect) {
							setTimeout(() => {
								navigate(
									`/acceptInvitation?referrer=${invitedUserEmail}&invite_token=${inviteToken}`
								);
							}, 1000);
						} else {
							setTimeout(() => {
								navigate('/');
							}, 1000);
						}
					}
				});
		} catch (error: any) {
			if (error?.data?.response_status == 'error') {
				toast.error(error?.data?.error_message);
			}
			if (error?.data?.detail) {
				toast.error(error?.data?.detail);
			}
		}
	};

	return (
		<div className="h-full flex  items-center justify-center">
			<div className=" w-full m-4 max-w-[600px] mx-auto">
				<div>
					<div className="flex items-center flex-col justify-center gap-3 my-3">
						<img src="/images/logo-text.png" height={50} width={150} />
					</div>
					<div className="flex gap-2 items-center">
						<div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
						<span className="text-white text-sm font-light  w-max mx-2 whitespace-nowrap text-center">
							Sign in with
						</span>
						<div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
					</div>
				</div>

				{invitedUserEmail && orgName && inviteToken && (
					<div className="bg-muted-foreground bg-[#eab308] px-3 py-4 my-4 rounded-md">
						<p className="text-sm">
							After log in, you will be automatically added to{' '}
							<span className="underline underline-offset-2 font-bold">
								{orgName}
							</span>
						</p>
					</div>
				)}
				<Form {...loginDetailsForm}>
					<form
						className="my-8 flex flex-col gap-2"
						onSubmit={loginDetailsForm.handleSubmit(handleLogin)}
					>
						<div className="flex flex-col gap-4">
							<FormField
								control={loginDetailsForm.control}
								name="email"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormControl>
											<Input
												type="email"
												placeholder="Email Address"
												className=""
												{...field}
											/>
										</FormControl>
										<FormMessage className=" text-[10px]" />
									</FormItem>
								)}
							/>

							<FormField
								control={loginDetailsForm.control}
								name="password"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormControl>
											<div className="relative transition-all duration-150">
												<Input
													type={passwordType ? 'password' : 'text'}
													placeholder="Password"
													className=""
													{...field}
												/>

												<EyeComponent
													className="absolute z-10 right-2 bottom-3 cursor-pointer"
													size={16}
													onClick={() => {
														setPasswordType((prev) => !prev);
													}}
												/>
											</div>
										</FormControl>
										<FormMessage className=" text-[10px]" />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex justify-end w-full items-end gap-5 my-2 px-2">
							<Link
								to="/forgot-password"
								className="text-white text-xs  underline font-bold"
							>
								Forgot Password?
							</Link>
						</div>
						<div className="my-6 w-full">
							<div className="flex justify-center items-center">
								<Button
									type="submit"
									disabled={isLoading}
									className="bg-[#eab308] text-black hover:bg-[#eab308]/60  w-full md:w-[60%] "
								>
									Login
								</Button>
							</div>
						</div>
					</form>
				</Form>
				<div>
					<h2 className="text-white text-xs font-light ">
						Not a member?{' '}
						<Link to="/signup" className="underline font-bold">
							Sign up now
						</Link>
					</h2>
				</div>
			</div>
		</div>
	);
};

export default Login;
