import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSliceReducer from './features/auth/auth.slice';
import homeSliceReducer from './features/home/home.slice';
import revenueSliceReducer from './features/revenue/revenue.slice';
import chargerNetworkReducer from './features/charger-network/charger-network.slice';
import tariffReducer from './features/tariff/tariff.slice';
import orgReducer from './features/org/org.slice';
import { authAction } from './features/auth/auth.api';
import { homeAction } from './features/home/home.api';
import { revenueApi } from './features/revenue/revenue.api';
import { chargerNetworkApi } from './features/charger-network/charger-network.api';
import { tariffApi } from './features/tariff/tariff.api';
import { orgApi } from './features/org/org.api';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
		'auth',
		'home',
		'revenue',
		'chargerNetwork',
		'tariff',
		'orgApi',
		'org',
	],
};

const rootReducer = combineReducers({
	auth: authSliceReducer,
	home: homeSliceReducer,
	revenue: revenueSliceReducer,
	chargerNetwork: chargerNetworkReducer,
	tariff: tariffReducer,
	org: orgReducer,
	[authAction.reducerPath]: authAction.reducer,
	[homeAction.reducerPath]: homeAction.reducer,
	[revenueApi.reducerPath]: revenueApi.reducer,
	[chargerNetworkApi.reducerPath]: chargerNetworkApi.reducer,
	[tariffApi.reducerPath]: tariffApi.reducer,
	[orgApi.reducerPath]: orgApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['persist/PERSIST'],
			},
		}).concat([
			authAction.middleware,
			homeAction.middleware,
			revenueApi.middleware,
			chargerNetworkApi.middleware,
			tariffApi.middleware,
			orgApi.middleware,
		]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
