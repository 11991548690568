import { totalChargersPieChartOptions } from '@/common/variables/charts';
import PieChart from '@/components/ui/pie-chart';
import { Skeleton } from '@/components/ui/skeleton';
import { TotalChargersType } from '@/types/home';

type ChargerData = {
	chargerCategoryName: string;
	value: number;
};

type Props = {
	data: TotalChargersType;
};

export const TotalChargers = ({ data }: Props) => {
	const totalChargers: ChargerData[] = [
		{
			chargerCategoryName: 'Online',
			value: data.category_counts.online,
		},
		{
			chargerCategoryName: 'Charging',
			value: data.category_counts.charging,
		},
		{
			chargerCategoryName: 'Offline',
			value: data.category_counts.offline,
		},
		{
			chargerCategoryName: 'Failure',
			value: data.category_counts.failure,
		},
	];

	return (
		<div className="backdrop-blur-xl bg-gradient-linear relative pb-0 rounded-xl  h-full overflow-hidden">
			{totalChargers && (
				<div className="p-6 flex items-start gap-7 flex-col">
					<div className="text-[#D1D1D1] font-extralight">
						<h2>Total chargers </h2>
						<span className="text-white font-bold text-2xl">
							{data.charger_count}
						</span>
					</div>
					<div className="min-h-[350px] flex-1 w-full md:min-w-[300px] relative">
						<PieChart
							type="pie"
							chartData={totalChargers.map((charger) => charger.value)}
							chartOptions={totalChargersPieChartOptions}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
