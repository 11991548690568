import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { ArrowTopRightIcon } from '@radix-ui/react-icons';
import { BsFillClockFill, BsLightning, BsPlug, BsPerson } from 'react-icons/bs';
import { TransactionsType } from '../../../types';

interface Props {
	data: {
		charger_group_name: string;
		charger_id: string;
		charger_name: string;
		firmware_version: string;
		protocol: string;
		vendor_model: string;
	};
}

vendor_model: 'evse';

export const ChargerDetails = ({ data }: Props) => {
	return (
		<div className="rounded-lg shadow-lg bg-black/50 p-6 text-white space-y-4 transform transition duration-300 ease-in-out h-full">
			<div className="flex justify-between items-center gap-3 ">
				<h2 className="font-semibold flex items-center">
					<BsFillClockFill className="mr-4 text-yellow-500" />
					Charger Usage Details
				</h2>
				<Button className="bg-transparent border-b rounded-b-none" size="sm">
					Show additinal details <ArrowTopRightIcon className="ml-2" />
				</Button>
			</div>
			<Separator className="bg-gray-500 h-[1px]" />
			<div className="">
				<ul className="space-y-3 flex-1">
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-bold text-sm">Charger ID:</span>{' '}
							<span className="uppercase text-xs text-zinc-400 ">
								{data.charger_id}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPerson className="mr-4 text-green-500" />
						<div>
							<span className="font-semibold text-sm">Group Name:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.charger_group_name}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsPlug className="mr-4 text-blue-500" />
						<div>
							<span className="font-semibold text-sm">Protocol:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.protocol}
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsLightning className="mr-4 text-yellow-500" />
						<div>
							<span className="font-semibold text-sm">Vendor/Model:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.vendor_model} kW/h
							</span>
						</div>
					</li>
					<li className="flex items-start">
						<BsLightning className="mr-4 text-yellow-500" />
						<div>
							<span className="font-semibold text-sm">Firmware version:</span>{' '}
							<span className="text-xs text-zinc-400 capitalize">
								{data.firmware_version} kW/h
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};
