import { barChartOptionsDailyTraffic } from "@/common/variables/charts";
import { BarChart } from "@/components/ui/bar-chart";
import { DateRangePicker } from "@/components/ui/date-range";
import { getWeeklySessionsCount } from "@/services/sessions";
import { useCallback, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import {
  extractDayDates,
  formatDateToYYYYMMDD,
  sortDatesByEarliest,
} from "@/lib/helpers/dates";
import { faker } from "@faker-js/faker";
import { getWeeklyEnergyConsumedAnalytics } from "@/services/energy";
import { addDays, subDays } from "date-fns";
import { useGetWeeklySessionsQuery } from "@/redux/features/home/home.api";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  setWeeklySessions,
  startLoading,
} from "@/redux/features/home/home.slice";
import { Skeleton } from "@/components/ui/skeleton";

export const Traffic = () => {
  const [date, setDate] = useState<DateRange | any>({
    from: subDays(new Date(), 6),
    to: new Date(),
  });
  const { orgId } = useAppSelector((state) => state.auth);
  const { weeklySessions, loading } = useAppSelector((state) => state.home);

  const dispatch = useAppDispatch();
  const { data: weeklySessionsData, isLoading: weeklySessionsLoading } =
    useGetWeeklySessionsQuery(
      {
        org_id: orgId,
        start_date: formatDateToYYYYMMDD(date.from),
      },
      { skip: !orgId }
    );

  const [data, setData] = useState<number[]>();
  const [dayDatesArray, setDayDatesArray] = useState<string[]>([]);

  const [dataSum, setDataSum] = useState<number>(0);

  function getDayOfWeekInRange(start: any, end: any) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const result = [];
    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const dayIndex = currentDate.getDay();
      result.push(dayOfWeek[dayIndex]);
    }

    setDayDatesArray(result);
    return result;
  }

  useEffect(() => {
    if (weeklySessionsLoading) {
      dispatch(startLoading());
    } else if (weeklySessionsData) {
      getDayOfWeekInRange(date.from, date.to);

      dispatch(setWeeklySessions(weeklySessionsData.data));

      const newData: any[] =
        weeklySessions &&
        weeklySessions.charging_sessions_per_day.map((obj: any) => {
          return Object.values(obj)[0];
        });

      setData(newData);
    }
  }, [date, weeklySessions, weeklySessionsData, dispatch]);

  return (
    <div className="backdrop-blur-xl bg-gradient-linear relative rounded-2xl h-full overflow-hidden">
      {loading ? (
        <div className="p-6 flex items-start gap-3 flex-col w-full">
          <div className="text-[#D1D1D1] font-extralight w-full space-y-2 flex justify-between items-center">
            <Skeleton className="h-5 w-[160px] " />
            <Skeleton className="h-10 w-[180px] " />
          </div>
          <div className="w-full">
            <Skeleton className="  h-[230px] rounded-lg w-full" />
          </div>
        </div>
      ) : (
        <div className="p-6 ">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-[#D1D1D1] font-extralight">
                Avg. Sessions Last Week
              </h3>
            </div>
            <div>
              <DateRangePicker onDateChange={setDate} />
            </div>
          </div>
          <div className="min-h-[260px] relative">
            <BarChart
              chartData={[
                {
                  name: "Average Weekly Sessions",
                  data: data ?? [],
                },
              ]}
              chartOptions={barChartOptionsDailyTraffic(dayDatesArray)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
