import { chargerGroupsOptions } from '@/common/variables/charts';
import { RangeBarChart } from '@/components/ui/range-bar-chart';
import { generateRandomContributions } from '../data/revenue';
import { useEffect, useRef, useState } from 'react';
import { getChargerGroupContributionsDaily } from '@/services/revenue';
import { BarChart } from '@/components/ui/bar-chart';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { setWeeklySessions } from '@/redux/features/home/home.slice';
import { setContributions } from '@/redux/features/revenue/revenue.slice';
import { useGetChargerGroupContributionsDailyQuery } from '@/redux/features/revenue/revenue.api';
import { startLoading } from '@/redux/features/auth/auth.slice';
import { Skeleton } from '@/components/ui/skeleton';

const ChargerGroupContributionsCard = () => {
	const { orgId } = useAppSelector((state) => state.auth);
	const dispatch = useAppDispatch();
	const {
		data: { contributions },
		loading,
	} = useAppSelector((state) => state.revenue);
	const { data, isFetching, isError } =
		useGetChargerGroupContributionsDailyQuery(orgId);

	useEffect(() => {
		if (isFetching) {
			dispatch(startLoading());
		} else if (data) {
			dispatch(
				setContributions({
					name: 'Average Weekly Sessions',
					data: data?.data?.daily_revenue_contribution.map(
						(item: { total_revenue: number; charger_group_name: string }) => ({
							y: String(item.total_revenue),
							x: item.charger_group_name,
						})
					),
				})
			);
		}
	}, [dispatch, data, isFetching]);

	return (
		<div className="backdrop-blur-xl  bg-gradient-linear relative p-6 rounded-xl flex flex-col items-center gap-7 w-full h-full">
			{loading ? (
				<div className=" flex items-start gap-3 flex-col w-full">
					<div className="text-[#D1D1D1] font-extralight w-full space-y-2 flex justify-between items-center">
						<Skeleton className="h-5 w-[160px] " />
					</div>
					<div className="w-full">
						<Skeleton className="  h-[360px] rounded-lg w-full" />
					</div>
				</div>
			) : (
				<>
					<div className="text-[#D1D1D1] font-extralight w-full">
						Charger Group Contributions
					</div>
					<div className="flex flex-1 w-full 2xl:flex-row flex-col">
						<div className="min-h-[300px] flex-1 w-full md:min-w-[300px] relative">
							{contributions && (
								<BarChart
									chartData={[contributions]}
									chartOptions={chargerGroupsOptions}
								/>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ChargerGroupContributionsCard;
