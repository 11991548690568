import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { DetailsCard } from './details-card';
import { GroupChargerTable } from './group-details-chargers/group-charger-tables';
import { RecentFeedsContainer } from '../charger-details/connector-table/recent-feeds/recent-feeds-container';
import { TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { useEffect, useState } from 'react';
import { GroupType } from '../../types';
import { X } from 'lucide-react';
import { getChargerPointsById } from '@/services/chargers';

type Props = {
	children?: React.ReactNode;
	data: GroupType | undefined;
	loading: boolean;
	setSelectedGroupId: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
};

export const GroupDetails = ({
	data,
	children,
	loading,
	setSelectedGroupId,
}: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(true);

	return (
		<>
			<Dialog modal open={isModalOpen}>
				<DialogContent className="border-0 shadow-2xl bg-background p-4 text-white max-w-2xl max-h-xl overflow-scroll ">
					<DialogHeader>
						<DialogTitle className="flex justify-between items-center">
							<h2>Group Details {data && 'for ' + data.name}</h2>
							<Button
								onClick={() => {
									setIsModalOpen(false);
									setSelectedGroupId(null);
								}}
								variant="secondary"
								type="button"
							>
								<X />
							</Button>
						</DialogTitle>

						{loading ? (
							<div>Loading</div>
						) : (
							<div className="flex gap-4 ">
								{data && <DetailsCard groupData={data} />}
								{/* <RecentFeedsContainer data={data.recentActivity} /> */}

								<div>
									{/* <GroupChargerTable chargers={data.chargers} /> */}
								</div>
							</div>
						)}
					</DialogHeader>
				</DialogContent>
			</Dialog>
		</>
	);
};
