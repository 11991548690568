import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const NotFound = () => {
	return (
		<div className="container h-full flex text-white flex-col justify-center items-center gap-4">
			<div>
				<h2 className="text-6xl font-bold text-center my-6">OOps!</h2>
				<p className="text-center">You are lost.</p>
			</div>
			<div className="flex items-center justify-center w-full">
				<img src="/images/--not-found.png" />
			</div>
			<div className="flex items-center justify-center flex-col gap-4">
				<p>Hey Gridflow User! Looks like you are on the wrong page!</p>
				<Link
					to="/"
					className="border-b rounded-none bg-transparent flex px-4 py-2 gap-2"
				>
					<ArrowUturnLeftIcon className="h-4 w-8" />
					Go back home
				</Link>
			</div>
		</div>
	);
};

export default NotFound;
