import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ViewOptions } from './view-options';

import { priorities, statuses } from '../../data/data';
import { Filter } from './filter';

interface ToolbarProps<TData> {
	table: Table<TData>;
}

export function Toolbar<TData>({ table }: ToolbarProps<TData>) {
	const isFiltered = table.getState().columnFilters.length > 0;

	return (
		<div className="flex items-center justify-between">
			<div className="flex flex-1 items-center space-x-2">
				<Input
					placeholder="Filter tariff plans..."
					value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
					onChange={(event) =>
						table.getColumn('name')?.setFilterValue(event.target.value)
					}
					className="h-8 w-[150px] lg:w-[250px]"
				/>
				{/* {table.getColumn('currency') && (
					<Filter
						column={table.getColumn('status')}
						title="Currency"
						options={statuses}
					/>
				)}
				{table.getColumn('priority') && (
					<Filter
						column={table.getColumn('priority')}
						title="Priority"
						options={priorities}
					/>
				)} */}
				{isFiltered && (
					<Button
						variant="ghost"
						onClick={() => table.resetColumnFilters()}
						className="h-8 px-2 lg:px-3"
					>
						Reset
						<Cross2Icon className="ml-2 h-4 w-4" />
					</Button>
				)}
			</div>
			<ViewOptions />
		</div>
	);
}
