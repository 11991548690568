import React from 'react';
import { Navbar } from '@/components/navbar';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { organizationSettings, personalSettings } from './data/settings';

import { CreateOrganisation } from './components/create-organisation-modal/create-organisation';

// Define a type for the settings

const Settings = () => {
	const { tab: activeTabFromUrl } = useParams<{ tab?: string }>();
	const [activeTab, setActiveTab] = useState<number>(0);
	const [organisation, setOrganisation] = useState<boolean>(false);

	useEffect(() => {
		if (activeTabFromUrl) {
			const tabIndex = personalSettings.findIndex(
				(setting) =>
					setting.name.toLowerCase() === activeTabFromUrl.toLowerCase()
			);
			if (tabIndex !== -1) {
				setActiveTab(tabIndex);
			}
		}
	}, [activeTabFromUrl]);

	return (
		<div
			style={{
				backgroundImage: 'url(/images/--gf-dashboard-cover.png)',
				backgroundSize: '100% 270px',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className="bg-cover md:px-9 px-4  md:pt-9 pt-4 flex flex-col 2xl:gap-11 gap-5 mb-10 container">
				<Navbar title="Settings" />
				<div className="container h-full">
					<div className="w-full h-full py-4 my-4 space-x-4 flex ">
						<div className="w-full  bg-background border-[.1px] border-gray-600 flex-[.3] rounded-2xl flex py-4 flex-col h-[619px]">
							<div className="text-white text-xs uppercase mb-2 ml-4">
								Personal Settings
							</div>
							{personalSettings.map(
								({ name, description, icon: Icon }, index) => (
									<div
										key={index}
										className={` bg-opacity-25 active:bg-orange-900/75 duration-150 relative py-4 ${
											activeTab === index
												? 'bg-orange-600/25'
												: 'hover:bg-orange-400/25'
										}`}
										onClick={() => setActiveTab(index)}
									>
										<div className="py-2 flex items-start space-x-3 max-w-[340px] cursor-pointer mx-4 duration-200">
											<span className="py-1">
												<Icon />
											</span>
											<div className="flex gap-1 flex-col">
												<h3 className="text-sm text-white font-medium">
													{name}
												</h3>
												<p className="text-[10px] font-normal">{description}</p>
											</div>
											{activeTab === index && (
												<span className="py-4 px-[1.5px] rounded-l-xl bg-red-600 absolute right-0 top-1/2 transform -translate-y-1/2" />
											)}
										</div>
									</div>
								)
							)}
							<div className="text-white text-xs uppercase mb-2 ml-4 mt-4">
								Organization Settings
							</div>

							{organizationSettings.map(
								({ name, description, icon: Icon }, index) => (
									<div
										key={index + personalSettings.length}
										className={` bg-opacity-25 active:bg-orange-900/75 duration-150 relative py-4 ${
											activeTab === index + personalSettings.length
												? 'bg-orange-600/25'
												: 'hover:bg-orange-400/25'
										}`}
										onClick={() =>
											setActiveTab(index + personalSettings.length)
										}
									>
										<div className="py-2 flex items-start space-x-3 max-w-[340px] cursor-pointer mx-4 duration-200">
											<span className="py-1">
												<Icon />
											</span>
											<div className="flex gap-1 flex-col">
												<h3 className="text-sm text-white font-medium">
													{name}
												</h3>
												<p className="text-[10px] font-normal">{description}</p>
											</div>
											{activeTab === index + personalSettings.length && (
												<span className="py-4 px-[1.5px] rounded-l-xl bg-red-600 absolute right-0 top-1/2 transform -translate-y-1/2" />
											)}
										</div>
									</div>
								)
							)}

							<CreateOrganisation />
						</div>
						<div className="w-full bg-background flex-1 rounded-2xl border-[.1px] border-gray-600 p-4 overflow-scroll h-[619px]">
							{activeTab < personalSettings.length
								? React.createElement(personalSettings[activeTab].component)
								: React.createElement(
										organizationSettings[activeTab - personalSettings.length]
											.component
									)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Settings;
