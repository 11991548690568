import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { AddChargePoint } from './add-charge-points';
import { Cross } from 'lucide-react';

interface ChargerTableToolbarProps<TData> {
	table: Table<TData>;
}

export function ChargerTableToolbar<TData>({
	table,
}: ChargerTableToolbarProps<TData>) {
	const isFiltered = table.getState().columnFilters.length > 0;

	return (
		<div className="flex items-center justify-between">
			<div className="flex flex-1 items-center space-x-2">
				{isFiltered && (
					<Button
						variant="ghost"
						onClick={() => table.resetColumnFilters()}
						className="h-8 px-2 lg:px-3"
					>
						Reset
						<Cross2Icon className="ml-2 h-4 w-4" />
					</Button>
				)}
			</div>
			<AddChargePoint>
				<Button
					variant="outline"
					size="sm"
					className="ml-auto hidden h-8 lg:flex"
				>
					<Cross className="mr-2 h-4 w-4" />
					Add Chargers
				</Button>
			</AddChargePoint>
		</div>
	);
}
