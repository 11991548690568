import { donutChartOptions } from '@/common/variables/charts';
import { getTimeframeUsage } from '@/services/revenue';
import { useState, useEffect } from 'react';
import PieChart from '@/components/ui/pie-chart';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	SelectGroup,
} from '@/components/ui/select';
import { useGetTimeFrameUtilizationQuery } from '@/redux/features/home/home.api';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { setTimeframe, startLoading } from '@/redux/features/home/home.slice';
import { Skeleton } from '@/components/ui/skeleton';

export const UtilizationRatio = () => {
	const { orgId } = useAppSelector((state) => state.auth);
	const { timeframe, loading } = useAppSelector((state) => state.home);
	const dispatch = useAppDispatch();

	const [date, setDate] = useState(30);

	const { data, isLoading } = useGetTimeFrameUtilizationQuery(
		{
			org_id: orgId,
			timeframe: date,
		},
		{
			skip: !orgId,
		}
	);

	const handleChangeTimeframe = (newValue: any) => {
		setDate(newValue);
	};
	useEffect(() => {
		if (isLoading) {
			dispatch(startLoading());
		} else if (data) {
			dispatch(setTimeframe(data));
		}
	}, [timeframe, data, isLoading]);

	return (
		<div className="backdrop-blur-xl  bg-gradient-linear relative p-6 rounded-xl flex flex-col items-center gap-7 w-full">
			{loading ? (
				<div className=" flex items-center justify-center gap-3 flex-col w-full">
					<div className="text-[#D1D1D1] font-extralight w-full space-y-2 flex justify-between items-center">
						<Skeleton className="h-5 w-[100px] " />
						<Skeleton className="h-10 w-[150px] " />
					</div>
					<div>
						<Skeleton className="  h-[325px] rounded-full w-[325px]" />
					</div>
				</div>
			) : (
				<>
					<div className=" flex justify-between w-full items-center">
						<div className="text-[#D1D1D1] font-extralight">
							Utilization Ratio
						</div>
						<Select onValueChange={handleChangeTimeframe} defaultValue="30">
							<SelectTrigger className="w-[180px]">
								<SelectValue />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									<SelectItem value="30">Monthly</SelectItem>
									<SelectItem value="7">Weekly</SelectItem>
									<SelectItem value="1">Daily</SelectItem>
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
					<div className="min-h-[325px]">
						<PieChart
							type="donut"
							chartData={
								timeframe
									? [
											timeframe.utilization_rate * 100,
											100 - timeframe.utilization_rate * 100,
										]
									: []
							}
							chartOptions={donutChartOptions(['active', 'idle'])}
						/>
					</div>
				</>
			)}
		</div>
	);
};
