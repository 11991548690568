import { BsCircleFill } from 'react-icons/bs';

export const statuses = [
	{
		value: 'Completed',
		label: 'Completed',
		icon: BsCircleFill,
	},
	{
		value: 'Ongoing',
		label: 'Ongoing',
		icon: BsCircleFill,
	},
	{
		value: 'Failed',
		label: 'Failed',
		icon: BsCircleFill,
	},
];

export const contributions = [
	{
		category: 'HK1',
		value: 30.53,
		chargers: 13,
		revenue: 10000,
	},
	{
		category: 'RK2',
		value: 40.53,
		chargers: 13,
		revenue: 20000,
	},
	{
		category: 'HK1',
		value: 50.53,
		chargers: 13,
		revenue: 30000,
	},
	{
		category: 'HK1',
		value: 60.53,
		chargers: 13,
		revenue: 50000,
	},
	{
		category: 'HK1',
		value: 70.53,
		chargers: 13,
		revenue: 10000,
	},
	{
		category: 'HK1',
		value: 80.53,
		chargers: 13,
		revenue: 15000,
	},
	{
		category: 'HK1',
		value: 90.53,
		chargers: 13,
		revenue: 60000,
	},
];
