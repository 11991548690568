import cookieService from '@/lib/helpers/cookie-service';
import { useAppNavigate } from '@/lib/hooks/use-app-navigate';
import { useAuth } from '@/lib/hooks/use-auth';
import { getUserProfile } from '@/services/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Auth = ({ children }: { children: React.ReactNode }) => {
	const { authenticated } = useAuth();
	const navigate = useNavigate();

	if (authenticated) {
		navigate('/');
		return;
	}
	return (
		!authenticated && (
			<div className="h-full w-full bg-gradient-auth-bg bg-black">
				{children}
			</div>
		)
	);
};
