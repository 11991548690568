import * as z from 'zod';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { EyeIcon, EyeOff, Lock } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useChangePasswordMutation } from '@/redux/features/auth/auth.api';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form';
import { useState } from 'react';
import { useAuth } from '@/lib/hooks/use-auth';
import { toast } from 'sonner';

type Props = {};

const ChangePasswordFormSchema = z
	.object({
		old_password: z.string().min(1, { message: 'Old cannot be empty' }),
		new_password: z
			.string()
			.min(1, { message: 'Please enter a password' })
			.min(8, { message: 'Passwords must be at least 8 characters long' })
			.refine(
				(value) => /[a-z]/.test(value),
				'Password must contain at least one lowercase letter'
			)
			.refine(
				(value) => /[A-Z]/.test(value),
				'Password must contain at least one uppercase letter'
			)
			.refine(
				(value) => /\d/.test(value),
				'Password must contain at least one number'
			)
			.refine(
				(value) => /[@$!%*?&]/.test(value),
				'Password must contain at least one special character'
			),
		confirm_new_password: z
			.string()
			.min(1, { message: 'Please confirm password' }),
	})
	.refine((data) => data.new_password === data.confirm_new_password, {
		message: "Passwords don't match",
		path: ['confirm_new_password'],
	});

export const ChangePassword = (props: Props) => {
	const [changePasswordMutation, { isLoading, isError, isSuccess, error }] =
		useChangePasswordMutation();

	const [passwordType, setPasswordType] = useState(true);
	const EyeComponent = passwordType ? EyeIcon : EyeOff;

	const changePasswordForm = useForm<z.infer<typeof ChangePasswordFormSchema>>({
		resolver: zodResolver(ChangePasswordFormSchema),
	});

	const handleSubmit = async (values: {
		old_password: string;
		new_password: string;
		confirm_new_password: string;
	}) => {
		const result = {
			old_password: values.old_password,
			new_password: values.new_password,
		};

		try {
			const changePasswordResult =
				await changePasswordMutation(result).unwrap();
			toast.success(changePasswordResult.message);
		} catch (error: any) {
			toast.error(error.data.error_message);
		}
	};

	return (
		<div className="py-10">
			<div className="flex items-center gap-4">
				<Lock color="white" size={24} />
				<h3 className="text-2xl font-semibold text-white">Change Password</h3>
			</div>
			<hr className="my-2 border-[.2px] border-slate-600 mt-6" />
			<Form {...changePasswordForm}>
				<form
					className="my-4 flex flex-col gap-6"
					onSubmit={changePasswordForm.handleSubmit(handleSubmit)}
				>
					<FormField
						control={changePasswordForm.control}
						name="old_password"
						render={({ field }) => (
							<FormItem className="w-full">
								<FormLabel>Old password</FormLabel>
								<FormControl>
									<div className="relative transition-all duration-150 group">
										<Input
											type={passwordType ? 'password' : 'text'}
											placeholder="Old password"
											className="outline-0 bg-transparent bg-opacity-40 rounded-lg border-opacity-10 border-[.3px]"
											{...field}
										/>

										<EyeComponent
											className="absolute z-10 right-2 bottom-3 cursor-pointer hidden group-hover:block"
											size={16}
											onClick={() => {
												setPasswordType((prev) => !prev);
											}}
										/>
									</div>
								</FormControl>
								<FormMessage className=" text-[10px]" />
							</FormItem>
						)}
					/>

					<FormField
						control={changePasswordForm.control}
						name="new_password"
						render={({ field }) => (
							<FormItem className="w-full">
								<FormLabel>New password</FormLabel>
								<FormControl>
									<div className="relative transition-all duration-150 group">
										<Input
											type={passwordType ? 'password' : 'text'}
											placeholder="Old password"
											className="outline-0 bg-transparent bg-opacity-40 rounded-lg border-opacity-10 border-[.3px]"
											{...field}
										/>

										<EyeComponent
											className="absolute z-10 right-2 bottom-3 cursor-pointer hidden group-hover:block"
											size={16}
											onClick={() => {
												setPasswordType((prev) => !prev);
											}}
										/>
									</div>
								</FormControl>
								<FormMessage className=" text-[10px]" />
							</FormItem>
						)}
					/>

					<FormField
						control={changePasswordForm.control}
						name="confirm_new_password"
						render={({ field }) => (
							<FormItem className="w-full">
								<FormLabel>Confirm New password</FormLabel>
								<FormControl>
									<div className="relative transition-all duration-150 group">
										<Input
											type={passwordType ? 'password' : 'text'}
											placeholder="Old password"
											className="outline-0 bg-transparent bg-opacity-40 rounded-lg border-opacity-10 border-[.3px]"
											{...field}
										/>

										<EyeComponent
											className="absolute z-10 right-2 bottom-3 cursor-pointer hidden group-hover:block"
											size={16}
											onClick={() => {
												setPasswordType((prev) => !prev);
											}}
										/>
									</div>
								</FormControl>
								<FormMessage className=" text-[10px]" />
							</FormItem>
						)}
					/>

					<Button disabled={isLoading} className="bg-black w-max">
						Update Account
					</Button>
				</form>
			</Form>
		</div>
	);
};
