import { Row } from '@tanstack/react-table';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { AddPricePlanModal } from '../tariff-plans/add-price-plan-modal';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { useDeleteTariffPlansMutation } from '@/redux/features/tariff/tariff.api';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { TariffPlanType } from '../../types';
import { EditPricePlanModal } from '../tariff-plans/edit-price-plan-modal';

interface ActionsProps<TData> {
	row: Row<TData>;
}

export function Actions<TData extends TariffPlanType>({
	row,
}: ActionsProps<TData>) {
	const [deleteTariffPlansMutation, { isLoading }] =
		useDeleteTariffPlansMutation();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleDelete = async (id: string) => {
		deleteTariffPlansMutation(id)
			.unwrap()
			.then(() => {
				toast.success('Tariff Plan deleted successfully');
				setTimeout(() => {
					setIsModalOpen(false);
				}, 500);
			});
	};
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="ghost"
					className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
				>
					<BsThreeDots className="h-4 w-4" />
					<span className="sr-only">Open menu</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[160px]">
				<div className="">
					<EditPricePlanModal data={row.original}>
						<div className="w-full hover:bg-slate-600 rounded-sm px-2 py-1 cursor-pointer">
							Edit
						</div>
					</EditPricePlanModal>
				</div>
				<DropdownMenuSeparator />

				<DropdownMenuItem onClick={() => setIsModalOpen(true)}>
					Delete
					<DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
				</DropdownMenuItem>
			</DropdownMenuContent>

			<Dialog
				modal
				open={isModalOpen}
				onOpenChange={() => setIsModalOpen(true)}
			>
				<DialogContent className="max-w-md p-4">
					<DialogHeader>
						<DialogTitle className="text-center leading-8 whitespace-nowrap text-sm">
							Are you absolutely sure you want to remove this tariff plan?
						</DialogTitle>
						<DialogDescription className="text-center text-red-400">
							This action cannot be undone.
						</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<Button
							size="sm"
							variant="outline"
							onClick={() => setIsModalOpen(false)}
						>
							Cancel
						</Button>
						<Button
							size="sm"
							variant="destructive"
							onClick={() => handleDelete(row.original?.id)}
							disabled={isLoading}
						>
							Delete
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</DropdownMenu>
	);
}
