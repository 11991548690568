import { BellIcon, LogOut } from 'lucide-react';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';
import { useAppNavigate } from '@/lib/hooks/use-app-navigate';

export const OrganisationNavbar = () => {
	const navigate = useNavigate();
	return (
		<div className="border-b-[.3px] border-slate-500 p-4">
			<div className="container">
				<div className="flex justify-between items-center gap-4">
					<img
						src="/images/organisation-navbar-1.png"
						height={20}
						width={170}
					/>{' '}
					<div className="flex justify-between items-center flex-[.15]">
						<BellIcon color="#ccc" />
						<Button
							variant="destructive"
							className="flex gap-3"
							onClick={() => {
								navigate('/login');
							}}
						>
							Logout <LogOut size={16} />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
