import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { EyeIcon, EyeOff } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '@/lib/hooks/use-auth';
import { useResetPasswordMutation } from '@/redux/features/auth/auth.api';
import { toast } from 'sonner';

type Props = {};
const createNewPasswordSchema = z
	.object({
		password: z
			.string()
			.min(1, { message: 'Please enter a password' })
			.min(8, { message: 'Passwords must be at least 8 characters long' })
			.refine(
				(value) => /[a-z]/.test(value),
				'Password must contain at least one lowercase letter'
			)
			.refine(
				(value) => /[A-Z]/.test(value),
				'Password must contain at least one uppercase letter'
			)
			.refine(
				(value) => /\d/.test(value),
				'Password must contain at least one number'
			)
			.refine(
				(value) => /[@$!%*?&]/.test(value),
				'Password must contain at least one special character'
			),
		confirmPassword: z.string().min(1, { message: 'Please confirm password' }),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: ['confirmPassword'],
	});

const CreateNewPassword = (props: Props) => {
	const [passwordType, setPasswordType] = useState(true);
	const EyeComponent = passwordType ? EyeIcon : EyeOff;

	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const resetToken = searchParams.get('reset_token') as string;
	const email = searchParams.get('referrer') as string;

	useEffect(() => {
		if (!resetToken || !email) {
			navigate('/login');
		}
	}, []);

	const createNewPasswordForm = useForm<
		z.infer<typeof createNewPasswordSchema>
	>({
		resolver: zodResolver(createNewPasswordSchema),
	});

	const [resetPassword, { isLoading, error, data }] =
		useResetPasswordMutation();

	const handleSubmit = async (values: { password: string }) => {
		const info = {
			email: email,
			reset_token: resetToken,
			new_password: values.password,
		};

		try {
			const response = await resetPassword(info)
				.unwrap()
				.then((data) => {
					if (data.response_status == 'success') {
						createNewPasswordForm.reset();
						toast.success(data.message);
						navigate('/login');
					}
				});
		} catch (error: any) {
			if (error) {
				toast.error(error?.data.error_message);
			}
		}
	};

	return (
		<div className="flex items-center justify-center h-full bg-black">
			<div className="max-w-[600px] w-full space-y-6">
				<div className="space-y-2">
					<div className="flex items-center flex-col justify-center gap-3 my-3">
						<img src="/images/logo-text.png" height={50} width={150} />
					</div>
					<div className="flex gap-2 items-center">
						<div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
						<span className="text-white text-sm font-light w-max mx-2 whitespace-nowrap text-center ">
							Create new password
						</span>
						<div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
					</div>

					<p className="text-slate-200 font-light text-xs max-w-[350px] text-center mx-auto">
						Enter your registered email address below & we will send an email
						with instructions to reset your password.
					</p>
				</div>

				<Form {...createNewPasswordForm}>
					<form
						onSubmit={createNewPasswordForm.handleSubmit(handleSubmit)}
						className="flex flex-col gap-6 w-full"
					>
						<div className="space-y-3">
							<FormField
								name="password"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormControl>
											<div className="relative transition-all duration-150">
												<Input
													type={passwordType ? 'password' : 'text'}
													placeholder="Password"
													className=""
													{...field}
												/>
												<EyeComponent
													className="absolute z-10 right-2 bottom-3 cursor-pointer"
													size={16}
													onClick={() => {
														setPasswordType((prev) => !prev);
													}}
												/>
											</div>
										</FormControl>
										<FormMessage className="text-[10px]" />
									</FormItem>
								)}
							/>
							<FormField
								name="confirmPassword"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormControl>
											<div className="relative transition-all duration-150">
												<Input
													type={passwordType ? 'password' : 'text'}
													placeholder="Confirm Password"
													className=""
													{...field}
												/>
												<EyeComponent
													className="absolute z-10 right-2 bottom-3 cursor-pointer"
													size={16}
													onClick={() => {
														setPasswordType((prev) => !prev);
													}}
												/>
											</div>
										</FormControl>
										<FormMessage className="text-[10px]" />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex flex-col items-center gap-4 w-full">
							<Button
								disabled={isLoading}
								className="bg-[#eab308] text-black hover:bg-[#eab308]/60 w-full md:w-[60%] "
							>
								Submit
							</Button>
						</div>
					</form>
				</Form>
			</div>
		</div>
	);
};

export default CreateNewPassword;
