'use client';

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form';
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from '@/components/ui/input-otp';
import {
	useLazyResendVerificationQuery,
	useVerifyOtpMutation,
} from '@/redux/features/auth/auth.api';
import { useAppDispatch } from '@/redux/hooks';
import { toast } from 'sonner';

type Props = {};

const FormSchema = z.object({
	pin: z.string().min(6, {
		message: 'Your one-time password must be 6 characters.',
	}),
});

export const VerifyEmail = (props: Props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const email = queryParams.get('email') || '';

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			pin: '',
		},
	});

	const [otpVerification, { isLoading: otpVerificationIsLoading }] =
		useVerifyOtpMutation();

	const [resendVerification, { isLoading: resendVerificationIsLoading }] =
		useLazyResendVerificationQuery();

	const [secondsRemaining, setSecondsRemaining] = useState(60);
	const [isResendDisabled, setIsResendDisabled] = useState(true);
	const [loading, setLoading] = useState(false);

	function onSubmit(data: z.infer<typeof FormSchema>) {
		otpVerification({ otp_token: data.pin, email })
			.unwrap()
			.then((data) => {
				if (data.response_status === 'success') {
					toast.success(data.message);
					navigate(`/login`);
				}
			})
			.catch((error: any) => {
				toast.error(error?.data?.error_message);
				const serverErrorMessage = error?.data?.error_message;
				const message = serverErrorMessage || 'An unknown error occurred';
			});
	}

	function handleResendCode() {
		resendVerification({ email: email }).then(({ data }) => {
			if (data.response_status === 'success') {
				setSecondsRemaining(60);
				setIsResendDisabled(true);
				form.reset();
				toast.success(data.message);
			}
		});
	}

	useEffect(() => {
		if (secondsRemaining > 0) {
			const timerId = setTimeout(() => {
				setSecondsRemaining(secondsRemaining - 1);
			}, 1000);
			return () => clearTimeout(timerId);
		} else {
			setIsResendDisabled(false);
		}
	}, [secondsRemaining]);

	useEffect(() => {
		if (!email) {
			navigate('/login');
		}
	}, [email, navigate]);

	return (
		<div className="flex items-center justify-center h-full bg-black">
			<div className="flex relative items-center justify-center h-full">
				<div className="flex-[.4] flex flex-col gap-4 p-5  ">
					<div>
						<div className="flex items-center flex-col justify-center gap-3 my-3">
							<img src="/images/logo-text.png" height={50} width={150} />
						</div>
						<div className="flex gap-2 items-center">
							<div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
							<span className="text-white text-sm font-light w-max mx-4 whitespace-nowrap text-center ">
								Enter OTP
							</span>
							<div className="w-full bg-white opacity-60 h-[.4px] rounded-full" />
						</div>
					</div>
					<p className="text-slate-200 font-light text-xs text-center">
						Check your email for the OTP and enter it below.
					</p>

					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-6">
							<FormField
								control={form.control}
								name="pin"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<InputOTP
												onComplete={form.handleSubmit(onSubmit)}
												maxLength={6}
												disabled={
													resendVerificationIsLoading ||
													otpVerificationIsLoading
												}
												{...field}
											>
												<InputOTPGroup>
													<InputOTPSlot
														className="h-16 w-16 text-5xl"
														index={0}
													/>
													<InputOTPSlot
														className="h-16 w-16  text-5xl"
														index={1}
													/>
													<InputOTPSlot
														className="h-16 w-16  text-5xl"
														index={2}
													/>
													<InputOTPSlot
														className="h-16 w-16  text-5xl"
														index={3}
													/>
													<InputOTPSlot
														className="h-16 w-16 text-5xl"
														index={4}
													/>
													<InputOTPSlot
														className="h-16 w-16 text-5xl"
														index={5}
													/>
												</InputOTPGroup>
											</InputOTP>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<div className="space-y-4 w-full flex flex-col items-center  ">
								<div className="text-center w-full">
									{secondsRemaining > 0 ? (
										<span>
											Resend code in{' '}
											<span className="font-bold text-yellow-500">
												{`00:${
													secondsRemaining < 10 ? '0' : ''
												}${secondsRemaining}`}
											</span>
										</span>
									) : (
										<Button
											type="button"
											variant="outline"
											className="w-full max-w-[60%] mx-auto border-[#eab308] border-[.1px] "
											onClick={() => handleResendCode()}
											disabled={
												isResendDisabled ||
												resendVerificationIsLoading ||
												otpVerificationIsLoading
											}
										>
											Resend Code
										</Button>
									)}
								</div>

								<Button
									disabled={
										resendVerificationIsLoading || otpVerificationIsLoading
									}
									className="bg-[#eab308] text-black hover:bg-[#eab308]/60 max-w-[60%] mx-auto w-full "
								>
									Verify
								</Button>
							</div>
						</form>
					</Form>
				</div>
			</div>
		</div>
	);
};
