import { Button } from "@/components/ui/button";
import React, { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { setShowInstructions } from "@/redux/features/charger-network/charger-network.slice";
import { X } from "react-feather";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

const Step1 = () => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold mb-4">
      Step 1: Retrieve WebSocket Endpoint
    </h2>
    <p className="mb-4 text-sm">
      After registering your charger, you will see a WebSocket endpoint
      displayed. This endpoint follows the format:
    </p>
    <p className="bg-gray-800 text-green-400 p-4 rounded-lg mb-6 text-xs">
      wss://core.grdflo.com/ocpp/1.6/central_system/&lt;charge_point_id&gt;
    </p>
    <p className="mb-6 text-sm">
      &lt;charge_point_id&gt;: This is a unique identifier for your charge point
      that you added during registration. Ensure you replace this placeholder
      with your actual charge point ID.
    </p>
  </div>
);

const Step2 = () => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold mb-4">
      Step 2: Configure WebSocket Connection on Your Charge Point
    </h2>
    <ol className="list-decimal ml-6 mb-6">
      <li className="mb-4 text-sm">
        <p className="font-semibold text-sm ">
          Access the charge point’s configuration interface:
        </p>
        <ul className="list-disc ml-6 text-sm">
          <li>
            This can usually be done via the manufacturer’s software or a web
            interface.
          </li>
          <li>
            Refer to your charge point’s manual for specific instructions on
            accessing its settings.
          </li>
        </ul>
      </li>
      <li className="mb-4 text-sm">
        <p className="font-semibold">Enter the WebSocket endpoint:</p>
        <ul className="list-disc ml-6">
          <li>
            Locate the section where you can input the WebSocket URL for the
            central system.
          </li>
          <li>
            Input the URL provided above, ensuring the correct charge point ID
            is included.
          </li>
        </ul>
      </li>
      <li className="mb-4 text-sm">
        <p className="font-semibold">Save the settings:</p>
        <ul className="list-disc ml-6">
          <li>Save the changes and restart your charge point if necessary.</li>
        </ul>
      </li>
    </ol>
  </div>
);

const Step3 = () => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold mb-4">
      Step 3: Initial Connection and Boot Notification
    </h2>
    <p className="mb-4 text-sm">
      Once the WebSocket connection is configured, the charge point should
      attempt to connect to our central system. Upon successful connection, it
      should send a Boot Notification message. Verify the following:
    </p>
    <ul className="list-disc ml-6 mb-6 text-sm">
      <li>
        <p className="font-semibold">Boot Notification Sent:</p>
        <ul className="list-disc ml-6">
          <li>
            Ensure the charge point sends a Boot Notification upon startup. This
            message includes details about the charge point and confirms the
            connection to the central system.
          </li>
        </ul>
      </li>
    </ul>
  </div>
);

const Step4 = () => (
  <div className="mb-8">
    <h2 className="text-xl font-semibold mb-4">
      Step 4: Heartbeat and Status Notifications
    </h2>
    <p className="mb-4 text-sm">
      To ensure ongoing communication and monitoring, your charge point will
      need to regularly send heartbeat and status notifications.
    </p>
    <ul className="list-disc ml-6 mb-6 text-sm">
      <li>
        <p className="font-semibold">Heartbeat Interval:</p>
        <ul className="list-disc ml-6">
          <li>
            Verify that the charge point sends regular heartbeat messages. This
            interval is typically configurable in the charge point settings.
          </li>
        </ul>
      </li>
      <li>
        <p className="font-semibold">Status Notifications:</p>
        <ul className="list-disc ml-6">
          <li>
            The charge point should send status notifications to report its
            current state (e.g., Available, SuspendedEVSE, Unavailable, etc.).
          </li>
          <li>
            Monitor these notifications to ensure they are received correctly by
            the central system.
          </li>
        </ul>
      </li>
    </ul>

    <p className="mb-6 text-sm pb-24">
      By following these steps, your charge point should be successfully
      connected to the GridFlow central system, ensuring it is ready for full
      functionality and proper monitoring. If you encounter any issues, please
      refer to your charge point’s manual or contact our support team at{" "}
      <a href="mailto:support@core.grdflo.com" className="text-blue-400">
        support@core.grdflo.com
      </a>{" "}
      for further assistance.
    </p>
  </div>
);

export const ChargePointSetupInstructions = () => {
  const dispatch = useAppDispatch();
  const { showInstructions } = useAppSelector((state) => state.chargerNetwork);
  const [steps, setSteps] = useState(1);

  return (
    <Dialog modal open={showInstructions}>
      <DialogContent className="bg-background  text-white rounded-lg shadow-lg max-w-3xl ">
        <div className="relative">
          <div className="max-h-[500px] overflow-auto pb-16">
            <Button
              onClick={() => {
                dispatch(setShowInstructions(false));
              }}
              variant={"secondary"}
              className="absolute right-2 top-2 p-2  h-auto"
            >
              <X className="h-4 w-4" />
            </Button>
            <div className="max-h-[500px] p-6 ">
              <h1 className="text-2xl font-bold mb-6 ">
                Instructions for Connecting Your Charger to GridFlow Central
                System
              </h1>
              <p className="mb-6 text-sm ">
                Welcome to GridFlow core charging station management system! To
                ensure your charger is properly connected and functional, please
                follow the detailed steps below after registering your charger
                in our server. These steps will guide you through setting up the
                WebSocket connection to our central system and testing
                compatibility with key functionalities of OCPP version 1.6.
              </p>
              {steps === 1 && <Step1 />}
              {steps === 2 && <Step2 />}
              {steps === 3 && <Step3 />}
              {steps === 4 && <Step4 />}
            </div>
          </div>
          <div className="fixed bottom-0 right-0 px-8 py-4 flex gap-4 justify-end bg-background w-full">
            <Button
              variant={"outline"}
              disabled={steps === 1}
              onClick={() => {
                setSteps((prev) => prev - 1);
              }}
            >
              Back
            </Button>
            <Button
              variant={"secondary"}
              onClick={() => {
                if (steps === 4) {
                  dispatch(setShowInstructions(false));
                } else {
                  setSteps((prev) => prev + 1);
                }
              }}
            >
              {steps === 4 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
