import { resetGlobalChargerGroupsPage } from './charger-network.slice';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/lib/helpers/redux-base-query';

export const chargerNetworkApi = createApi({
	reducerPath: 'chargerNetworkApi',
	baseQuery,
	tagTypes: ['ChargePoints', 'ChargeGroups'],
	endpoints: (builder) => ({
		getChargePoints: builder.query({
			query: ({ orgId, page = 1 }) =>
				`core_backend/chargepoints/${orgId}?page=${page}`,
			providesTags: ['ChargePoints'],
		}),
		addChargePoint: builder.mutation({
			query: (values) => ({
				url: `core_backend/new-chargepoint/`,
				method: 'POST',
				body: values,
			}),
			invalidatesTags: ['ChargePoints'],
		}),
		updateChargePoint: builder.mutation({
			query: ({ id, ...values }) => ({
				url: `core_backend/chargepoint/edit/${id}/`,
				method: 'PATCH',
				body: values,
			}),
			invalidatesTags: ['ChargePoints'],
		}),
		deleteChargePoint: builder.mutation({
			query: (id) => ({
				url: `core_backend/chargepoint/delete/${id}/`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ChargePoints'],
		}),
		addChargerGroupToTariff: builder.mutation({
			query: ({ charger_group_id, tariff_plan_id }) => ({
				url: `core_backend/charger-groups/${charger_group_id}/`,
				method: 'PATCH',
				body: {
					tarrif_plan: tariff_plan_id,
				},
			}),
			invalidatesTags: ['ChargeGroups'],
		}),
		getChargeGroups: builder.query({
			query: ({ orgId, page = 1 }) =>
				`core_backend/charger-groups/?organization=${orgId}&page=${page}`,
			providesTags: ['ChargeGroups'],
		}),
		addChargeGroup: builder.mutation({
			query: (values) => ({
				url: `core_backend/charger-groups/`,
				method: 'POST',
				body: values,
			}),

			invalidatesTags: ['ChargeGroups'],

			// this is used to change the page of the global charger groups back to 1, so the new data can be fetched again without a problem
			async onQueryStarted(id, { dispatch, queryFulfilled }) {
				try {
					dispatch(resetGlobalChargerGroupsPage());
				} catch (error) {}
			},
		}),
		getChargersState: builder.query({
			query: ({ org_id, group_id }) =>
				`core_backend/utilization-breakdown/${org_id}${
					group_id ? `?charger_group_id=${group_id}` : ''
				}`,
		}),
		getChargersUtilizationRate: builder.query({
			query: (values) =>
				`core_backend/daily-charger-utilization/${values.org_id}${
					values.group_id ? `?charger_group=${values.group_id}` : ''
				}`,
		}),
		getChargeGroupGlobal: builder.query({
			query: (args: { orgId: any; page: any }) =>
				`core_backend/charger-groups/?organization=${args.orgId}&page=${args.page}`,
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			// Always merge incoming data to the cache entry
			merge: (currentCache, newItems) => {
				if (!newItems.previous) {
					currentCache.results = newItems.results;
					currentCache.next = newItems.next;
					currentCache.previous = newItems.previous;
					return;
				}
				currentCache.results.push(...newItems.results);
				currentCache.next = newItems.next;
				currentCache.previous = newItems.previous;
			},
			// Refetch when the page arg changes
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: ['ChargeGroups'],
		}),
		getChargepointConnectors: builder.query({
			query: (args: { orgId: any; chargepointId: any; page: any }) =>
				`core_backend/chargepoint-connectors/${args.orgId}?page=${args.page}&charge_point=${args.chargepointId}`,
		}),
	}),
});

export const {
	useGetChargePointsQuery,
	useGetChargeGroupsQuery,
	useAddChargeGroupMutation,
	useAddChargePointMutation,
	useGetChargersStateQuery,
	useGetChargersUtilizationRateQuery,
	useGetChargeGroupGlobalQuery,
	useAddChargerGroupToTariffMutation,
	useGetChargepointConnectorsQuery,
	useDeleteChargePointMutation,
	useUpdateChargePointMutation,
} = chargerNetworkApi;
