import { Input } from '@/components/ui/input';
import { Search } from 'lucide-react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';

export const FilterSection = () => {
	return (
		<div className="flex gap-2 my-4">
			<div className=" flex items-center relative flex-1">
				<Search
					color="#898989"
					size={16}
					className="absolute top-1/2 transform -translate-y-1/2 left-2"
				/>
				<Input placeholder="Filter..." className="pl-8 text-sm py-1" />
			</div>
			<Select>
				<SelectTrigger className=" flex-[.2]">
					<SelectValue placeholder="All team roles" />
				</SelectTrigger>
				<SelectContent>
					<SelectItem value="light">Member </SelectItem>
					<SelectItem value="dark">Owner</SelectItem>
				</SelectContent>
			</Select>
		</div>
	);
};
