import React, { useState, useEffect } from 'react';
import { Zap, BatteryCharging, AlertCircle, Power } from 'react-feather';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { faker } from '@faker-js/faker';

import { ChargerGroupType } from '@/types/charger-network';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
	useGetChargeGroupGlobalQuery,
	useGetChargeGroupsQuery,
	useGetChargersStateQuery,
} from '@/redux/features/charger-network/charger-network.api';
import {
	incrementGlobalChargerGroupsPage,
	setChargeGroups,
	setChargerStatus,
	startLoading,
} from '@/redux/features/charger-network/charger-network.slice';
import { CircularProgress } from '@mui/material';
import { Skeleton } from '@/components/ui/skeleton';

interface ChargerGroup {
	id: string;
	name: string;
	onlineCount: number;
	chargingCount: number;
	failureCount: number;
	offlineCount: number;
	[key: string]: number | string;
}

const ChargersStatusCard: React.FC = () => {
	const { orgId } = useAppSelector((state) => state.auth);
	const globalChargeGroupsPage = useAppSelector(
		(state) => state.chargerNetwork.globalChargerGroups.page
	);
	const dispatch = useAppDispatch();
	const { chargerGroupStatus, loading } = useAppSelector(
		(state) => state.chargerNetwork
	);

	const {
		data: groupsData,
		isFetching,
		isError,
		isLoading,
	} = useGetChargeGroupsQuery({ orgId: orgId, page: globalChargeGroupsPage });

	const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
	const {
		data: chargerStatusData,
		isFetching: chargerStatusFetching,
		isError: chargerStatusError,
		isLoading: chargerStatusIsLoading,
	} = useGetChargersStateQuery({
		org_id: orgId,
		group_id: selectedGroup,
	});
	const globalChargeGroups = useGetChargeGroupGlobalQuery({
		orgId: orgId,
		page: globalChargeGroupsPage,
	});

	const handleGroupChange = (value: string) => {
		setSelectedGroup(value);
	};

	const loadMoreData = (data: any) => {
		if (data.next) {
			dispatch(incrementGlobalChargerGroupsPage());
		}
	};

	useEffect(() => {
		if (chargerStatusIsLoading) {
			dispatch(startLoading());
		} else if (groupsData) {
			dispatch(setChargeGroups(groupsData));
		}
		if (chargerStatusIsLoading) {
			dispatch(startLoading());
		} else if (chargerStatusData) {
			dispatch(setChargerStatus(chargerStatusData.data));
		}
	}, [
		dispatch,
		isFetching,
		chargerStatusFetching,
		chargerStatusData,
		groupsData,
	]);

	return (
		<div className="backdrop-blur-xl  bg-gradient-linear relative p-4 rounded-lg shadow-2xl text-white  justify-between flex-1 space-y-3 ">
			{loading ? (
				<div className=" flex items-start gap-3 flex-col w-full">
					<div className="text-[#D1D1D1] font-extralight w-full space-y-2 flex justify-between items-center">
						<Skeleton className="h-5 w-[160px] " />
						<Skeleton className="h-10 w-[180px] " />
					</div>
					<div className="w-full grid grid-cols-2 gap-2">
						<Skeleton className="  h-[60px] rounded-lg w-full" />
						<Skeleton className="  h-[60px] rounded-lg w-full" />
						<Skeleton className="  h-[60px] rounded-lg w-full" />
						<Skeleton className="  h-[60px] rounded-lg w-full" />
					</div>
				</div>
			) : (
				chargerGroupStatus && (
					<>
						<div className="flex gap-4 items-center justify-between">
							<h2 className="text-lg ">
								Total Chargers -{' '}
								<span className="font-bold">
									{chargerGroupStatus.charger_count}
								</span>
							</h2>
							<div className="mt-2 flex-[.5]">
								<Select disabled={isLoading} onValueChange={handleGroupChange}>
									<SelectTrigger className="">
										<SelectValue
											placeholder={isLoading ? 'Loading Groups' : 'All Groups'}
										/>
									</SelectTrigger>

									<SelectContent>
										{globalChargeGroups.isLoading ? (
											<div className="flex justify-center py-2">
												<CircularProgress size={20} color="inherit" />
											</div>
										) : globalChargeGroups.data &&
										  Object.keys(globalChargeGroups.data).length !== 0 ? (
											<div>
												{globalChargeGroups.data.results.map((group: any) => (
													<SelectItem value={group.id} key={group.name}>
														{group.name}
													</SelectItem>
												))}
												{globalChargeGroups.data.results.length > 0 &&
													!globalChargeGroups.isFetching &&
													globalChargeGroups.data.next && (
														<button
															className="text-center w-full py-2 text-xs text-gray-500 hover:text-gray-300"
															onClick={() =>
																loadMoreData(globalChargeGroups.data)
															}
														>
															Load more
														</button>
													)}

												{globalChargeGroups.isFetching && (
													<div className="flex justify-center py-2">
														<CircularProgress size={20} color="inherit" />
													</div>
												)}
											</div>
										) : (
											<div>No Charger groups found</div>
										)}
									</SelectContent>
								</Select>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-2">
							<div className="space-y-3">
								<div className="flex items-center p-2 bg-[#6BEBA4] bg-opacity-20 border-[#6BEBA4] border text-[#6BEBA4] rounded-md">
									<Zap className="text-base mr-3" />
									<div>
										<p className="text-xs font-semibold">Online</p>
										<span className=" font-bold">
											{chargerGroupStatus.category_counts.online}
										</span>
									</div>
								</div>
								<div className="flex items-center p-2 bg-blue-500 bg-opacity-20 border border-blue-500 text-blue-500 rounded-md">
									<BatteryCharging className="text-sm mr-3" />
									<div>
										<p className="text-xs font-semibold">Charging</p>
										<span className="text-base font-bold">
											{chargerGroupStatus.category_counts.charging}
										</span>
									</div>
								</div>
							</div>
							<div className="space-y-3">
								<div className="flex items-center p-2 bg-red-500 bg-opacity-20 border border-red-500 text-red-500 rounded-md">
									<AlertCircle className="text-2xl mr-3" />
									<div>
										<p className="text-xs font-semibold">Failure</p>
										<span className="text-base font-bold">
											{chargerGroupStatus.category_counts.failure}
										</span>
									</div>
								</div>
								<div className="flex items-center p-2 bg-gray-500 bg-opacity-20 border border-gray-500 text-gray-500 rounded-md">
									<Power className="text-2xl mr-3" />
									<div>
										<p className="text-xs font-semibold">Offline</p>
										<span className="text-base font-bold">
											{chargerGroupStatus.category_counts.offline}
										</span>
									</div>
								</div>
							</div>
						</div>
					</>
				)
			)}
		</div>
	);
};

export default ChargersStatusCard;
