import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ChargerTable } from './charger-table/charger-tables';
import { GroupsTable } from './group-table/group-tables';
type Props = {};

export const ChargerTabs = (props: Props) => {
	return (
		<div className="my-8 backdrop-blur-xl  bg-gradient-linear rounded-lg py-4 text-white relative">
			<Tabs
				defaultValue="chargers"
				className=" bg-transparent container space-y-6 "
			>
				<TabsList className=" flex h-14 bg-slate-700">
					<TabsTrigger
						value="chargers"
						className="flex-1 w-full h-full text-xl font-bold"
					>
						Chargers
					</TabsTrigger>
					<TabsTrigger
						value="groups"
						className="flex-1 w-full h-full text-xl font-bold "
					>
						Groups
					</TabsTrigger>
				</TabsList>

				<TabsContent value="chargers">
					<ChargerTable />
				</TabsContent>
				<TabsContent value="groups">
					<GroupsTable />
				</TabsContent>
			</Tabs>
		</div>
	);
};
