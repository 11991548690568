import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "@/lib/helpers/redux-base-query";

export const orgApi = createApi({
  reducerPath: "orgApi",
  baseQuery,
  tagTypes: ["organizations", "TeamMembers", "Invites"],
  endpoints: (builder) => ({
    createNewOrganisation: builder.mutation({
      query: (values) => ({
        url: `core/organization/new-org/`,
        method: "POST",
        body: values,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(orgApi.util.invalidateTags(["organizations"]));
        }, 1000);
      },
    }),
    editOrganisation: builder.mutation({
      query: ({ values, orgId }) => ({
        url: `core/organization/org/${orgId}`,
        method: "PATCH",
        body: values,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(orgApi.util.invalidateTags(["organizations"]));
        }, 1000);
      },
    }),
    deleteOrganisation: builder.mutation({
      query: (orgId) => ({
        url: `core/organization/org/${orgId}`,
        method: "DELETE",
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(orgApi.util.invalidateTags(["organizations"]));
        }, 1000);
      },
    }),
    getOrganizations: builder.query({
      query: () => `core/organization/list-user-orgs`,
      providesTags: ["organizations"],
    }),
    getTeamMembers: builder.query({
      query: (args: { orgId: string; page: number }) =>
        `core/organization/members/${args.orgId}?page=${args.page}`,
      providesTags: ["TeamMembers"],
    }),
    getInvitations: builder.query({
      query: (args: { orgId: string; page: number }) =>
        `core/organization/invites/${args.orgId}?page=${args.page}&accepted=false`,
      providesTags: ["Invites"],
    }),
    sendInvitations: builder.mutation({
      query: (args: {
        orgId: string | null;
        frontend_url: string;
        invites: any;
      }) => ({
        url: `core/organization/generateInvites/${args.orgId}`,
        method: "POST",
        body: { frontend_url: args.frontend_url, invites: args.invites },
      }),
      invalidatesTags: ["Invites"],
    }),
    removeUserFromOrg: builder.mutation({
      query: ({ orgId, userId }) => ({
        url: `core/organization/members/remove/${orgId}?member_id=${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TeamMembers"],
    }),
    deleteInvitation: builder.mutation({
      query: (id) => ({
        url: `core/organization/invites/delete/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invites"],
    }),
    resendInvitation: builder.mutation({
      query: (args: { id: string; frontend_url: string }) => ({
        url: `core/organization/invites/resend/${args.id}/`,
        method: "PATCH",
        body: { frontend_url: args.frontend_url },
      }),
      invalidatesTags: ["Invites"],
    }),
  }),
});

export const {
  useCreateNewOrganisationMutation,
  useGetOrganizationsQuery,
  useGetInvitationsQuery,
  useGetTeamMembersQuery,
  useSendInvitationsMutation,
  useDeleteInvitationMutation,
	useResendInvitationMutation,
  useRemoveUserFromOrgMutation,
  useDeleteOrganisationMutation,
  useEditOrganisationMutation,
} = orgApi;
