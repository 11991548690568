import { Sidebar } from "@/components/sidebar";
import { cn } from "@/lib/utils/cn";
import { useGetOrganizationsQuery } from "@/redux/features/org/org.api";
import {
  setOrgId,
  setUser,
  startLoading,
} from "@/redux/features/auth/auth.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  useGetUserPrivateOrgQuery,
  useGetUserProfileQuery,
} from "@/redux/features/auth/auth.api";
import {
  extractCountryCode,
  extractCurrencySymbol,
} from "@/lib/helpers/country-code-currency";
import { setGlobalCurrency } from "@/redux/features/home/home.slice";
import { setOrganisations } from "@/redux/features/org/org.slice";

export const Main = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => !!state.auth.user);
  const { orgId, user } = useAppSelector((state) => state.auth);
  const { currentOrg } = useAppSelector((state) => state.org);

  const [dataState, setDataState] = useState("close");

  const { data: organizationsData, isLoading: organiszationsLoading } =
    useGetOrganizationsQuery(undefined, { skip: !!orgId });
  const { data, isLoading } = useGetUserProfileQuery(undefined, {
    skip: !!user,
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setUser(data));
    }

    if (organiszationsLoading) {
      dispatch(startLoading());
    } else if (organizationsData) {
      dispatch(
        setOrgId(
          organizationsData.data.find((data: any) => data.org_type == "Private")
            ?.id
        )
      );
      dispatch(setOrganisations(organizationsData.data));
    }
  }, [
    data,
    isLoading,
    organiszationsLoading,
    organiszationsLoading,
    dispatch,
    organizationsData,
  ]);

  useEffect(() => {
    if (currentOrg) {
      const countryCode = extractCountryCode(currentOrg.org_phone_number);
      if (countryCode) {
        const currencySymbol = extractCurrencySymbol(countryCode);
        dispatch(setGlobalCurrency(currencySymbol));
      } else {
        dispatch(setGlobalCurrency("$"));
      }
    }
  }, [currentOrg, extractCountryCode, extractCurrencySymbol, dispatch]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div
      data-state={dataState}
      className="flex flex-col h-full w-full md:pl-[260px] md:pb-0 pb-[90px]  data-[state=close]:pl-14 transition-all "
    >
      <Sidebar
        className=" overflow-y-scroll"
        dataState={dataState}
        setDataState={setDataState}
      />

      <div className="flex-1 overflow-y-scroll scrollbar-thumb-gray-900 scrollbar-thin">
        {children}
      </div>
    </div>
  );
};
