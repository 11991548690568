import { Checkbox } from '@/components/ui/checkbox';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import React, { useState, useEffect } from 'react';
import { PendingMemberCard } from './pending-member-card';
import { Info } from 'lucide-react';
import { useAppSelector } from '@/redux/hooks';
import { useGetInvitationsQuery } from '@/redux/features/org/org.api';
import { MembersDetailsType } from '../../types';

export const PendingInvitations = () => {
	const [page, setPage] = useState(1);
	const { orgId } = useAppSelector((state) => state.auth);

	const invitesQuery = useGetInvitationsQuery(
		{
			orgId: orgId!,
			page: page,
		},
		{ refetchOnMountOrArgChange: true }
	);
	return (
		<>
			{invitesQuery.isLoading && (
				<div className="h-64 bg-primary  border border-gray-600 rounded-md p-4 flex items-center justify-center">
					<div className="space-y-1">
						<h2 className="text-center font-bold">Loading...</h2>
					</div>
				</div>
			)}
			{invitesQuery.isSuccess &&
				(invitesQuery.data.data.results.length > 0 ? (
					<div className="text-white rounded-lg border-[1px] border-gray-600 overflow-hidden">
						<div className="flex justify-between items-center p-4  border-b-[1px] border-gray-600">
							<div className="flex gap-3 items-center">
								<Checkbox className="border-white" />
								<p className="text-sm font-light text-gray-300">Select all</p>
							</div>
							<div>
								<DotsHorizontalIcon />
							</div>
						</div>
						<div className="max-h-[200px] overflow-y-scroll">
							{invitesQuery.data.data.results.map((item: any) => (
								<PendingMemberCard key={item.id} data={item} />
							))}
						</div>
						<div className="text-gray-400 text-sm font-light italic pb-3 pl-3 flex gap-2 items-center mt-3">
							<Info size={16} />
							Invitations Expire afer 7 days of sending them out
						</div>
					</div>
				) : (
					<div className="h-64 bg-primary  border border-gray-600 rounded-md p-4 flex items-center justify-center">
						<div className="space-y-1">
							<h2 className="text-center font-bold">
								No Pending Invitations Found
							</h2>
							<p className="text-center font-light text-gray-500">
								Use the form above to invite a Team Member.
							</p>
						</div>
					</div>
				))}
			{invitesQuery.isError && (
				<div className="h-64 bg-primary  border border-gray-600 rounded-md p-4 flex items-center justify-center">
					<div className="space-y-1">
						<h2 className="text-center font-bold text-red-400">
							Error fetching invites
						</h2>
					</div>
				</div>
			)}
		</>
	);
};
