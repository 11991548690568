import { useCallback, useReducer } from 'react';
import { RevenueContext } from './context';
import { RevenueContextPropType } from './types/revenue';
import { revenueReducer } from './reducer';
import {
	createNewOrg,
	generateInviteLink,
	getOrgProfile,
} from '@/services/organisation';
import { toast } from 'sonner';
import { CreateNewOrganisationType } from '@/types/organisation';
import { useAppNavigate } from '@/lib/hooks/use-app-navigate';
import { useNavigate } from 'react-router-dom';

const RevenueProvider = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate();

	const handleGetTransactionRecords = useCallback(() => {}, []);

	const values: RevenueContextPropType = {};
	return (
		<RevenueContext.Provider value={values}>{children}</RevenueContext.Provider>
	);
};

export default RevenueProvider;
