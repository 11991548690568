import { Button } from '@/components/ui/button';
import React from 'react';

export const PaymentMethod = () => {
	return (
		<div className="border-[.3px] border-gray-500 bg-primary rounded-lg text-white overflow-hidden">
			<div className="p-4 space-y-2">
				<h2 className="text-lg font-bold">Payment Method</h2>
				<p className="text-sm ">
					You have not yet added any cards. Click the button below to add one.
				</p>
			</div>
			<div className="p-4 justify-between flex items-center border-t-[.3px] border-gray-500 bg-primary ">
				<span className="text-xs ">
					At most, three credit cards, debit cards or prepaid cards can be
					added.
				</span>
				<Button variant="secondary">Add new card method</Button>
			</div>
		</div>
	);
};
