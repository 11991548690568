import { useAppSelector } from "@/redux/hooks";
import { ChargerMaintenance } from "./components/charger-maintenance";
import ChargerStatus from "./components/charger-status";
import { ChargePointSetupInstructions } from "./components/charger-table/charge-point-setup-instruction";
import { ChargerTabs } from "./components/charger-tabs";
import { ChargerUtilization } from "./components/charger-utilization";
import { Navbar } from "@/components/navbar";
import React from "react";

type Props = {};

export default function ChargerNetwork({}: Props) {
  return (
    <div className="space-y-6">
      <div
        style={{
          backgroundImage: "url(/images/--gf-dashboard-cover.png)",
          backgroundSize: "100% 270px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-cover -mb-10  md:pt-9 pt-4 flex flex-col gap-6  container">
          <Navbar title="Charger Network" />
          <div className="flex flex-col xl:flex-row  gap-5">
            <div className=" my-2 w-full flex gap-4  ">
              <ChargerStatus />
              {/* <ChargerMaintenance /> */} <ChargerUtilization />
            </div>
          </div>
        </div>
      </div>
      <div className="container !mt-16">
        <ChargerTabs />
      </div>
      <ChargePointSetupInstructions />
    </div>
  );
}
