import { DeltaType } from '@/common/constants/enums';
import { OverviewCard } from '@/components/overview-card';
import { calculateRevenueChange } from '@/lib/helpers/calculate-revenue-change';
import { isValidDeltaType } from '@/lib/helpers/delta-type';
import { useAppSelector } from '@/redux/hooks';
import { getTodayRevenue } from '@/services/revenue';
import { TotalRevenueType } from '@/types/home';
import React, { useEffect, useState } from 'react';

type Props = {
	data: TotalRevenueType;
};

export const TotalRevenueCard = ({ data }: Props) => {
	const { globalCurrency } = useAppSelector((state) => state.home);

	return (
		<OverviewCard
			data={[1, 0]}
			title="Today’s Revenue"
			metric={globalCurrency + data.today_total_revenue}
			deltaValue={data.percentageChange}
			deltaType={
				isValidDeltaType(data.deltaType) ? DeltaType[data.deltaType] : 'default'
			}
			dayTime="yesterday"
			className="!w-full"
		/>
	);
};
