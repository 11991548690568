import { Button } from "../../../../components/ui/button";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";
import { cn } from "@/lib/utils/cn";
import { Calendar } from "@/components/ui/calendar";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useEffect, useState } from "react";
import { ChevronRightCircle, Info } from "lucide-react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  useCreatePaymentCycleMutation,
  useGetPaymentCyclesQuery,
} from "@/redux/features/revenue/revenue.api";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  formatDate,
  formatDateShort,
  formatDateToYYYYMMDD,
} from "@/lib/helpers/dates";
import { toast } from "sonner";
import { Link } from "react-router-dom";
import {
  setPaymentCycle,
  setPaymentSettlementCycle,
  startLoading,
} from "@/redux/features/revenue/revenue.slice";
import { filterDataByCurrentMonth } from "@/lib/helpers/revenue";

const FormSchema = z.object({
  payment_date: z.object({
    from: z.date({
      required_error: "From date is required.",
    }),
    to: z.date({
      required_error: "To date is required.",
    }),
  }),
});

const SettlementCycle = () => {
  const dispatch = useAppDispatch();
  const { orgId } = useAppSelector((state) => state.auth);
  const { paymentCycle, paymentSettlementCycles, loading } = useAppSelector(
    (state) => state.revenue
  );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const [createPaymentCycle, { isLoading }] = useCreatePaymentCycleMutation();
  const { data: paymentCyclesData, isLoading: paymentCyclesLoading } =
    useGetPaymentCyclesQuery(orgId, {
      skip: !orgId,
    });

  useEffect(() => {
    if (isLoading) {
      dispatch(startLoading());
    } else if (paymentCyclesData) {
      dispatch(
        setPaymentSettlementCycle(
          paymentCyclesData.results.length === 0 ? null : paymentCyclesData
        )
      );
    }

    if (paymentSettlementCycles) {
      const filteredData = filterDataByCurrentMonth(
        paymentSettlementCycles.results
      );

      if (filteredData) {
        dispatch(
          setPaymentCycle({
            id: filteredData.id,
            retaining_period_start_date: formatDateShort(
              filteredData.retaining_period_start_date
            ),
            retaining_period_end_date: formatDateShort(
              filteredData.retaining_period_end_date
            ),
            disbursement_date: formatDateShort(filteredData.disbursement_date),
            created_by: filteredData.created_by,
            organization: filteredData.organization,
          })
        );
      }
    }
  }, [
    paymentSettlementCycles,
    dispatch,
    paymentCyclesLoading,
    paymentCyclesData,
  ]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    const requestBody = {
      organization: orgId,
      retaining_period_start_date: formatDateToYYYYMMDD(data.payment_date.from),
      retaining_period_end_date: formatDateToYYYYMMDD(data.payment_date.to),
    };

    createPaymentCycle(requestBody)
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        dispatch(
          setPaymentCycle({
            id: response.data.id,
            retaining_period_start_date: formatDateShort(
              response.data.retaining_period_start_date
            ),
            retaining_period_end_date: formatDateShort(
              response.data.retaining_period_end_date
            ),
            disbursement_date: formatDateShort(response.data.disbursement_date),
            created_by: response.data.created_by,
            organization: response.data.organization,
          })
        );
      })
      .catch((error) => {
        toast.error(error.data.error_message);
      });
  }

  return (
    <div className="backdrop-blur-xl  bg-gradient-linear  p-6 rounded-xl flex flex-col gap-7 w-full h-full">
      <div className="text-[#D1D1D1] font-extralight">
        Your Settlement Cycle
      </div>

      {paymentCycle && paymentSettlementCycles ? (
        <>
          <div className="flex-col flex items-center gap-2">
            <div className="text-white mb-4">
              {paymentCycle.retaining_period_start_date} -{" "}
              {paymentCycle.retaining_period_end_date}
            </div>
            <div className="flex flex-col items-center mb-4 gap-2">
              <div className="text-white mb-2">
                <div className=" font-light text-[#D1D1D1] text-center">
                  Next payment date
                </div>
                <div className="text-2xl font-bold">
                  {paymentCycle.disbursement_date}
                </div>
              </div>
              <div className="flex gap-4">
                <Link to={"/revenue/settlements"}>
                  <Button className="text-white" variant="outline">
                    View cycles
                  </Button>
                </Link>
                <Button className="text-black" variant="secondary">
                  Change payment cycle
                </Button>
              </div>
            </div>
          </div>
          <div className="max-w-[330px] flex items-start gap-2">
            <div className="text-gray-400">
              <Info size={16} />
            </div>
            <span className="text-gray-400 font-light italic text-xs">
              You can only create a settlement period once, please contact
              support for more information.
            </span>
          </div>
        </>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="payment_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Select payment period</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          id="date"
                          variant={"outline"}
                          className={cn(
                            " justify-start text-left font-normal",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {field.value?.from ? (
                            field.value.to ? (
                              <>
                                {format(field.value.from, "LLL dd, y")} -{" "}
                                {format(field.value.to, "LLL dd, y")}
                              </>
                            ) : (
                              format(field.value.from, "LLL dd, y")
                            )
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        initialFocus
                        mode="range"
                        selected={field.value}
                        onSelect={(e) =>
                          field.onChange({
                            from: e?.from,
                            to: e ? addDays(e.from!, 29) : undefined,
                          })
                        }
                        numberOfMonths={3}
                        defaultMonth={new Date()}
                        disabled={(date) => date <= new Date()}
                        // max={30}
                        // min={30}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription className="max-w-[330px] flex items-start gap-2">
                    <div className="text-gray-400">
                      <Info size={16} />
                    </div>
                    <span className="text-gray-400 font-light italic text-xs">
                      You can only create a settlement period once, please
                      contact support for more information.
                    </span>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              disabled={isLoading}
              variant="secondary"
              className="gap-2 w-full"
            >
              Create Settlement plan
              <ChevronRightCircle size={16} />
            </Button>
          </form>
        </Form>
      )}
    </div>
  );
};

export default SettlementCycle;
