import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface PieChartProps {
	chartData: number[];
	chartOptions: any;
	type:
		| 'line'
		| 'area'
		| 'bar'
		| 'pie'
		| 'donut'
		| 'radialBar'
		| 'scatter'
		| 'bubble'
		| 'heatmap'
		| 'candlestick'
		| 'boxPlot'
		| 'radar'
		| 'polarArea'
		| 'rangeBar'
		| 'rangeArea'
		| 'treemap'
		| undefined;
}

const PieChart: React.FC<PieChartProps> = (props) => {
	const [chartData, setChartData] = useState(props.chartData);
	const [chartOptions, setChartOptions] = useState(props.chartOptions);

	useEffect(() => {
		setChartData(props.chartData);
		setChartOptions(props.chartOptions);
	}, [props.chartData, props.chartOptions]);

	return (
		<ReactApexChart
			options={chartOptions}
			series={chartData}
			type={props.type}
			width="100%"
			height="100%"
		/>
	);
};

export default PieChart;
