import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PaymentSettlmentCycleTypeResult = {
	id: string;
	retaining_period_start_date: string;
	retaining_period_end_date: string;
	disbursement_date: string;
	created_by: string;
	organization: string;
};

export type PaymentSettlmentCycleType = {
	count: number | null;
	next: number | null;
	previous: number | null;
	results: PaymentSettlmentCycleTypeResult[];
};
interface InitialState {
	loading: boolean;
	data: {
		dailySalesAnalytics: any[];
		weeklySalesAnalytics: any[];
		monthlySalesAnalytics: any[];
		yearlySalesAnalytics: any[];
		contributions: {
			name: string;
			data: string[] | any;
		};
		transactionRecords: {};
	};
	paymentCycle: PaymentSettlmentCycleTypeResult | null;
	paymentSettlementCycles: PaymentSettlmentCycleType | null;
	error: string | null;
}
const initialState: InitialState = {
	loading: false,
	data: {
		dailySalesAnalytics: [],
		weeklySalesAnalytics: [],
		monthlySalesAnalytics: [],
		yearlySalesAnalytics: [],
		contributions: { name: '', data: [] },
		transactionRecords: {},
	},
	paymentCycle: null,
	paymentSettlementCycles: null,
	error: null,
};

const revenueSlice = createSlice({
	name: 'revenue',
	initialState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
			state.error = null;
		},
		setDailySalesData: (state, action) => {
			state.data.dailySalesAnalytics = action.payload.data;
			state.loading = false;
		},
		setMonthlySalesData: (state, action) => {
			state.data.monthlySalesAnalytics = action.payload.data;
			state.loading = false;
		},
		setWeeklySalesData: (state, action) => {
			state.data.weeklySalesAnalytics = action.payload.data;
			state.loading = false;
		},
		setYearlySalesData: (state, action) => {
			state.data.yearlySalesAnalytics = action.payload.data;
			state.loading = false;
		},
		setContributions: (state, action) => {
			state.data.contributions = action.payload;
			state.loading = false;
		},
		setTransactionRecords: (state, action) => {
			state.data.transactionRecords = action.payload;
			state.loading = false;
		},
		setPaymentCycle: (state, action) => {
			state.paymentCycle = action.payload;
			state.loading = false;
		},
		setPaymentSettlementCycle: (state, action) => {
			state.paymentSettlementCycles = action.payload;
			state.loading = false;
		},

		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loading = false;
		},
	},
});

export const {
	startLoading,
	setDailySalesData,
	setMonthlySalesData,
	setWeeklySalesData,
	setYearlySalesData,
	setContributions,
	setTransactionRecords,
	setError,
	setPaymentCycle,
	setPaymentSettlementCycle,
} = revenueSlice.actions;

export default revenueSlice.reducer;
