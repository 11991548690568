/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

interface ColumnChartProps {
	chartData: { name: string; data: number[] }[];
	chartOptions: any;
}

export const BarChart: React.FC<ColumnChartProps> = ({
	chartData,
	chartOptions,
}) => {
	const [data, setData] = useState<{
		chartData: { name: string; data: number[] }[];
		chartOptions: any;
	}>({
		chartData: [],
		chartOptions: {},
	});

	useEffect(() => {
		setData({ chartData, chartOptions });
	}, [chartData, chartOptions]);

	return (
		<Chart
			options={data.chartOptions}
			series={data.chartData}
			type="bar"
			width="100%"
			height="100%"
		/>
	);
};
