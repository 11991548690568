import request from '@/lib/utils/axios';
import { SignupType } from '@/types/auth';
import { AxiosError } from 'axios';

const orgId = localStorage.getItem('org_id');

export const loginUserService = async (value: {
	email: string;
	password: string;
}) => {
	try {
		const res = await request.post('/core/users/login/', value);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const signupUserService = async (value: SignupType) => {
	try {
		const res = await request.post('/core/users/sign-up/', value);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const getUserProfile = async () => {
	const url = orgId
		? '/core/users/profile/'
		: 'core/users/profile/?org_context=true';
	try {
		const res = await request.get('/core/users/profile/');
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const getOrgId = async () => {
	const url = orgId
		? '/core/users/profile/'
		: 'core/users/profile/?org_context=true';
	try {
		const res = await request.get('/core/organization/user-private-org');
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const getAccessToken = async (value: { refresh: string }) => {
	try {
		const res = await request.post('/core/users/access-token/refresh/', value);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const editUserProfile = async (value: {
	email?: string | undefined;
	first_name?: string | undefined;
	last_name?: string | undefined;
}) => {
	try {
		const res = await request.put('/core/users/edit-profile/', value);
		return res.data; 
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const verifyOTP = async (value: {
	email?: string | undefined;
	otp_token?: string | undefined;
}) => {
	try {
		const res = await request.patch(
			`/core/users/verify-otp/?email=${value.email}`,
			{
				otp_token: value.otp_token,
			}
		);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const resendOTP = async (value: { email: string }) => {
	try {
		const res = await request.get(
			`/core/users/resend-otp/?email=${value.email}`
		);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const forgotPassword = async (value: { email: string }) => {
	try {
		const res = await request.post('/core/users/forgot-password/', value);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const resetPassword = async (value: {
	email?: string;
	new_password: string;
	reset_token?: string;
}) => {
	try {
		const res = await request.post('/core/users/reset-password/', value);
		return { ...res.data, status: 'success' };
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return { ...error.response.data, status: 'error' };
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const changePassword = async (value: {
	old_password: string;
	new_password: string;
}) => {
	try {
		const res = await request.patch('/core/users/change-password/', value);
		return res.data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};

export const acceptOrgInvite = async (value: { invite_token: string }) => {
	try {
		const res = await request.post('/core/organization/acceptInvite/', value);
		return res.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			if (error.response) {
				return error.response.data;
			} else {
				throw new Error(error.message);
			}
		}
	}
};