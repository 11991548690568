import { cn } from '@/lib/utils/cn';
import { BatteryLow } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { FaBolt } from 'react-icons/fa';

export const InfiniteMovingCards = ({
	items,
	direction = 'left',
	speed = 'fast',
	pauseOnHover = true,
	className,
}: {
	items: {
		chargerId: string;
		utilization: number;
	}[];
	direction?: 'left' | 'right';
	speed?: 'fast' | 'normal' | 'slow';
	pauseOnHover?: boolean;
	className?: string;
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const scrollerRef = useRef<HTMLUListElement>(null);

	useEffect(() => {
		addAnimation();
	}, []);
	const [start, setStart] = useState(false);
	function addAnimation() {
		if (containerRef.current && scrollerRef.current) {
			const scrollerContent = Array.from(scrollerRef.current.children);

			scrollerContent.forEach((item) => {
				const duplicatedItem = item.cloneNode(true);
				if (scrollerRef.current) {
					scrollerRef.current.appendChild(duplicatedItem);
				}
			});

			getDirection();
			getSpeed();
			setStart(true);
		}
	}
	const getDirection = () => {
		if (containerRef.current) {
			if (direction === 'left') {
				containerRef.current.style.setProperty(
					'--animation-direction',
					'forwards'
				);
			} else {
				containerRef.current.style.setProperty(
					'--animation-direction',
					'reverse'
				);
			}
		}
	};
	const getSpeed = () => {
		if (containerRef.current) {
			if (speed === 'fast') {
				containerRef.current.style.setProperty('--animation-duration', '20s');
			} else if (speed === 'normal') {
				containerRef.current.style.setProperty('--animation-duration', '40s');
			} else {
				containerRef.current.style.setProperty('--animation-duration', '80s');
			}
		}
	};
	return (
		<div
			ref={containerRef}
			className={cn(
				'scroller relative z-20  max-w-7xl overflow-hidden  [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]',
				className
			)}
		>
			<ul
				ref={scrollerRef}
				className={cn(
					' flex min-w-full shrink-0 gap-4 py-4 w-max flex-nowrap',
					start && 'animate-scroll ',
					pauseOnHover && 'hover:[animation-play-state:paused]'
				)}
			>
				{items.map((item, idx) => (
					<li
						className="w-[350px] max-w-full relative rounded-2xl border border-b-0 flex-shrink-0 border-slate-700 px-8 py-6 md:w-[350px]"
						style={{
							background:
								'linear-gradient(180deg, rgb(30 41 59) ,  rgb(15 23 42 )',
						}}
						key={item.chargerId}
					>
						<div className="flex items-center gap-3">
							{item.utilization > 20 ? (
								<FaBolt
									size={45}
									className="inline-block ml-2 text-yellow-300 animate-pulse"
								/>
							) : (
								<BatteryLow
									size={45}
									className="inline-block ml-2 text-red-500 animate-pulse"
								/>
							)}
							<div>
								<span className=" relative z-20 text-sm leading-[1.6] text-gray-100 font-normal">
									Charger ID - {item.chargerId}
								</span>
								<div className="relative z-20 mt-6 flex flex-row items-center">
									<span className="flex flex-col gap-1">
										<span className=" text-base font-bold leading-[1.6] text-gray-400">
											{item.utilization}%
										</span>
									</span>
								</div>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};
