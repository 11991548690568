export const INVITE_LINK_SUCCESS = 'INVITE_LINK_SUCCESS';
export const INVITE_LINK_LOADING = 'INVITE_LINK_LOADING';
export const INVITE_LINK_FAIL = 'INVITE_LINK_FAIL';

export const CREATE_NEW_ORG_SUCCESS = 'CREATE_NEW_ORG_SUCCESS';
export const CREATE_NEW_ORG_LOADING = 'CREATE_NEW_ORG_LOADING';
export const CREATE_NEW_ORG_FAIL = 'CREATE_NEW_ORG_FAIL';

export const GET_ORG_PROFILE_SUCCESS = 'GET_ORG_PROFILE_SUCCESS';
export const GET_ORG_PROFILE_LOADING = 'GET_ORG_PROFILE_LOADING';
export const GET_ORG_PROFILE_FAIL = 'GET_ORG_PROFILE_FAIL';

interface OrganisationState {
	loading: boolean;
	data: any;
	error: string | null;
}

interface OrganisationAction {
	type: string;
	payload?: any;
}

export const organisationReducer = (
	state: OrganisationState,
	action: OrganisationAction
): OrganisationState => {
	switch (action.type) {
		case INVITE_LINK_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case INVITE_LINK_LOADING:
			return {
				...state,
				loading: true,
				data: [],
				error: null,
			};
		case INVITE_LINK_FAIL:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload,
			};

		case CREATE_NEW_ORG_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case CREATE_NEW_ORG_LOADING:
			return {
				...state,
				loading: true,
				data: [],
				error: null,
			};
		case CREATE_NEW_ORG_FAIL:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload,
			};

		case GET_ORG_PROFILE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null,
			};
		case GET_ORG_PROFILE_LOADING:
			return {
				...state,
				loading: true,
				data: {},
				error: null,
			};
		case GET_ORG_PROFILE_FAIL:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload,
			};
		default:
			return state;
	}
};
