import { PaymentSettlmentCycleTypeResult } from '@/redux/features/revenue/revenue.slice';

export function filterDataByCurrentMonth(
	data: PaymentSettlmentCycleTypeResult[]
): PaymentSettlmentCycleTypeResult | undefined {
	const currentMonth = new Date().getMonth() + 1; // Months are zero-indexed
	const currentYear = new Date().getFullYear();

	return data.find((item) => {
		const date = new Date(item.retaining_period_start_date);
		return (
			date.getMonth() + 1 === currentMonth && date.getFullYear() === currentYear
		);
	});
}
