import { Navbar } from "@/components/navbar";
import { TariffPlanDataTable } from "./components/tariff-plan/tariff-plan-data-table";
import { columns } from "./components/tariff-plan/column";
import { generateTariffPlanData } from "./data/tariff";
import { useEffect, useState } from "react";
import { TariffPlanType } from "./types";
import { getTafiffPlans } from "@/services/tariff-plan";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useGetTafiffPlansQuery } from "@/redux/features/tariff/tariff.api";
import {
  setTariffPlans,
  startLoading,
} from "@/redux/features/tariff/tariff.slice";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import { TariffGroupsTable } from "./components/group-table/group-tables";

export const TariffPlanTable: React.FC = () => {
  const [page, setPage] = useState(1);
  const { orgId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const {
    data: { tariffPlans },
    loading,
  } = useAppSelector((state) => state.tariff);

  const { data, isFetching } = useGetTafiffPlansQuery({ orgId, page });

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setTariffPlans(data));
    }
  }, [tariffPlans, dispatch, isFetching, data]);

  return (
    <div className=" flex gap-9 flex-col relative ">
      <div
        style={{
          backgroundImage: "url(/images/--gf-dashboard-cover.png)",
          backgroundSize: "100% 270px",
          backgroundRepeat: "no-repeat",
        }}
        className="bg-cover md:px-9 px-4  md:pt-9 pt-4 flex flex-col 2xl:gap-11 gap-5 mb-10"
      >
        <div className="container px-0">
          <Navbar title={"Tariff Management"} />
        </div>
        <div className=" bg-black p-6 rounded-lg border border-slate-400 text-white container">
          <div className="mb-4">
            <h2 className="text-2xl font-bold ">Your tariff plans.</h2>
            <p className="text-lg">
              Here's a list of your current Tariff plans!
            </p>
          </div>

          <TariffPlanDataTable
            data={tariffPlans ? tariffPlans.results : []}
            columns={columns}
            loading={loading}
          />

          {loading ? (
            <div className="flex  justify-end my-2">
              <div className="flex justify-end gap-2">
                <Skeleton className=" h-6 w-12 rounded-sm " />
                <Skeleton className=" h-6 w-12 rounded-sm " />
                <Skeleton className=" h-6 w-12 rounded-sm " />
              </div>
            </div>
          ) : (
            tariffPlans && (
              <Pagination className="justify-end pr-6 pt-4">
                <PaginationContent>
                  {tariffPlans.previous && (
                    <PaginationItem onClick={() => setPage(page - 1)}>
                      <PaginationPrevious href="#" />
                    </PaginationItem>
                  )}
                  <PaginationItem>
                    <PaginationLink href="#" isActive>
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                  {tariffPlans.next && (
                    <PaginationItem onClick={() => setPage(page + 1)}>
                      <PaginationNext href="#" />
                    </PaginationItem>
                  )}
                </PaginationContent>
              </Pagination>
            )
          )}
        </div>
        <TariffGroupsTable />
      </div>
    </div>
  );
};

export default TariffPlanTable;
