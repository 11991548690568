import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../lib/helpers/redux-base-query';

export const revenueApi = createApi({
	reducerPath: 'revenueApi',
	baseQuery,
	tagTypes: ['payments'],
	endpoints: (builder) => ({
		getDailySalesAnalytics: builder.query({
			query: (orgId) =>
				`core_backend/daily-sales-analytics/?organization=${orgId}`,
		}),
		getYearlySalesAnalytics: builder.query({
			query: (orgId) =>
				`core_backend/yearly-sales-analytics/?organization=${orgId}`,
		}),
		getWeeklySalesAnalytics: builder.query({
			query: (orgId) =>
				`core_backend/weekly-sales-analytics/?organization=${orgId}`,
		}),
		getMonthlySalesAnalytics: builder.query({
			query: (orgId) =>
				`core_backend/monthly-sales-analytics/?organization=${orgId}`,
		}),
		getChargerGroupContributionsDaily: builder.query({
			query: (orgId) =>
				`core_backend/org-charger-group-daily-revenue-contribution/?organization=${orgId}`,
		}),
		getTransactionRecords: builder.query({
			query: ({ orgId, page = 1 }) =>
				`core_backend/revenue/transaction-records/?organization=${orgId}&page=${page}`,
		}),
		getTransactionRecordsById: builder.query({
			query: (values) =>
				`core_backend/revenue/transaction-records/${values.transaction_id}`,
		}),
		createPaymentCycle: builder.mutation({
			query: (values) => ({
				url: `core_backend/revenue/payment-cycles/`,
				method: 'POST',
				body: values,
			}),
			invalidatesTags: ['payments'],
		}),
		getPaymentCycles: builder.query({
			query: (orgId) =>
				`core_backend/revenue/payment-cycles/?organization=${orgId}`,
			providesTags: ['payments'],
		}),

		getPastPaymentCycles: builder.query({
			query: (orgId) =>
				`core_backend/revenue/payment-cycles/?organization=${orgId}&payment_cycle_type=past`,
		}),
	}),
});

export const {
	useGetDailySalesAnalyticsQuery,
	useGetMonthlySalesAnalyticsQuery,
	useGetWeeklySalesAnalyticsQuery,
	useGetYearlySalesAnalyticsQuery,
	useGetChargerGroupContributionsDailyQuery,
	useGetTransactionRecordsQuery,
	useGetTransactionRecordsByIdQuery,
	useCreatePaymentCycleMutation,
	useGetPaymentCyclesQuery,
	useGetPastPaymentCyclesQuery,
} = revenueApi;
