import { useAppSelector } from '@/redux/hooks';
import React from 'react';

type Props = {
	amount: number;
};

export const AmountRow = ({ amount }: Props) => {
	const { globalCurrency } = useAppSelector((state) => state.home);
	return (
		<div className="text-xs">
			{globalCurrency}
			{amount}
		</div>
	);
};
