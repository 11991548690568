import { TableRow } from '@/components/ui/table';
import React, { useState } from 'react';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';

type Props = { children: React.ReactNode };

export const ManageRole = ({ children }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	return (
		<>
			<Button
				onClick={() => setIsModalOpen(true)}
				variant="outline"
				className=" border-[1px] border-gray-600 bg-transparent"
			>
				{children}
			</Button>

			<Dialog
				modal
				open={isModalOpen}
				onOpenChange={() => setIsModalOpen(true)}
			>
				<DialogContent className=" border-0 shadow-2xl bg-background p-4  text-white max-w-lg ">
					<DialogHeader>
						<DialogTitle className="flex justify-between items-center">
							<h2>Manage members role</h2>
						</DialogTitle>
					</DialogHeader>
					<div className="flex">
						<div className="flex items-center gap-1 flex-1">
							<img src="/images/user-image.png" />
							<div>
								<h3 className="text-sm">Chan Tai Man</h3>
								<p className="text-xs text-gray-500">chaiman@grdflo.com</p>
							</div>
						</div>
						<Select>
							<SelectTrigger className="flex-1">
								<SelectValue id="path" placeholder="Select a role." />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="Member">Member</SelectItem>
								<SelectItem value="Owner">Owner</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<DialogFooter className="flex items-center gap-3">
						<Button
							onClick={() => setIsModalOpen(false)}
							variant="outline"
							type="button"
						>
							Cancel
						</Button>

						<Button
							onClick={() => setIsModalOpen(false)}
							variant="secondary"
							type="button"
						>
							Continue
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
};
