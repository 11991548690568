import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { FilterSection } from './filter-section';
import TeamTable from './team-table';
import { PendingInvitations } from './pending-invitations';
import { MembersDetailsType } from '../../types';


export const TeamMembers = () => {
	return (
		<Tabs defaultValue="teamMembers" className=" bg-transparent">
			<TabsList className="bg-transparent border-b rounded-none w-full flex justify-start p-0 border-gray-600">
				<TabsTrigger
					value="teamMembers"
					className="bg-transparent data-[state=active]:bg-transparent data-[state=active]:border-b-2 -mb-2 rounded-none margin-0 "
				>
					Team members
				</TabsTrigger>
				<TabsTrigger
					value="invitations"
					className="bg-transparent data-[state=active]:bg-transparent data-[state=active]:border-b-2 -mb-2 rounded-none margin-0 "
				>
					Pending invitations
				</TabsTrigger>
			</TabsList>
			<FilterSection />
			<TabsContent value="teamMembers">
				<TeamTable />
			</TabsContent>
			<TabsContent value="invitations">
				<PendingInvitations />
			</TabsContent>
		</Tabs>
	);
};
