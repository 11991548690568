import { ColumnDef } from '@tanstack/react-table';
import { Checkbox } from '@/components/ui/checkbox';
import { DataTableColumnHeader } from '@/components/ui/data-table/data-table-column-header';
import { Actions } from './actions';
import { Group } from '../../data/schema';
import { extractFirstId } from '@/lib/helpers/extract-first-id';
import { ApplyTo } from './apply-to';

export const columns: ColumnDef<Group>[] = [
	{
		id: 'select',
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllPageRowsSelected()}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
				className="translate-y-[2px]"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={(value) => row.toggleSelected(!!value)}
				aria-label="Select row"
				className="translate-y-[2px]"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'id',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Group ID" />
		),
		cell: ({ row }) => (
			<div className="uppercase">{extractFirstId(row.getValue('id'))}</div>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'name',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Name" />
		),
		cell: ({ row }) => <div className="">{row.getValue('name')}</div>,
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'address',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Location" />
		),
		cell: ({ row }) => (
			<div className="capitalize">{row.getValue('address')}</div>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		id: 'applyTo',
		cell: ({ row }) => (
			<ApplyTo id={row.getValue('id')} tariffId={row.original.tarrif_plan} />
		),
	},
	// {
	// 	id: 'actions',
	// 	cell: ({ row }) => <Actions row={row} />,
	// },
];
