// Get the current hour of the day
const currentHour = new Date().getHours();

// Define a function to determine the appropriate greeting based on the time of day
export const getGreeting = () => {
	if (currentHour >= 5 && currentHour < 12) {
		return 'Good morning';
	} else if (currentHour >= 12 && currentHour < 17) {
		return 'Good afternoon';
	} else if (currentHour >= 17 && currentHour < 21) {
		return 'Good evening';
	} else {
		return 'Good night';
	}
};
